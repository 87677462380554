import React, { Component } from 'react';
import Loader from './Loader';
import ANotebookComponent from './ANotebookComponent'
import { Route } from 'react-router-dom';
import { authenticationService } from '../services/authentication.service';
import { ErrorNotFound } from './ErrorNotFound';
import { authHeader } from '../helpers/auth-header';
import { DialogInfo } from './DialogInfo';

export class ANotebookCreate extends Component {
    static displayName = ANotebookCreate.name;
    
    constructor(props) {
        super(props);
        this.state = { notebook: null, loading: true, logged: false, creating: false, isOpen: false, message: "" };        
        this.handleCreateNotebook = this.handleCreateNotebook.bind(this);        

        if (authenticationService.currentUserValue) {
            this.state.logged = true;
        }
    }
    toggleModal = () => {        
        if (this.state.message == "Notebook byl úspěšně vytvořen.") {
            this.props.history.goBack();
        }
        else {
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }

    handleCreateNotebook(e) { 
        if (this.state.notebook.isUnconfigurable) {
            this.setState({ loading: true, creating: true });
            this.createNotebookAsync(this.state.notebook);
        }
        else {
            var classNameWithoutDefault = [];
            var selected = this.state.notebook.components.filter(x => x.isSelected);

            let group = selected.reduce((r, a) => {
                r[a.component.className] = [...r[a.component.className] || [], a];
                return r;
            }, {});

            Object.entries(group).map(([key, value]) => {
                if (key !== 'Others') {
                    var isDefault = value.filter(x => x.isDefault);
                    if (isDefault.length === 0) {
                        classNameWithoutDefault.push(key);
                    }
                }
            });

            if (classNameWithoutDefault.length === 0) {
                this.setState({ loading: true, creating: true });
                this.createNotebookAsync(this.state.notebook);
            }
            else {
                this.setState({ isOpen: true, message: 'Není vybrána STANDARD komponenta:' + classNameWithoutDefault.map(x => ' ' + x) });
            }
        }
    } 

    componentDidMount() {
        if (this.state.logged) {
            this.getNotebookAsync();
        }        
    }

    render() {
        if (!this.state.logged) {
            return <Route path="*" component={ErrorNotFound} />
        }
        
        let loader = (this.state.creating && this.state.loading) && <Loader loading={this.state.loading} />
        let contents = (!this.state.creating && this.state.loading)
            ? <Loader loading={this.state.loading} />
            : <ANotebookComponent notebook={this.state.notebook} />       

        return (
            <div style={{ paddingBottom: '240px' }}>
                {loader}
                <DialogInfo
                    header="Vytvoření notebooku" show={this.state.isOpen}
                    onClose={this.toggleModal}>
                    {this.state.message}
                </DialogInfo>
                <h2>Vytvoření notebooku</h2>
                {contents}
                <div className="col-sm-12 text-center">
                    <button className="submit-button rounded" onClick={this.handleCreateNotebook}>VYTVOŘIT</button>
                </div>
            </div>
        );
    }

    async getNotebookAsync() {
        const requestOptions = { method: 'GET', headers: authHeader() };
        const response = await fetch('admin/createnotebook', requestOptions);
        const data = await response.json();
        this.setState({ notebook: data, loading: false });
    }

    async createNotebookAsync(notebook) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(notebook)
        };
        const response = await fetch('admin/createnotebook', requestOptions);       
        var message = "";
        if (response.ok) {            
            message = "Notebook byl úspěšně vytvořen."
        }
        else {
            message = "Notebook se nepovedlo vytvořit."
        }
        this.setState({ loading: false, creating: false, isOpen: true, message });
    }
}

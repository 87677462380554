import React from 'react';
import { Icon, Step } from 'semantic-ui-react'
import './ShoppingSteps.css'

export const ShoppingSteps = ({ step = 1 }) => {
    return (
        <div style={{ position: "relative", paddingBottom: '24px', zIndex: '0' }}>
        <Step.Group size='mini' unstackable fluid horizontal>
            <Step active={step == 1} >
                <Icon name='shopping bag' />
                <div className="mobile-hide-content">
                <Step.Content>
                    <Step.Title>Košík</Step.Title>
                    <Step.Description>Obsah košíku</Step.Description>
                    </Step.Content>
                </div>
            </Step>

            <Step active={step == 2}>
                <Icon name='address book' />
                <div className="mobile-hide-content">
                <Step.Content>
                    <Step.Title>Fakturační údaje</Step.Title>
                    <Step.Description>Fakturační a doručovací údaje</Step.Description>
                    </Step.Content>
                    </div>
            </Step>

            <Step active={step == 3} disabled={step < 2}>
                <Icon name='truck' />
                <div className="mobile-hide-content">
                <Step.Content>
                    <Step.Title>Způsob přepravy</Step.Title>
                    <Step.Description>Přeprava a platba</Step.Description>
                    </Step.Content>
                </div>
            </Step>
            <Step active={step == 4} disabled={step < 3}>
                <Icon name='info' />
                <div className="mobile-hide-content">
                <Step.Content>
                    <Step.Title>Souhrn</Step.Title>
                    <Step.Description>Potvrzení objednávky</Step.Description>
                    </Step.Content>
                </div>
            </Step>
            </Step.Group> 
            </div>
        )        
}
import React, { Component } from 'react';
import Loader from './Loader';
import { authHeader } from '../helpers/auth-header';
import { Route } from 'react-router-dom';
import { authenticationService } from '../services/authentication.service';
import { ErrorNotFound } from './ErrorNotFound';
import { DialogInfo } from './DialogInfo';
import { DialogConfirm } from './DialogConfirm';

export class ANewsletter extends Component {
    static displayName = ANewsletter.name;

    constructor(props) {
        super(props);
        this.state = {
            newsletters: [],            
            loading: true,
            logged: false,
            working: false,            
            isOpen: false,
            header: "",
            message: "",
            isDelete: false,
            codeForDel: "",
            deleteId: -1
        };
        
        this.handleDelete = this.handleDelete.bind(this);        
        this.toggleModal = this.toggleModal.bind(this);

        if (authenticationService.currentUserValue) {
            this.state.logged = true;
        }
    }

    toggleOK = () => {
        this.setState({ isDelete: false, loading: true, working: true, header: "Smazání" });
        this.deletenewsAsync(this.state.deleteId);
    }
    toggleNOK = () => {
        this.setState({ isDelete: false });
    }
    handleDelete(e) {
        const { id } = e.target;
        var news = this.state.newsletters.find(x => x.id == id)
        this.setState({ deleteId: id, isDelete: true, codeForDel: news.lastName });
    }

    toggleModal() {
        this.setState({
            isOpen: !this.state.isOpen
        });       
    }

    componentDidMount() {
        if (this.state.logged) {
            this.getnewsAsync();
        }
    }

    static rendernewsTable(_this) {
        return (
            <table className='table table-striped' style={{ fontSize: '13px' }}>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Telefon</th>
                        <th>Jméno</th>
                        <th>Příjmení</th>
                        <th>Obec</th>
                        <th>Ulice</th>
                        <th>PSČ</th>
                        <th>Stát</th>
                        <th>Společnost</th> 
                        <th>IČO</th>
                        <th>DIČ</th>                                    
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {_this.state.newsletters.map(news =>
                        <tr key={news.id}>
                            <td>{news.email}</td>
                            <td>{news.phone}</td>
                            <td>{news.firstName}</td> 
                            <td>{news.lastName}</td>
                            <td>{news.city}</td>
                            <td>{news.street}</td> 
                            <td>{news.zipCode}</td>
                            <td>{news.country}</td>
                            <td>{news.companyName}</td> 
                            <td>{news.companyID == 0 ? null : news.companyID}</td>
                            <td>{news.taxID == 0 ? null : news.taxID}</td> 
                            <td><div id={news.id} key={news.id} onClick={_this.handleDelete} className="text-button">SMAZAT</div></td>
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

    render() {
        if (!this.state.logged) {
            return <Route path="*" component={ErrorNotFound} />
        }

        let loader = (this.state.working && this.state.loading) && <Loader loading={this.state.loading} />
        let contents = (!this.state.working && this.state.loading)
            ? <Loader loading={this.state.loading} />
            : ANewsletter.rendernewsTable(this);

        return (
            <React.Fragment>
                {loader}   
                <DialogConfirm
                    header="Odběratele měny" show={this.state.isDelete}
                    onOKButton={this.toggleOK}
                    onNOKButton={this.toggleNOK}>
                    Opravdu chceš smazat odběratel <b>{this.state.codeForDel}</b>?
                </DialogConfirm>
                <DialogInfo
                    header={this.state.header} show={this.state.isOpen}
                    onClose={this.toggleModal}>
                    {this.state.message}
                </DialogInfo>
                <h5 style={{ paddingBottom: '24px' }}>Seznam odběratelů slev a akcí</h5>
                {contents}
            </React.Fragment>
        );
    }

    async getnewsAsync() {
        const requestOptions = { method: 'GET', headers: authHeader() };
        const response = await fetch('admin/getallnewsletter', requestOptions);
        const data = await response.json();
        this.setState({ newsletters: data, loading: false });
    }
        
    async deletenewsAsync(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: authHeader()
        };
        const response = await fetch('admin/deletenewsletter/' + id, requestOptions);
        var { newsletters } = this.state;
        var message = "";
        if (response.ok) {
            message = "Odběratel byl úspěšně smazána."

            newsletters = newsletters.filter(x => x.id != id);
        }
        else {
            message = "Odběratele se nepovedlo smazat."
        }
        this.setState({ loading: false, working: false, isOpen: true, message, newsletters });
    }
}
import React, { Component, useEffect, useRef, useState, cloneElement, useLayoutEffect } from 'react';
import Slider from 'react-animated-slider';
import Loader from './Loader';
import SEO from './SEO';
import { gsap, Power0, Power4, TimelineMax, Linear } from "gsap";
import 'react-animated-slider/build/horizontal.css';
import './Home.css'

//const PlusIconSvg = <></>
//const MinusIconSvg  = <></>
//const StarIconSvg = <></>
//const RightArrowIconSvq = <></>


const PlusIconSvg = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" xmlSpace="preserve">
    <g id="CIRCLE__x2F__PLUS_1_" enableBackground="new    "><g id="CIRCLE__x2F__PLUS"><g>
        <path d="M 32,0 C 14.327,0 0,14.327 0,32 0,49.673 14.327,64 32,64 49.673,64 64,49.673 64,32 64,14.327 49.673,0 32,0 Z m 0,16 c 1.657,0 3,1.343 3,3 v 10 h 10 c 1.657,0 3,1.343 3,3 0,1.657 -1.343,3 -3,3 H 35 v 10 c 0,1.657 -1.343,3 -3,3 -1.657,0 -3,-1.343 -3,-3 V 35 H 19 c -1.657,0 -3,-1.343 -3,-3 0,-1.657 1.343,-3 3,-3 H 29 V 19 c 0,-1.657 1.343,-3 3,-3 z" />
    </g></g></g>
</svg>

const MinusIconSvg = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" xmlSpace="preserve">
    <g id="CIRCLE__x2F__MINUS_1_" enableBackground="new    "><g id="CIRCLE__x2F__MINUS"><g>
        <path d="M 32,0 C 14.327,0 0,14.327 0,32 0,49.673 14.327,64 32,64 49.673,64 64,49.673 64,32 64,14.327 49.673,0 32,0 Z M 19,29 h 26 c 1.657,0 3,1.343 3,3 0,1.657 -1.343,3 -3,3 H 19 c -1.657,0 -3,-1.343 -3,-3 0,-1.657 1.343,-3 3,-3 z" />
    </g></g></g>
</svg>

const StarIconSvg = props => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="64px" height="64px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64"   xmlSpace="preserve">
    {props.children}
    <g id="STAR_1_" enableBackground="new    ">
        <g id="STAR">
            <g>
                <path fill={props.children && "url(#half)"} d="M64,26c0-1.657-1.343-3-3-3H41.047L34.869,4.12C34.492,2.893,33.352,2,32,2c-1.35,0-2.492,0.892-2.868,2.12L22.953,23H3
				c-1.657,0-3,1.343-3,3c0,1,0.49,1.885,1.242,2.43l0,0L17.36,40.09l-6.209,18.971C11.053,59.357,11,59.672,11,60
				c0,1.657,1.343,3,3,3c0.657,0,1.263-0.213,1.758-0.57h0.001L32,50.682L48.242,62.43h0.001C48.736,62.788,49.343,63,50,63
				c1.657,0,3-1.343,3-3c0-0.328-0.053-0.643-0.15-0.938L46.641,40.09l16.118-11.66l0,0C63.51,27.885,64,27,64,26z"/>
            </g>
        </g>
    </g>
</svg>

const RightArrowIconSvg = () => <svg className="info-right-arrow-inline" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" xmlSpace="preserve" >
    <g id="CIRCLE__x2F__RIGHT_1_" enableBackground="new    "><g id="CIRCLE__x2F__RIGHT"><g>
        <path d="m 47,29.778 0.007,-0.008 -10,-9 L 37,20.778 C 36.468,20.3 35.771,20 35,20 c -1.657,0 -3,1.343 -3,3 0,0.885 0.391,1.673 1,2.222 L 32.993,25.23 37.182,29 H 19 c -1.657,0 -3,1.343 -3,3 0,1.657 1.343,3 3,3 H 37.182 L 32.994,38.77 33,38.777 c -0.61,0.55 -1,1.338 -1,2.223 0,1.657 1.343,3 3,3 0.771,0 1.468,-0.299 2,-0.777 l 0.007,0.008 10,-9 L 47,34.223 c 0.609,-0.55 1,-1.337 1,-2.223 0,-0.886 -0.391,-1.672 -1,-2.222 z" />
    </g></g></g>
</svg>

const ShoppingCartIconSvg = () => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" xmlSpace="preserve">
    <g id="SHOPPING_CART_1_" enableBackground="new    "><g id="SHOPPING_CART"><g>
                <path d="M52.2,39.993c1.132-0.074,2.092-0.776,2.536-1.763l0,0l9-20l-0.006-0.002C63.9,17.853,64,17.439,64,17
				c0-1.657-1.343-3-3-3H16.578l-1.747-4.991L14.82,9.013C14.411,7.844,13.309,7,12,7H3c-1.657,0-3,1.343-3,3s1.343,3,3,3h6.872
				l11.55,33H16c-3.314,0-6,2.686-6,6s2.686,6,6,6s6-2.686,6-6h28c0,3.314,2.686,6,6,6s6-2.686,6-6s-2.686-6-6-6H27.778
				l-1.497-4.279L52.2,39.993L52.2,39.993z M24.224,35.846L18.679,20h37.68l-6.356,14.127L24.224,35.846z"/>
    </g></g></g>
</svg>

export class Home extends Component {
  static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            slides: [],            
            loading: true,
            videoUrl: null
        }; 
    }

    renderPositionContainer = (slide) => {
        switch (slide.row) {
            case "1":
                return <>
                    {
                        slide.column === "1"
                            ? <div className="slider-left-container" style={{ top: '24px' }}>
                                <h1 className="slide-title" style={{ color: '#' + slide.color }}>{slide.title}</h1>
                                <p className="slide-desc" style={{ color: '#' + slide.color }}>{slide.description}</p>
                                <button className="slider-button" style={{ color: '#' + slide.color }} onClick={() => { this.props.history.push(slide.buttonLink) }}>{slide.button}</button>
                            </div>
                            : <div className="slider-right-container" style={{ top: '24px' }}>
                                <h1 className="slide-title" style={{ color: '#' + slide.color }}>{slide.title}</h1>
                                <p className="slide-desc" style={{ color: '#' + slide.color }}>{slide.description}</p>
                                <button className="slider-button" style={{ color: '#' + slide.color }} onClick={() => { this.props.history.push(slide.buttonLink) }}>{slide.button}</button>
                            </div>
                    }
                </>
            case "2":
                return <>
                    {
                        slide.column === "1"
                            ? <div className="slider-left-container" style={{ top: '50%', transform: 'translate(0, -50%)' }}>
                                <h1 className="slide-title" style={{ color: '#' + slide.color }}>{slide.title}</h1>
                                <p className="slide-desc" style={{ color: '#' + slide.color }}>{slide.description}</p>
                                <button className="slider-button" style={{ color: '#' + slide.color }} onClick={() => { this.props.history.push(slide.buttonLink) }}>{slide.button}</button>
                            </div>
                            : <div className="slider-right-container" style={{ top: '50%', transform: 'translate(0, -50%)' }}>
                                <h1 className="slide-title" style={{ color: '#' + slide.color }}>{slide.title}</h1>
                                <p className="slide-desc" style={{ color: '#' + slide.color }}>{slide.description}</p>
                                <button className="slider-button" style={{ color: '#' + slide.color }} onClick={() => { this.props.history.push(slide.buttonLink) }}>{slide.button}</button>
                            </div>                            
                    }
                </>
            case "3":
                return <>
                    {
                        slide.column === "1"
                            ? <div className="slider-left-container" style={{ bottom: '24px' }}>
                                <h1 className="slide-title" style={{ color: '#' + slide.color }}>{slide.title}</h1>
                                <p className="slide-desc" style={{ color: '#' + slide.color }}>{slide.description}</p>
                                <button className="slider-button" style={{ color: '#' + slide.color }} onClick={() => { this.props.history.push(slide.buttonLink) }}>{slide.button}</button>
                            </div>
                            : <div className="slider-right-container" style={{ bottom: '24px' }}>
                                <h1 className="slide-title" style={{ color: '#' + slide.color }}>{slide.title}</h1>
                                <p className="slide-desc" style={{ color: '#' + slide.color }}>{slide.description}</p>
                                <button className="slider-button" style={{ color: '#' + slide.color }} onClick={() => { this.props.history.push(slide.buttonLink) }}>{slide.button}</button>
                            </div>
                    }
                </>
            default:
                return <></>
        }
    }

    componentDidMount() {
        this.getSlidesAsync();
    }

    async getSlidesAsync() {
        const requestOptions = { method: 'GET' };
        const response = await fetch('admin/getallslides', requestOptions);
        const data = await response.json();
                
        this.setState({ slides: data, loading: false });
    }

    render() {
        let loader = this.state.loading && <Loader loading={this.state.loading} />
    return (
        <div>  
            {SEO({ title: "Výkonné a herní notebooky na míru | EUROCOM | Goldmax", description: "Výkonné pracovní notebooky na výpočty, grafiku, programování a střih videa." }) }
            { loader}
            <div className="anim-slide">
                <Slider autoplay={4000}>                
                    {this.state.slides.map((item, index) => (
                        <div
                            key={index}
                            style={{ background: `url('${item.imageLink}') no-repeat center center` }}
                        >
                            {this.renderPositionContainer(item)}
                        </div>
                    ))}
                </Slider>
            </div>

            <div className="home-slogan-container">                
                <p style={{ marginBottom: '4px' }}><i>“Vizí značky EUROCOM je neustále bořit hranici mezi konvenčními PC a notebooky, které stavíme individuálně „na míru“ dle specifických projektových požadavků profesionálům napříč všemi odvětvími. Flexibilní, unikátní řešení, které konkurence neumí...„</i></p>
                <p className="slogan-author"><i>Ing. Ondřej Káhn, jednatel</i></p>
            </div>


           <LogosContainer />

            <div style={{ textAlign: 'center', margin: "0 0 24px 0", padding: "56px 0", backgroundImage: "url('images/video-bg.png')", backgroundRepeat: "no-repeat", backgroundSize: "100% 100%" }}>
                
                {/* <video src="video/eurocom-cz-fin.mp4" controls autoPlay loop muted playsInline style={{ width: "80%", height: "80%" }} />*/}
                <video src="video/eurocom-cz-fin.mp4" poster="video/scene.jpg" controls muted style={{ width: "80%", height: "80%" }} />
            </div>



            <h1 className="info-title">Věděli jste PROČ nakoupit u nás?</h1>           
            <p className="info-subtitle">aneb jednou EUROCOM, navždy EUROCOM...</p>

            <div className="info-grid-container">
                <div className="info green">
                    <PlusIconSvg />
                    <p>Jsme nejvýkonnější na trhu, supersport světa notebooků. Cenově se pohybujeme od 50-250 tisíc Kč, přesto se k nám klienti rádi vrací, protože se jim z pohledu návratnosti investice vyplácíme.</p>
                    <PlusIconSvg />
                    <p>Konzultace poptávek, známe naše zákazníky a teprve pak individuálně konfigurujeme „na míru“. Lidé nejsou stejní. Univerzální = velký kompromis.</p>
                    <PlusIconSvg />
                    <p>Cílíme na 1% trhu. Nejsme tuctovka, ale luxusní, prémioví a hlavně jedineční, jako lidé samotní. Jsme malosériová výroba s důrazem na dlouhodobou kvalitu a preciznost a spolehlivost.</p>
                    <PlusIconSvg />
                    <p>Naše klienty často notebook EUROCOM živí nebo u něj sedí celé dny. Nahrazujeme stolní PC, boříme výkonové hranice s pomáháme Vám vyniknout.</p>
                </div>               
                <div>
                    <div className="circle-container">
                        <div className="circle">
                            <div className="circle-title">hodnocení</div>
                            <h1 className="circle-value">4.8</h1>
                            <div className="stars">
                                <StarIconSvg />
                                <StarIconSvg />
                                <StarIconSvg />
                                <StarIconSvg />
                                <StarIconSvg>
                                        <linearGradient id="half">
                                            <stop offset="0%" stop-color="#FEC460" />
                                            <stop offset="70%" stop-color="#FEC460" />
                                            <stop offset="70%" stop-color="#ACACAC" />
                                            <stop offset="100%" stop-color="#ACACAC" />
                                        </linearGradient>
                                </StarIconSvg>
                            </div>
                        </div>
                    </div>
                    <div className="info-os">
                        <p><strong>Windows 11 </strong>Ready</p>
                        <p><strong>Linux</strong> Ready</p>
                    </div>
                </div>
                <div className="info red">
                    <MinusIconSvg />
                    <p>Konkurence klienty nezná, neřeší jejich potřeby, hlavní je cokoliv univerzálního prodat.</p>
                    <MinusIconSvg />
                    <p>Konkurence prodává skrze stovky eshopů, mnohdy staré a neaktualizované produkty, které leží ve skladech i měsíce. Tuctové zboží, tuctový zákazník.</p>
                    <MinusIconSvg />
                    <p>Konkurence je levnější nejen cenově, ale i zpracováním, odladěním či životnosti.</p>
                    <MinusIconSvg />
                    <p>S konkurencí jste o krok pozadu, vážně to chcete?</p>
                    <div className="info-grid-full-row">
                        <p className="info-href-title">Kontaktuj nás ještě dnes!</p>                        
                        <div className="info-href-container">
                            {/*<div className="round-svg-icon"><ShoppingCartIconSvg /></div>*/}
                            {/*<span className="info-href">*/}
                            {/*    Zde nakoupíš to nejlepší<RightArrowIconSvg />*/}
                            {/*</span>*/}
                            <div className="round-svg-icon"><ShoppingCartIconSvg /></div>
                            <a target="_blank" href="https://www.czc.cz/notebooky/eurocom/produkty" className="info-href">
                                Exkluzivně skladem na <span style={{ color: "#D91F29", fontWeight: 700 }}>CZC.</span><span style={{ color: "#20648B", fontWeight: 700, paddingRight: "2px" }}>CZ</span><RightArrowIconSvg />
                            </a>
                        </div>
                    </div>                   
                </div>
            </div>

            <div className="infographic-container">
                <div className="infographic-item-container">
                    <img src="icons/specialize.svg" />
                    <hr />
                    <p>PROJEKTOVÉ A SPECIALIZOVANÉ IT ŘEŠENÍ</p>
                </div>
                <div className="infographic-item-container">
                    <img src="icons/custom.svg" />
                    <hr />
                    <p>ZAKÁZKOVÁ MALOSÉRIOVÁ VÝROBA OD ROKU 2009</p>
                </div>
                <div className="infographic-item-container">
                    <img src="icons/speed.svg" />
                    <hr />
                    <p>NEJVÝKONNEJŠÍ MOBILNÍ ŘEŠENÍ NA TRHU</p>
                </div>
                <div className="infographic-item-container">
                    <img src="icons/upgrade.svg" />
                    <hr />
                    <p>BEZKONKURENČNÍ VARIABILITA A UPGRADOVATELNOST</p>
                </div>
                <div className="infographic-item-container">
                    <img src="icons/config.svg" />
                    <hr />
                    <p>MOŽNOST KONFIGURACE<br />„NA MÍRU“</p>
                </div>
                <div className="infographic-item-container">
                    <img src="icons/unique.svg" />
                    <hr />
                    <p>UNIKÁTNÍ ŘEŠENÍ, KTERÉ KONKURENCE NEUMÍ</p>
                </div>
            </div>


            <hr style={{ paddingBottom: 0, marginBottom: 0 }} />
            <h5 style={{ textAlign: 'center', paddingTop: '8px' }}>REFERENČNÍ KLIENTI</h5>
            <img style={{ width: '100%', margin: '24px 0 0 0' }} src="icons/references.png" />

            {/*
        <h1>Hello, world!</h1>
        <p>Welcome to your new single-page application, built with:</p>
        <ul>
          <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
          <li><a href='https://facebook.github.io/react/'>React</a> for client-side code</li>
          <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
        </ul>
        <p>To help you get started, we have also set up:</p>
        <ul>
          <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
          <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
          <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>
        </ul>
        <p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>
            */}
        </div>
    );
  }
}

const duration = 15; // time for one sphere to go from right to left    
const flexGap = 32;

const LogosContainer = () => {
    const [timeLine] = useState(gsap.timeline());
    const [itemWidthFromStart, setItemWidthFromStart] = useState([]);
    const constainerRef = useRef(null);

    useEffect(() => {
        //if (constainerRef.current) {
        //    let item = constainerRef.current.children[0];
        //    if (item && item.clientWidth > 0) {
        //        animate();
        //    }
        //}
        setTimeout(() => {
            if (constainerRef.current) {
                let item = constainerRef.current.children[0];
                if (item && item.clientWidth > 0) {
                    animate();
                }

            }
        }, 3000);
    }, []);

    //useEffect(() => {
    //    if (constainerRef.current) {
    //        const items = constainerRef.current.children;
    //        for (let i = 0; i < items.length; i++) {

    //            let tempWidth = 0;
    //            if (i === 0) {
    //                let tese = items[i].offsetWidth;
    //                tempWidth = items[i].clientWidth;
    //            } else {
    //                tempWidth = itemWidthFromStart[i - 1] + items[i].clientWidth + (i !== items.length ? flexGap : 0);
    //                //if (i + 1 < which.length) {
    //                //    totalWidth += flexGap;
    //                //    //gapAdded = true;
    //                //}
    //            }
    //            setItemWidthFromStart(prev => [...prev, tempWidth]);

    //            //totalWidth += which[i].clientWidth;
    //            //itemWidthIncludedPrevItems.set(i, totalWidth); // nastaveni sirky, ktera je potrebna pro uplne skryti itemu
    //            //if (i + 1 < which.length) {
    //            //    totalWidth += flexGap;
    //            //    //gapAdded = true;
    //            //}
    //        }
    //        animate();
    //    }
    //}, [constainerRef]);

    const horizontalInfineLoopAnimation = (which, time, flexGap) => {

        let itemWidthIncludedPrevItems = new Map();

        let totalWidth = 0;
        gsap.set(which, {
            x: (i) => {
                //let gapAdded = false;
                //console.log("GSAP - CLIENT WIDTH: ", which[i].clientWidth);
                //console.log("GSAP - OFFSET WIDTH: ", which[i].offsetWidth);
                //console.log("GSAP - BoundingClientRect: ", which[i].getBoundingClientRect().width);

                //totalWidth += which[i].clientWidth;
                totalWidth += which[i].getBoundingClientRect().width;
                //let rrrr = ReactDOM.findDOMNode(which[i]);
                //let testtt = which[i].getBoundingClientRect().width;
                itemWidthIncludedPrevItems.set(i, totalWidth); // nastaveni sirky, ktera je potrebna pro uplne skryti itemu
                if (i + 1 < which.length) {
                    totalWidth += flexGap;
                    //gapAdded = true;
                }
                //console.log("GSAP : FUNCTION :", i === 0 ? 0 : totalWidth - which[i].clientWidth - (gapAdded ? flexGap : 0));

                return 0;
                //return i === 0 ? 0 : totalWidth - which[i].clientWidth - (gapAdded ? flexGap : 0);
            }
        });

        //gsap.set(which, { x: 0 });

        //const mod = gsap.utils.wrap(0, totalWidth);

        var action = Array.from(which).map((x, i) => gsap.timeline()
            .to(which[i], {
                x: '-=' + (totalWidth + flexGap),
                modifiers: {
                    x: (x) => {
                        const currentWidth = parseFloat(x);
                        
                        if (currentWidth <= -itemWidthIncludedPrevItems.get(i)) {
                            // na konec sirky konteineru
                            const tempWidth = totalWidth + flexGap + currentWidth;
                            //console.log("GSAP for item with index {" + i + "} : TIMELINE TO MODIF:", tempWidth);
                            return `${tempWidth}px`;
                        } else {
                            //console.log("GSAP : TIMELINE TO X:", x);
                            return x;
                        }
                        
                        //return mod(parseFloat(x)) + "px";
                    }
                },
                duration: time, ease: Linear.easeNone,
                repeat: -1,
            }));
        return action

        //var action = gsap.timeline()
        //    .to(which, {
        //        x: '-=' + totalWidth,
        //        modifiers: {
        //            x: (x, target, i) => {
        //                console.log("GSAP : TIMELINE TO :", x);
        //                console.log("GSAP : TIMELINE TO TARGET :", i);
        //                return mod(parseFloat(x)) + "px";
        //            }
        //        },
        //        duration: time, ease: 'none',
        //        repeat: -1,
        //    });
        //return action
    }

    
    const animate = () => {       
        //const container = constainerRef.current;
        const items = constainerRef.current.children;


        timeLine.add(horizontalInfineLoopAnimation(items, duration, flexGap), 1);

        ////const clonedItems = [];
        //// Sum of width for all elements and space between them
        //let w = 0;
        //for (let i = 0; i < items.length; i++) {
        //    w += items[i].clientWidth;
        //    //const cloned = cloneElement(items[i], {});
        //    //clonedItems.push(cloned);
        //}
        //w += flexGap * (items.length - 1);

        //const dirFromRight = "-=" + w;



        //var master = gsap.timeline().add(marquee(items, 15, flexGap, w), 1);
        //master.play();



        //const tlDelay = interval * (items.length + 1) - duration; // time between the start of one timeline and the other's
        //const w = (container.clientWidth + items[0].clientWidth + 100) / 2; // assuming same size for every sphere and adding an arbitrary 100px margin

        //for (let i = 0; i < items.length; i++) {
        //    //tl[0].to(items[i], { x: -w, ease: Linear.easeNone, duration: (Number(duration) / 2) }, interval);
        //    //tl[0].to(items[i], { x: -w, ease: Linear.easeNone, duration: (Number(duration) / 2) }, interval);



        //    tl[0].to(items[i], { x: -w, ease: Linear.easeNone, duration: 4 }, interval);
        //    tl[0].to(items[i], { x: 0, ease: Linear.easeNone, duration: 0 }, interval);


        //    //tl[1].fromTo(clonedItems[i], { x: w }, { x: -w, ease: Linear.easeNone, duration, delay: duration }, interval + 1);

        //    //tl[1].fromTo(items[i], { x: w }, { x: -w, ease: Linear.easeNone, duration }, interval + 1);
        //}


        //for (let i = 0; i < 2; i++) {
        //    tl[i]
        //        .staggerFromTo(
        //            container,
        //            duration,
        //            { x: w },
        //            { x: -w, ease: Linear.easeNone },
        //            interval
        //        )
        //        .staggerTo(container, duration / 6, { scale: 1.25 }, interval, duration / 6)
        //        .staggerTo(
        //            container,
        //            duration / 6,
        //            { scale: 1 },
        //            interval,
        //            (duration * 4) / 6
        //        );
        //}
                
        //tl[1].delay(interval * items.length);

        //tl[0].play();//.call(() => chceckStopInitTimeline(items, w));


        //tl[1].repeatDelay(tlDelay).play();
       


        //const width = container.clientWidth;

        //const tl = new TimelineMax({ repeat: -1 });
        //tl.to(constainerRef.current, 7, { x: -(width / 2), ease: Power0.easeNone });
    }

    //const onMouseEnterItem = (e) => {
    //    var el = e.currentTarget;
    //    if (timeLine.paused()) {
    //        //timeLine.paused(false);
    //        gsap.to(el, .25, { scale: 1, filter: "grayscale(1)" });
    //    } else {
    //        //timeLine.paused(true);
    //        gsap.to(el, .25, { scale: 1.32, filter: "grayscale(0)" });
    //    }

    //    //timeLine.paused(!timeLine.paused())
    //}
    const scaleUpAnimation = (e) => gsap.to(e.currentTarget, .25, { scale: 1.26, opacity: 1, filter: "grayscale(0)" });
    const scaleDownAnimation = (e) => gsap.to(e.currentTarget, .25, { scale: 1, opacity: .54, filter: "grayscale(1)" });
    const toggleAnimation = () => timeLine.paused(!timeLine.paused());

    const onClickAction = (url) => window.open(url, "_blank");

    return <div onMouseEnter={toggleAnimation} onMouseLeave={toggleAnimation} className="logos-container" ref={constainerRef}>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.autodesk.cz/products/3ds-max")}>
            <img src="logos/logo_3ds_max.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.autodesk.cz/products/maya")}>
            <img src="logos/logo_maya.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.autodesk.cz/products/autocad")}>
            <img src="logos/logo_autocad.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.autodesk.cz/products/revit")}>
            <img src="logos/logo_revit.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.autodesk.cz/products/inventor")}>
            <img src="logos/logo_inventor.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.solidworks.cz")}>
            <img src="logos/logo_solidworks.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.3ds.com/products-services/catia")}>
            <img src="logos/logo_catia.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.cinema4d.cz")}>
            <img src="logos/logo_cinema.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.adobe.com/cz/products/photoshop")}>
            <img src="logos/logo_photoshop.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.adobe.com/cz/products/aftereffects")}>
            <img src="logos/logo_after_effects.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.adobe.com/cz/products/premiere")}>
            <img src="logos/logo_adobe_premier.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.vray.cz")}>
            <img src="logos/logo_vray.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.unrealengine.com")}>
            <img src="logos/logo_unreal_engine.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.cryengine.com")}>
            <img src="logos/logo_cryengine.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://unity.com")}>
            <img src="logos/logo_unity.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.blender.org")}>
            <img src="logos/logo_blender.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.lumion.cz")}>
            <img src="logos/logo_lumion.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://sketchup.cz")}>
            <img src="logos/logo_sketchup.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.rhino3d.com")}>
            <img src="logos/logo_rhinoceros.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.ubuntu.cz")}>
            <img src="logos/logo_ubuntu.png" />
        </div>
        <div onMouseEnter={scaleUpAnimation} onMouseLeave={scaleDownAnimation} className="logo-item" onClick={() => onClickAction("https://www.oculus.com/?locale=cs_CZ")}>
            <img src="logos/logo_vr.png" />
        </div>
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_3ds_max.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_maya.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_autocad.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_revit.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_inventor.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_solidworks.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_catia.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_cinema.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_photoshop.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_after_effects.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_adobe_premier.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_vray.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_unreal_engine.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_cryengine.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_unity.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_blender.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_lumion.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_sketchup.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_rhinoceros.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_ubuntu.png" /></div>*/}
        {/*<div onMouseEnter={onMouseEnterItem} onMouseLeave={onMouseEnterItem} className="logo-item"><img src="logos/logo_vr.png" /></div>            */}
    </div>
    
}

import React from 'react';
import './Students.css';
import SEO from './SEO';

export const Students = () => {
    return (
        <>
            {SEO({ title: "Levné notebooky pro studenty | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
            <h1>Slevy pro studenty</h1>
            <p>Pokud jste student, učitel, profesor či doktorand, požádejte nás o garantovanou slevu ve výši 10% ceníkové ceny. Také jsme schopni Vám pomoci s přípravou tenderu pro Vaši univerzitu či jiné speciální projekty spojené s Akademickou půdou. Kontaktujte nás s Vašim požadavkem na <a href="mailto:info@goldmax.cz">info@goldmax.cz</a> ještě dnes.</p>

            <h2 style={{ paddingTop: "16px" }}>Splátkový prodej notebooků</h2>
            <p style={{ paddingTop: "4px", paddingBottom: "48px" }}>Splátkový prodej nabízí Goldmax.cz pro všechny, kteří o něj projeví zájem. Nejsme jako ostatní, kteří preferují složité a dokumentově náročné procedury schvalování/bonifikace. My neřeším, jaké problémy jste měli se splácením a bonitou v minulosti. Proto, pokud jste schopni uhradit 1/3 ceny notebooku předem jako akontaci a zbytek nám splatíte dle dohody běhěm 6-24ti měsíců, věříme, že se dohodneme. Na nic víc se neptáme, tak co, už máte svůj notebook v košíku?</p>
           

            <img className="full-img" src="images/static-content/students_2.jpg" />
            <img style={{ paddingBottom: '44px' }} className="full-img" src="images/static-content/students_1.jpg" />

            <div className="s-third-con-l">
                <img className="full-img" src="images/static-content/students_3.jpg" />
                <h5>Vysoké školy a univerzity</h5>
                <p>Zasloužíte si vysoce výkonný stroj, který dokáže držet krok s požadavky vašeho vzdělání (a udržet si náskok před svými vrstevníky).</p>
            </div>
            <div className="s-third-con-c">
                <img className="full-img" src="images/static-content/students_4.jpg" />
                <h5>Základní a střední školy</h5>
                <p>Všechny systémy Eurocom jsou navrženy inteligentně. Čím dříve začnete, tím pružnější a inteligentnější budete!</p>
            </div>
            <div className="s-third-con-r">
                <img className="full-img" src="images/static-content/students_5.jpg" />
                <h5>Postgraduální a další</h5>
                <p>Učení, výzkum a vývoj se nezastaví. Špičkový systém Eurocom vám umožní získat konkurenční výhodu, kterou potřebujete k dlouhodobému úspěchu.</p>
            </div>
            <div style={{ clear: 'both' }}></div>   
        </>
    );
};
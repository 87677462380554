import React, { Component } from 'react';
import Loader from './Loader';
import A_Notebook_VM from './ANotebookComponent'
import NotebookImage from './ANotebookComponent'
import { Route } from 'react-router-dom';
import { authenticationService } from '../services/authentication.service';
import { ErrorNotFound } from './ErrorNotFound';
import { authHeader } from '../helpers/auth-header';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const getType = (number) => {
    switch (number) {
        case 0:
            return "Top";
        case 1:
            return "Workstation";
        case 2:
            return "Server";
        case 3:
            return "Enterprise";
        case 4:
            return "Gaming";
    }
}

export class ANotebookDetail extends Component {
    static displayName = ANotebookDetail.name;
     
    constructor(props) {
        super(props);
        this.state = { notebook: new A_Notebook_VM(), loading: true, className: '', logged: false, nav1: null, nav2: null };
        this.id = 0;
        if (this.props.location.state) {
            this.id = this.props.location.state.id;
        }

        if (authenticationService.currentUserValue) {
            this.state.logged = true;
        }
    }
    
    componentDidMount() {
        if (this.state.logged && this.id !== 0) {
            this.getNotebookAsync();
        } 
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    setClassName(components) {
        if (this.state.className != components.component.className) {
            this.state.className = components.component.className;
            return <h5 className="top-offset">{components.component.className}</h5>
        }
    }

    static renderNotebookTable(_this) {

        return (
            _this.state.notebook.components.map((p, i) =>
                p.isSelected &&
                <React.Fragment>
                    {_this.setClassName(p)}
                    {
                        p.isDefault
                            ?
                            <b>
                                <div className="row-container bottom-border">
                                    <div className="col-sm-3 padding-right-only">{p.component.name}</div>
                                    <div className="col-sm-6 padding-right-only">{p.component.description == "" ? <span>&nbsp;</span> : p.component.description}</div>
                                    <div className="col-sm-1 padding-right-only">{p.price == 0 ? <span>&nbsp;</span> : p.price}</div>
                                    <div className="col-sm-1 padding-right-only">{p.discountPrice == 0 ? <span>&nbsp;</span> : p.discountPrice}</div>
                                    <div className="col-sm-1 nopadding">{p.discountNote}</div>                                
                                </div>
                            </b>
                            : 
                            <div className="row-container bottom-border">
                                <div className="col-sm-3 padding-right-only">{p.component.name}</div>
                                <div className="col-sm-6 padding-right-only">{p.component.description == "" ? <span>&nbsp;</span> : p.component.description}</div>
                                <div className="col-sm-1 padding-right-only">{p.price == 0 ? <span>&nbsp;</span> : p.price.toLocaleString()}</div>
                                <div className="col-sm-1 padding-right-only">{p.discountPrice == 0 ? <span>&nbsp;</span> : p.discountPrice.toLocaleString()}</div>
                                <div className="col-sm-1 nopadding">{p.discountNote}</div>
                            </div>
                    }
                </React.Fragment>
            )
        );
    }

    render() {
        if (!this.state.logged || this.id === 0) {
            return <Route path="*" component={ErrorNotFound} />
        }
        let contents = this.state.loading
            ? <Loader loading={this.state.loading} />
            : ANotebookDetail.renderNotebookTable(this);

        const { notebook } = this.state;
        let notebookDescription = notebook.notebookDescription + "";
        let description = [];
        let defaultImg = new NotebookImage();
        let otherImage = [];
        if (typeof this.state.notebook.notebookImages !== 'undefined' && this.state.notebook.notebookImages.length > 0) {
            defaultImg = this.state.notebook.notebookImages.find((rec) => { return rec.isDefault == true });
            if (typeof defaultImg === 'undefined') {
                defaultImg = new NotebookImage();
            }
            otherImage = this.state.notebook.notebookImages.filter((rec) => { return rec.isDefault != true });
        }
        if (typeof notebookDescription !== 'undefined' && notebookDescription != "") {
            description = notebookDescription.split(";");
        }
        return (
            <div className="page-container">
                <h2>{this.state.notebook.notebookName}</h2>
                <div>{getType(this.state.notebook.type)}{this.state.notebook.isUnconfigurable && " - NEKONFIGUROVATELNÝ"} </div>
                <div className="row-container top-offset">
                    <div className="col-sm-6 nopadding">
                        {description.map(desc =>
                            <div className="col-sm-12 nopadding">• {desc}</div>
                        )}                        
                        <div className="col-sm-12 padding-top-only">Celková cena notebooku: {this.state.notebook.notebookPrice.toLocaleString()} Kč s DPH</div>
                        {this.state.notebook.isUnconfigurable && <div className="col-sm-12 nopadding">Celková cena po slevě: {this.state.notebook.notebookDiscountPrice.toLocaleString()} Kč s DPH</div>}
                        {this.state.notebook.isUnconfigurable && <div className="col-sm-12 nopadding">Poznámka ke slevě: {this.state.notebook.notebookDiscountNote}</div>}
                    </div>
                    <div className="col-sm-6 nopadding text-center">
                        <div style={{ width: '70%', float: 'right' }}>
                            <Slider
                                asNavFor={this.state.nav2}
                                ref={slider => (this.slider1 = slider)}
                                accessibility={false}
                                arrows={false}>
                                <div>
                                    {defaultImg.link ? <img style={{ width: '100%' }} src={defaultImg.link} /> : <span className="align-middle">NENÍ VYBRÁN NÁHLEDOVÝ OBRÁZEK !</span>}                               
                                </div>
                                {otherImage.map(img =>
                                    <div>
                                        <img style={{ width: '100%' }} src={img.link} />
                                    </div>
                                )}                             
                            </Slider>
                            <hr/>
                            <Slider style={{ padding: '12px 0' }}
                                asNavFor={this.state.nav1}
                                ref={slider => (this.slider2 = slider)}
                                slidesToShow={3}
                                swipeToSlide={true}
                                focusOnSelect={true}
                                centerMode={true}
                                autoplay={true}
                                autoplaySpeed={3000}
                                pauseOnHover={true}
                                cssEase={"linear"}
                                infinite={true}
                                accessibility={true}
                                arrows={true}
                            >
                                <div>
                                    {defaultImg.link ? <img style={{ margin: '0 auto', width: '80%' }} src={defaultImg.link} /> : <span className="align-middle">NENÍ VYBRÁN NÁHLEDOVÝ OBRÁZEK !</span>}                                
                                </div>
                                {otherImage.map(img =>
                                    <div>
                                        <img style={{ margin: '0 auto', width: '80%' }} src={img.link} />
                                    </div>
                                )}                            
                            </Slider>    
                        </div>
                    </div>                                
                </div>    
                
                <div className="row-container top-offset">                    
                    <div className="col-sm-3 font-weight-bold nopadding">Název</div>
                    <div className="col-sm-6 font-weight-bold nopadding">Popis</div>
                    <div className="col-sm-1 font-weight-bold nopadding">Cena</div>
                    <div className="col-sm-1 font-weight-bold nopadding">Sleva</div>
                    <div className="col-sm-1 font-weight-bold nopadding">Poznámka</div>                    
                </div>              
                {contents}                
            </div>
        );
    }

    async getNotebookAsync() {
        const requestOptions = { method: 'GET', headers: authHeader() };
        const response = await fetch('admin/updatenotebook/' + this.id, requestOptions);
        const data = await response.json();
        this.setState({ notebook: data, loading: false });
                
    }
}

import React, { useState } from 'react';
import { DynamicForm } from './DynamicForm';
import SEO from './SEO';

const formSubmitBtnName = 'ODESLAT';
const dialogHeader = 'Firemní testovací zápůjčka';
const successMessage = 'Váš požadavek byl odeslán, budeme Vás kontaktovat během následujícího pracovního dne.';
const errorMessage = 'Omlouváme se, ale Váš pořadavek se nepodařilo odeslat, kontaktujte nás prostřednictvím e-mailu nebo telefonu.';
const fields = [
    { prop: 'name', label: 'Kontaktní osoba', placeholder: 'Jméno a příjmení', type: 'text', size: 6, validation: 'Zadejte prosím Vaše celé jméno.', validationtype: 'required' },
    { prop: 'firm', label: 'Firma', placeholder: 'Název firmy', type: 'text', size: 6, validation: '', validationtype: '' },
    { prop: 'email', label: 'E-mail', placeholder: 'Váš e-mail', type: 'email', size: 6, validation: 'Zadejte prosím platnou emailovou adresu.', validationtype: 'email' },
    { prop: 'phone', label: 'Telefonní číslo', placeholder: 'Včetně předčíslí +420', type: 'text', size: 6, validation: 'Zadejte prosím platné telefonní číslo (+420123456789).', validationtype: 'phone' },
    { prop: 'count', label: 'Plánovaný počet nových notebooků', placeholder: '', type: 'number', size: 6, validation: 'Zadejte prosím počet notebooků.', validationtype: 'required' },
    { prop: 'price', label: 'Plánovaný rozpočet na nové notebooky', placeholder: '', type: 'number', size: 6, validation: 'Zadejte prosím  rozpočet.', validationtype: 'required' },   
    { prop: 'message', label: 'Poznámky a další instrukce', placeholder: 'Vaše zpráva...', type: 'textarea', size: 12, validation: '', validationtype: '' }
];
let dynamicObject = {};
fields.map(item => dynamicObject[item.prop] = '');

export const TestLend = () => {   
    const [object, setObject] = useState(dynamicObject);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBody, setDialogBody] = useState('');

    const handleObjectChange = e => {
        const { id, value } = e.target;
        setObject(prevObj => ({ ...prevObj, [id]: value }));
    };
    const onSubmit = () => {
        setLoading(true);
        sendFomrAsync();
    };
    const onCloseDialog = () => {
        setDialogOpen(false);
    }

    const sendFomrAsync = async () => {       
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        const response = await fetch('form?id=4', requestOptions);
        if (response.ok) {
            setDialogBody(successMessage);
            setObject(dynamicObject);
        }
        else {
            setDialogBody(errorMessage);
        }
        setLoading(false);
        setDialogOpen(true);
    }

    return (
        <>
            {SEO({ title: "Firemní testovací zápůjčka | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
            <h1>Firemní testovací zápůjčka</h1>
            <p>Plánujete nákup jednoho a více notebooku na firmu/IČ v cenové relaci 45.000 Kč a výše? Nejste si jisti výběrem, značkou a typem notebooku? Pokud i nadále pochybujete o kvalitách značky EUROCOM, je tato nabídka určena právě Vám.</p>
            <p>V rámci rozšiřování portfolia zákazníků a šíření renomé značky nyní oslovujeme specializované firmy a profesionály všech oborů, kterým umožníme <b>zapůjčit</b> si <b>a otestovat</b> prémiový notebook EUROCOM zcela <b>ZDARMA</b>.</p>
            <p>Pokud o brzkém nákupu výkonných notebooků a mobilních pracovních stanic vyšších cenových relacích uvažujete, zapůjčíme Vám náš testovací model po časově omezenou dobu, který si budete moci osahat a následně otestovat v reálném firemním prostředí dle Vašich představ – bez vysokých investic a bez rizika.</p>
            <p><b>Schvalovací postup:</b></p>
            <ol>
                <li>Vyplňte registrační formulář zápůjček, kde uvedete pravdivé údaje.</li>
                <li>Proběhne proces schvalování a sjednávání termínu (forma a doba zápůjčky).</li>
                <li>Schválení předávacího protokolu a bezpečnostní zálohy proti odcizení/poškození.</li>
                <li>Zápůjčka notebooku u Vás ve firmě.</li>
                <li>Návrat notebooku k nám, kontrola stavu a vyplacení bezpečnostní zálohy</li>
            </ol>
            <p>Komu je zápůjčka notebooku především určena?</p>
            <ul>
                <li>Státní sektor i soukromé podniky využívající špičkovou výpočetní techniku a servery.</li>
                <li>Strojírenská produkce, subdodavatelé automobilového průmyslu a simulací výroby.</li>
                <li>Grafická studia zaměřená na střih videa ve vysokém rozlišení a úpravu fotografií.</li>
                <li>Konstrukce CAx/GIS/PLM ve 3D, vývoj technologií v oblasti mechaniky a proudění částic.</li>
                <li>IT specialisté a programátoři poskytující servis a vývoj firemních/cloudových aplikací.</li>
                <li>Inženýrské a projektové kanceláře zabezpečující realizaci a koordinaci staveb.</li>
                <li>Správci databází, dodavatelé ERP a komplexních podnikových informačních systémů.</li>
            </ul>

            <DynamicForm fields={fields} object={object} submitBtnName={formSubmitBtnName} onChangeObject={handleObjectChange} onSubmitForm={onSubmit} loading={loading} dialogHeader={dialogHeader} dialogBody={dialogBody} dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />

            <p style={{ paddingTop: '24px' }}>Veškeré další dotazy směřujte na <a href="mailto:info@goldmax.cz">info@goldmax.cz</a> či telefon <a href="tel:+420724583823">+420 724 583 823</a>.</p>
        </>
    );
};
import React, { Component } from 'react';
import { authenticationService } from '../services/authentication.service';
import './ANotebookComponent.css';

export class Admin extends Component {
    static displayName = Admin.name;

    constructor(props) {
        super(props);
        this.state = { loading: false, logged: false, login: "", password: "" };      
        this.handleNotebooks = this.handleNotebooks.bind(this);
        this.handleComponents = this.handleComponents.bind(this);
        this.handleNewsletter = this.handleNewsletter.bind(this);
        this.handleVouchers = this.handleVouchers.bind(this);
        this.handleCarousel = this.handleCarousel.bind(this);
        this.handleCurrency = this.handleCurrency.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.loginButton = this.loginButton.bind(this);
        this.logoutButton = this.logoutButton.bind(this);
        /*
        this.deleteDb = this.deleteDb.bind(this);
        */
        if (authenticationService.currentUserValue) {
            this.state.logged = true;
        }
    }

    handleNotebooks() {
        this.props.history.push('/admin-notebook');
        window.scrollTo(0, 0);
    }
    handleComponents() {
        this.props.history.push('/admin-component');        
        window.scrollTo(0, 0);
    }
    handleNewsletter() {
        this.props.history.push('/admin-newsletter'); 
        window.scrollTo(0, 0);
    }
    handleVouchers() {
        this.props.history.push('/admin-voucher');
        window.scrollTo(0, 0);
    }
    handleCarousel() {
        this.props.history.push('/admin-carousel');
        window.scrollTo(0, 0);
    }
    handleCurrency() {
        this.props.history.push('/admin-currency');
        window.scrollTo(0, 0);
    }
    handleLogin(e) {
        const value = e.target.value;        
        this.setState({ login: value });
    }
    handlePassword(e) {
        const value = e.target.value;
        this.setState({ password: value });
    }
    loginButton() {
        authenticationService.login(this.state.login, this.state.password).then(
            user => {
                this.setState({ logged: true });
            },
            error => {
                alert("Špatné uživatelské jméno nebo heslo.");
            }
        );
    }
    /*
    deleteDb() {
        const requestOptions = { method: 'GET' };
        fetch('admin/createdb', requestOptions).then(resp => alert(resp.status + resp.body));
    }
    */
    logoutButton() {
        authenticationService.logout();           
        this.setState({ logged: false });                
    }

    render() {        
        
        if (!this.state.logged) {
            // not logged in so redirect to login page with the return url
            return (
                <React.Fragment>                    
                    <div className="col-sm-12 text-center">
                        <input type="text" placeholder="Uživatelské jméno" value={this.state.login == "" ? "" : this.state.login} onChange={this.handleLogin} className="form-control input-text col-sm-4 col-centered" />
                    </div> 
                    <div className="col-sm-12 text-center">
                        <input type="password" placeholder="Heslo" value={this.state.password == "" ? "" : this.state.password} onChange={this.handlePassword} className="form-control input-text col-sm-4 col-centered" />
                    </div> 
                    <div className="col-sm-12 text-center">
                        <button className="submit-button rounded" onClick={this.loginButton}>PŘIHLÁSIT</button>
                    </div> 
                    {
                    /*
                    <div className="col-sm-12 text-center">
                        <button className="submit-button rounded" onClick={this.deleteDb}>DELETE DB</button>
                    </div>
                    */
                    }                     
                    <div style={{ height: '240px' }}></div>
                </React.Fragment>
                )
        }
        else {
            // authorised so return component            
            return (
                <React.Fragment>
                    <div className="col-sm-4 text-center">
                        <button className="submit-button rounded" onClick={this.handleNotebooks}>NOTEBOOKY</button>
                    </div>
                    <div className="col-sm-4 text-center">
                        <button className="submit-button rounded" onClick={this.handleComponents}>KOMPONENTY</button>
                    </div>
                    <div className="col-sm-4 text-center">
                        <button className="submit-button rounded" onClick={this.handleNewsletter}>ZPRAVODAJ</button>
                    </div>
                    <div className="col-sm-4 text-center">
                        <button className="submit-button rounded" onClick={this.handleVouchers}>KUPÓNY</button>
                    </div>
                    <div className="col-sm-4 text-center">
                        <button className="submit-button rounded" onClick={this.handleCarousel}>KARUSEL</button>
                    </div>
                    <div className="col-sm-4 text-center">
                        <button className="submit-button rounded" onClick={this.handleCurrency}>MĚNY</button>
                    </div>
                    <div className="col-sm-12 text-center">
                        <button className="submit-button rounded" onClick={this.logoutButton}>ODHLÁSIT</button>
                    </div> 
                    <div style={{ height: '640px' }}></div>
                </React.Fragment>
                )
        }
    }
}
export function classNameToString(className) {
    switch (className) {
        case 'System': return 'Modelová řada';
        case 'Display': return 'LCD Display';
        case 'GSync': return 'G-Sync';
        case 'DisplayCalibaration': return 'Kalibrace barev';
        case 'Cpu': return 'Procesor';
        case 'CpuHeatsink': return 'Chlazení Procesoru';
        case 'Gpu': return 'Grafická karta';
        case 'GpuSlot1': return 'Grafická karta 1';
        case 'GpuSlot2': return 'Grafická karta 2';
        case 'GpuHeatsink': return 'Chlazení grafiky';
        case 'GpuTechnology': return 'GPU technologie';
        case 'Ram': return 'Operační paměť RAM';
        case 'M2': return 'm.2 SSD disk';
        case 'M2Slot1': return 'm.2 SSD disk 1';
        case 'M2Slot2': return 'm.2 SSD disk 2';
        case 'M2Slot3': return 'm.2 SSD disk 3';
        case 'HardDisk': return 'Pevný disk 2,5" šachta';
        case 'HardDiskSlot1': return 'Pevný disk 2,5" šachta 1';
        case 'HardDiskSlot2': return 'Pevný disk 2,5" šachta 2';
        case 'HardDiskSlot3': return 'Pevný disk 2,5" šachta 3';
        case 'RaidOptions': return 'RAID pole';
        case 'WirelessLan': return 'Bezdrátový WiFi modul';
        case 'WebCamera': return 'Webkamera';
        case 'SecurityPackage': return 'Bezpečnostní úprava';
        case 'MobileBroadbandReady': return '4G/LTE modul';
        case 'BiometricDevice': return 'Biometrické systémy';
        case 'SecurityChip': return 'Zabezpečení dat pomocí šifrování';
        case 'EmbeddedSecurityChip': return 'Zabezpečení dat pomocí šifrování';
        case 'HDMIPort': return 'HDMI port';
        case 'ThunderboltPort': return 'Thunderbolt Port';
        case 'SmartCardReader': return 'SmartCard čtečka';
        case 'CardReader': return 'Čtečka paměťových karet';
        case 'WarrantyPlan': return 'Rozsah záruky';
        case 'KeyBoard': return 'Klávesnice';
        case 'Bios': return 'BIOS';
        case 'OS': return 'Operační systém';
        case 'Software': return 'Doplňkový software';
        case 'ACAdapter': return 'Náhradní adaptér';
        case 'PowerCord': return 'Náhradní kabeláž';
        case 'Batterry': return 'Náhradní baterie';
        case 'TVTuner': return 'Externí TV tuner';
        case 'OpticalDrive': return 'Optická mechanika';
        case 'CarAcAdapter': return 'Auto adaptér';
        case 'CarryingCase': return 'Brašny a kufry';
        case 'DockingStation': return 'Dokovací stanice';
        case 'ExternalOpticalDrive': return 'Externí optická mechanika';
        case 'ThermalPadsM2': return 'Termální podložky a chlazení';
        case 'GamingAccessories': return 'Herní příslušenství';
        case 'ExternalDisplay': return 'Externí LCD displej';
        case 'GraphicsTabletStylus': return 'Grafický tablet';
        case 'Others': return 'Ostatní příslušenství';
        default: return 'Unknown'
    }
}
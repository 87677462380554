import React from 'react';
import './CaseStudies.css';
import SEO from './SEO';

export const CaseStudies = () => {
    return (
        <>
            {SEO({ title: "Notebook na střih videa | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
            <h1>Případové studie a kam cílíme</h1>
            <h2>Typický průběh konzultace aneb chceme Vás poznat:</h2>

            <p style={{ paddingTop: "4px" }}>Goldmax: <i className="dark-text">„Dobrý den, jak Vám mohu pomoci?“</i></p>
            <p>Klient A: <i className="blue-text">„Chtěl bych výkonný 17“ notebook na PS a 4K video v Premiéře, dělám v reklamě.“</i></p>
            <p>Klient B: <i className="green-text">„Jsem programátor a hledám něco výkonného do 100.000 Kč.“</i></p>

            <p>Goldmax: <i className="dark-text">„Rozumím, pro Vaši činnost je vhodný model X/Y, kde je pro Vás zcela nejvyšší prioritou rychlosti komponent <span className="blue-text">(Klient A)</span> grafika s podporou CUDA a k tomu rychlá RAM plus vícejádrové CPU <span className="green-text">(Klient B)</span> CPU, RAM a poté SSD.“</i></p>
            <p>Klient A: <i className="blue-text">„OK, pomůžete mi sestavit daný model? Kolik byste doporučil RAM a co úložiště?“</i></p>
            <p>Klient B: <i className="green-text">„Dobře, máte nějaké rychlé LCD, které šetří oči? Jak máte servis?“</i></p>

            <p>Goldmax: <i className="dark-text">„<span className="blue-text">(Klient A)</span> Ano, pomůžeme, sestavíme na míru a pošleme k odsouhlasení a další konzultaci. Jaký HW máte nyní a kolik RAM větší projekt odhadem zabere? <span className="green-text">(Klient B)</span> Servis realizujeme tak, že Vám pošleme komponentu na výměnu bez ztráty záruky klientovi, pokud víme, kde je problém, tudíž zůstane notebook po celou dobu v pracovně schopném stavu (například výměna RAM modulu, disku, klávesnice, wifi modulu, grafiky či adaptéru) a doporučujeme zvolit 300Hz LCD modelu EUROCOM Sky Z7 R2.“</i></p>
            <p>Klient A: <i className="blue-text">„Dobře, můj email a telefon je XXX, nabízíte také splátkový prodej?“</i></p>
            <p>Klient B: <i className="green-text">„OK, pošlete mi dvě cenové alternativy, jak konfiguraci sestavit a já se rozmyslím.“</i></p>

            <p>Goldmax: <i className="dark-text">„<span className="blue-text">(Klient A)</span> Nabízíme splátkový prodej s akontací 1/3 ceny na 6-24 měsíců a nepožadujeme žádné bezdlužnosti, respektive neověřujeme bonitu klienta. <span className="green-text">(Klient B)</span> Notebook Vám sestavím dle našich 13 letých zkušeností, okomentujeme si je vzájemně a navrhneme změny či alternativy.“</i></p>
            <p>Klient A: <i className="blue-text">„Děkuji za bezva podporu a servis.“</i></p>
            <p>Klient B: <i className="green-text">„Díky za super reakci.“</i></p>

            <p>Toto je velmi zkrácený, avšak reálný scénář, dle jakého se snažíme poznat klienta. Dále se běžně ptáme, na jakém HW zákazník pracuje doposud, co ho dle jeho názoru brzdí, jaké další aplikace používá a jakým způsobem bude notebook využívat v budoucnu. To vše nám pomůže vytvořit si představu o ideálním notebooku pro konkrétního uživatele, jehož požadavky jsou většinou vysoce individuální. U nás notebook klient nemění za 2 roky, ale počítá nejméně s investicí na 3-5 let, zpravidla i déle. Životnost notebooku je pro nás prioritou.</p>
            <p>EUROCOM pomáhá dosahovat vyšších profesních výsledků a boří hranice mezi laptopy a stolními počítači již řadu let. Pokud Vás práce na počítači živí, pak se Vám rychlejší každodenní operace vyplatí a můžeme zde hovořit reálně o návratnosti investice (ROI), kterou ve výsledku notebook EUROCOM představuje.</p>

            <h2 style={{ paddingTop: "16px" }}>Kdo je tedy našim typickým zákazníkem?</h2>

            <ul className="list-container">
                <li>Státní sektor i soukromé podniky využívající špičkové výpočetní techniky a serverů</li>
                <li>Strojírenská produkce, subdodavatelé automobilového průmyslu a simulace výroby</li>
                <li>Tvorba obsahu pro VR, AR a další pokročilé technologie, výzkům a vývoj</li>
                <li>Grafická/filmová studia zaměřená na střih ve vysokém rozlišení a úpravu fotografií</li>
                <li>Konstrukce CAx/GIS/PLM ve 3D, vývoj technologií v oblasti mechaniky a proudění částic</li>
                <li>IT specialisté a programátoři poskytující servis a vývoj firemních/cloudových aplikací</li>
                <li>Inženýrské a projektové kanceláře zabezpečující realizaci a koordinaci staveb</li>
                <li>Správci databází, dodavatelé ERP a komplexních podnikových informačních systémů</li>
            </ul>
            <a href="https://www.facebook.com/EurocomTechnology" target="blank"><img className="full-img" src="images/static-content/casestudies_1.jpg" /></a>
            <a href="https://www.eurocom.com/ec/data/whitepapers/19.pdf" target="blank"><img className="full-img" src="images/static-content/casestudies_2.jpg" /></a>
            <a href="https://www.eurocom.com/ec/data/whitepapers/20.pdf" target="blank"><img className="full-img" src="images/static-content/casestudies_3.jpg" /></a>
        </>
    );
};
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, withRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
//import { AppStateContext } from './components/AppStateContext';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

class ScrollToTop extends React.Component {
    //static contextType = AppStateContext;
    componentDidUpdate(prevProps) {
        //this.context[1]((prevState) =>
        //    ({ ...prevState, footer: false })
        //);
        if (
            this.props.location.pathname !== prevProps.location.pathname
        ) {
            window.scrollTo(0, 0);
        }
    }
    //componentDidMount() {
    //    this.context[1]((prevState) =>
    //        ({ ...prevState, footer: false })
    //    );
    //}
    render() {
        return null;
    }
}

export default withRouter(ScrollToTop);

ReactDOM.render(    
    <BrowserRouter basename={baseUrl}>
        <ScrollToTop />
        <App />
    </BrowserRouter>,
  rootElement);

registerServiceWorker();




import React from 'react';
import SEO from './SEO';

export const Review = () => {
    return (
        <>
            {SEO({ title: "Recenze notebooků | EUROCOM | Goldmax", description: "Testy notebooků a srovnání. Flexibilní a unikátní řešení." })}
            <h1>Recenze a ocenění</h1>

            <p><a href="https://www.eurocom.com/ec/reviewsall()ec" target="_blank" rel="noopener noreferrer">EUROCOM reviews (zahraniční databáze)</a></p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Sky Z7</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://pctuning.tyden.cz/hardware/notebooky-pda/61479-notebook-eurocom-sky-z7-delo-s-i9-10900k-a-rtx-2080-s" target="_blank" rel="noopener noreferrer">
                    https://pctuning.tyden.cz/hardware/notebooky-pda/61479-notebook-eurocom-sky-z7-delo-s-i9-10900k-a-rtx-2080-s
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM NightSky ARX15</h4>            
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://pctuning.tyden.cz/hardware/notebooky-pda/60317-notebook-eurocom-nightsky-arx15-amd-r9-3950x-a-rtx-2070?start=9" target="_blank" rel="noopener noreferrer">
                    https://pctuning.tyden.cz/hardware/notebooky-pda/60317-notebook-eurocom-nightsky-arx15-amd-r9-3950x-a-rtx-2070
                </a>
            </p>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://www.idnes.cz/technet/pc-mac/test-eurocom-nightsky-arx15-msi-ge75-raider-core-i7-ryzen-9-supernotebook.A201209_102001_hardware_nyv" target="_blank" rel="noopener noreferrer">
                    https://www.idnes.cz/technet/pc-mac/test-eurocom-nightsky-arx15-msi-ge75-raider-core-i7-ryzen-9-supernotebook
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://www.zive.cz/clanky/16-stolnich-jader-v-prenosnem-baleni-test-notebooku-eurocom-nightsky-arx15/sc-3-a-205936/default.aspx" target="_blank" rel="noopener noreferrer">
                    https://www.zive.cz/clanky/16-stolnich-jader-v-prenosnem-baleni-test-notebooku-eurocom-nightsky-arx15
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM NightSky RX15</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://www.zive.cz/clanky/oled-displej-a-rychla-rtx-2070-ktomu-test-notebooku-eurocom-nightsky-rx15/sc-3-a-199384/default.aspx" target="_blank" rel="noopener noreferrer">
                    https://www.zive.cz/clanky/oled-displej-a-rychla-rtx-2070-ktomu-test-notebooku-eurocom-nightsky-rx15
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://zive.aktuality.sk/clanok/141758/recenzia-eurocom-nightsky-rx15-taky-notebook-sa-len-tak-nevidi/" target="_blank" rel="noopener noreferrer">
                    https://zive.aktuality.sk/clanok/141758/recenzia-eurocom-nightsky-rx15-taky-notebook-sa-len-tak-nevidi
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Tornado F7W</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://www.digitalengineering247.com/article/blown-away-eurocom-tornado-f7w-mobile-workstation/reviews" target="_blank" rel="noopener noreferrer">
                    https://www.digitalengineering247.com/article/blown-away-eurocom-tornado-f7w-mobile-workstation/reviews
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://www.notebookcheck.net/Eurocom-Tornado-F7W-i9-9900K-Quadro-P5200-Workstation-Review.373721.0.html" target="_blank" rel="noopener noreferrer">
                    https://www.notebookcheck.net/Eurocom-Tornado-F7W-i9-9900K-Quadro-P5200-Workstation-Review
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM X7C</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://pctuning.tyden.cz/hardware/notebooky-pda/50523-eurocom-sky-x7c-pracovni-stroj-s-core-i7-8700k-a-gtx-1070?start=7" target="_blank" rel="noopener noreferrer">
                    https://pctuning.tyden.cz/hardware/notebooky-pda/50523-eurocom-sky-x7c-pracovni-stroj-s-core-i7-8700k-a-gtx-1070
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="http://www.ddworld.cz/mobility/notebook/test-notebooku-s-core-i7-8700k-eurocom-sky-x7c.-unikatni-dtr-aneb-desktop-sbaleny-na-cesty.html" target="_blank" rel="noopener noreferrer">
                    http://www.ddworld.cz/mobility/notebook/test-notebooku-s-core-i7-8700k-eurocom-sky-x7c.-unikatni-dtr-aneb-desktop-sbaleny-na-cesty
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM X9C</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://www.notebookcheck.net/Eurocom-Sky-X9C-i7-8700K-GTX-1080-SLI-Clevo-P870TM1-G-Laptop-Review.274230.0.html" target="_blank" rel="noopener noreferrer">
                    https://www.notebookcheck.net/Eurocom-Sky-X9C-i7-8700K-GTX-1080-SLI-Clevo-P870TM1-G-Laptop-Review
                </a>
            </p>
           
            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM X4C</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://www.notebookcheck.net/Eurocom-Sky-X4C-i7-8700K-GTX-1080-Clevo-P751TM1-G-Laptop-Review.281388.0.html" target="_blank" rel="noopener noreferrer">
                    https://www.notebookcheck.net/Eurocom-Sky-X4C-i7-8700K-GTX-1080-Clevo-P751TM1-G-Laptop-Review
                </a>
            </p>
            
            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Q5</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://www.zive.cz/clanky/test-notebooku-eurocom-q5-tenoucky-a-pritom-na-hry-diky-grafice-max-q/sc-3-a-189696/default.aspx" target="_blank" rel="noopener noreferrer">
                    https://www.zive.cz/clanky/test-notebooku-eurocom-q5-tenoucky-a-pritom-na-hry-diky-grafice-max-q
                </a>
            </p>
           
            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Tornado F5</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://www.notebookcheck.net/Eurocom-Tornado-F5W-Xeon-E3-1280-v5-Quadro-P5000-Workstation-Review.262400.0.html" target="_blank" rel="noopener noreferrer">
                    https://www.notebookcheck.net/Eurocom-Tornado-F5W-Xeon-E3-1280-v5-Quadro-P5000-Workstation-Review
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://www.notebookcheck.net/Eurocom-Tornado-F5-Notebook-Review.189496.0.html" target="_blank" rel="noopener noreferrer">
                    https://www.notebookcheck.net/Eurocom-Tornado-F5-Notebook-Review
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Sky MX5 R2 Slim</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://zive.aktuality.sk/clanok/122246/test-eurocom-sky-mx5-r2-stolny-pocitac-uz-nepotrebujete/" target="_blank" rel="noopener noreferrer">
                    https://zive.aktuality.sk/clanok/122246/test-eurocom-sky-mx5-r2-stolny-pocitac-uz-nepotrebujete
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://digitalportal.sk/recenzia-eurocom-sky-mx5/" target="_blank" rel="noopener noreferrer">
                    https://digitalportal.sk/recenzia-eurocom-sky-mx5
                </a>
            </p>
                        
            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Sky X4E2</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://pctuning.tyden.cz/hardware/notebooky-pda/44543-notebook-eurocom-sky-x4e2-4k-delo-s-i7-6700k-a-gtx-1070" target="_blank" rel="noopener noreferrer">
                    https://pctuning.tyden.cz/hardware/notebooky-pda/44543-notebook-eurocom-sky-x4e2-4k-delo-s-i7-6700k-a-gtx-1070
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://digitalportal.sk/recenzia-eurocom-sky-x4e2/" target="_blank" rel="noopener noreferrer">
                    https://digitalportal.sk/recenzia-eurocom-sky-x4e2
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Sky X6</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="http://www.ddworld.cz/mobility/notebook/test-eurocom-sky-x6-vykonne-core-i7-6700k-sbalene-na-cesty-v-notebooku.html" target="_blank" rel="noopener noreferrer">
                    http://www.ddworld.cz/mobility/notebook/test-eurocom-sky-x6-vykonne-core-i7-6700k-sbalene-na-cesty-v-notebooku
                </a>
            </p>            

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Sky X9</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://www.zive.cz/clanky/eurocom-sky-x9-desktop-v-tele-notebooku-test/sc-3-a-181536/default.aspx" target="_blank" rel="noopener noreferrer">
                    https://www.zive.cz/clanky/eurocom-sky-x9-desktop-v-tele-notebooku-test
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://pctuning.tyden.cz/hardware/notebooky-pda/38959-eurocom-sky-x9-extremni-herni-delo-s-i7-6700k-a-gtx-980?start=1" target="_blank" rel="noopener noreferrer">
                    https://pctuning.tyden.cz/hardware/notebooky-pda/38959-eurocom-sky-x9-extremni-herni-delo-s-i7-6700k-a-gtx-980
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM P7 Pro</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://pctuning.tyden.cz/hardware/notebooky-pda/36247-eurocom-p7-pro-extremni-herni-delo-s-i7-5775c-a-gtx-980m" target="_blank" rel="noopener noreferrer">
                    https://pctuning.tyden.cz/hardware/notebooky-pda/36247-eurocom-p7-pro-extremni-herni-delo-s-i7-5775c-a-gtx-980m
                </a>
            </p>
         
            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM M5 Pro</h4>            
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="http://www.ddworld.cz/mobility/notebook/test-splneny-mobilni-sen-eurocom-m5-pro-tenky-a-uzasne-vykonny-notebook-v-akci.html" target="_blank" rel="noopener noreferrer">
                    http://www.ddworld.cz/mobility/notebook/test-splneny-mobilni-sen-eurocom-m5-pro-tenky-a-uzasne-vykonny-notebook-v-akci 
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="http://www.notebookreview.com/notebookreview/eurocom-m5-pro-review-clevo-p650se/3/" target="_blank" rel="noopener noreferrer">
                    http://www.notebookreview.com/notebookreview/eurocom-m5-pro-review-clevo-p650se
                </a>
            </p>           
            
            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM X7</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://www.zive.cz/clanky/eurocom-x7-nezastavitelna-herni-bestie-test/sc-3-a-176455/default.aspx" target="_blank" rel="noopener noreferrer">
                    https://www.zive.cz/clanky/eurocom-x7-nezastavitelna-herni-bestie-test
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://pctuning.tyden.cz/hardware/notebooky-pda/32179-goldmax-eurocom-x7-herne-monstrum-s-gtx-970m-v-sli" target="_blank" rel="noopener noreferrer">
                    https://pctuning.tyden.cz/hardware/notebooky-pda/32179-goldmax-eurocom-x7-herne-monstrum-s-gtx-970m-v-sli
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Monster 4.0</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://diit.cz/clanek/13palcovy-herni-notebook-eurocom-monster-40-s-3200x1800-ips-displejem-v-testu" target="_blank" rel="noopener noreferrer">
                    https://diit.cz/clanek/13palcovy-herni-notebook-eurocom-monster-40-s-3200x1800-ips-displejem-v-testu
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://zive.aktuality.sk/clanok/97991/test-eurocom-monster-4-0-vykonny-liliputan/" target="_blank" rel="noopener noreferrer">
                    https://zive.aktuality.sk/clanok/97991/test-eurocom-monster-4-0-vykonny-liliputan
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Panther 5.0SE</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://www.idnes.cz/technet/pc-mac/eurocom-panther-5se-notebook-server.A141008_163946_notebooky_nyv" target="_blank" rel="noopener noreferrer">
                    https://www.idnes.cz/technet/pc-mac/eurocom-panther-5se-notebook-server
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://www.novinky.cz/internet-a-pc/hardware/clanek/nejvykonnejsi-notebook-na-svete-stoji-stejne-jako-nove-auto-226659" target="_blank" rel="noopener noreferrer">
                    https://www.novinky.cz/internet-a-pc/hardware/clanek/nejvykonnejsi-notebook-na-svete-stoji-stejne-jako-nove-auto
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Monster 3.0</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://www.zive.cz/clanky/goldmax-monster-30-nenapadne-herni-monstrum-test/sc-3-a-171472/default.aspx" target="_blank" rel="noopener noreferrer">
                    https://www.zive.cz/clanky/goldmax-monster-30-nenapadne-herni-monstrum-test
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://pctuning.tyden.cz/hardware/notebooky-pda/28648-goldmax-monster-3-0-nejvykonnejsi-13-3-notebook-planety" target="_blank" rel="noopener noreferrer">
                    https://pctuning.tyden.cz/hardware/notebooky-pda/28648-goldmax-monster-3-0-nejvykonnejsi-13-3-notebook-planety
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Racer 2.0</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://pctuning.tyden.cz/hardware/notebooky-pda/24950-goldmax-racer-2-0-extremne-vykonny-notebook-na-hry" target="_blank" rel="noopener noreferrer">
                    https://pctuning.tyden.cz/hardware/notebooky-pda/24950-goldmax-racer-2-0-extremne-vykonny-notebook-na-hry
                </a>
            </p>           

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Monster 1.0</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://zive.aktuality.sk/clanok/58994/test-goldmax-monster-1-0-drobec-ktory-hra-naplno/" target="_blank" rel="noopener noreferrer">
                    https://zive.aktuality.sk/clanok/58994/test-goldmax-monster-1-0-drobec-ktory-hra-naplno
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://pctuning.tyden.cz/hardware/notebooky-pda/24592-goldmax-monster-1-0-nejvyssi-vykon-ve-12-kabatku?start=11" target="_blank" rel="noopener noreferrer">
                    https://pctuning.tyden.cz/hardware/notebooky-pda/24592-goldmax-monster-1-0-nejvyssi-vykon-ve-12-kabatku
                </a>
            </p>
            
            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Scorpius</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://pctuning.tyden.cz/hardware/notebooky-pda/25335-test-mobilnich-grafik-gtx-680m-sli-vs-hd-7970m-cf?start=13" target="_blank" rel="noopener noreferrer">
                    https://pctuning.tyden.cz/hardware/notebooky-pda/25335-test-mobilnich-grafik-gtx-680m-sli-vs-hd-7970m-cf
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://zive.aktuality.sk/clanok/62344/goldmax-scorpius-nahrada-desktopu-pre-narocnych/" target="_blank" rel="noopener noreferrer">
                    https://zive.aktuality.sk/clanok/62344/goldmax-scorpius-nahrada-desktopu-pre-narocnych
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM Electra</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://zive.aktuality.sk/clanok/67199/eurocom-electra-notebook-zamerany-na-hry/" target="_blank" rel="noopener noreferrer">
                    https://zive.aktuality.sk/clanok/67199/eurocom-electra-notebook-zamerany-na-hry
                </a>
            </p>            

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>EUROCOM X3</h4>
            <p style={{ marginBottom: '2px' }}>
                <a style={{ fontWeight: 500 }} href="https://pctuning.tyden.cz/hardware/notebooky-pda/27703-hd-8970m-vs-gtx-780m-v-notebooku-goldmax-x3" target="_blank" rel="noopener noreferrer">
                    https://pctuning.tyden.cz/hardware/notebooky-pda/27703-hd-8970m-vs-gtx-780m-v-notebooku-goldmax-x3
                </a>
            </p>
            <p>
                <a style={{ fontWeight: 500 }} href="https://zive.aktuality.sk/clanok/62344/goldmax-scorpius-nahrada-desktopu-pre-narocnych/" target="_blank" rel="noopener noreferrer">
                    https://zive.aktuality.sk/clanok/62344/goldmax-scorpius-nahrada-desktopu-pre-narocnych
                </a>
            </p>

        </>
    );
};
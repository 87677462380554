import React from 'react';
import './Loader.css';

const Loader = props => {
    const {
        loading,
        ...attributes
    } = props;

    return (
        <>
            {
                loading &&
                    <div className="loader">
                        <div className="loader-content">
                            <div className="loader-div">
                                <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Loader;
import React, { Component, useContext } from 'react';
import Loader from './Loader';
import { DialogInfo } from './DialogInfo';
import { Route } from 'react-router-dom';
/*import { onCountChange } from './NavMenu';*/
import { DialogConfirm } from './DialogConfirm';
import { ShoppingSteps } from './ShoppingSteps';
import { Icon } from 'semantic-ui-react';
import { isEmpty, validateEmail, validatePhone } from '../helpers/validator';
import { classNameToString } from '../helpers/classNameToString';
import { AppStateContext } from './AppStateContext';
//import { AppStateContext } from './AppStateContext'
import { GetCurrency } from './Configurator';
import { SlideDown } from 'react-slidedown';
import SEO from './SEO';
import 'react-slidedown/lib/slidedown.css';


//import { Steps } from 'rsuite';
//import { Icon, Step } from 'semantic-ui-react'
//import 'rsuite/dist/styles/rsuite-default.css';
import './ShoppingCart.css';
//import 'semantic-ui-css/semantic.min.css'

export class InquiryOrder_VM
{
    voucher;
    emailSubject;
    emailMessage;
    paymentMethod;
    shipmentMethod;
    customer;
    postCustomer;
    configuration_VMs;
    isEUR;
    exchangeRate;
}

export class Customer
{
    companyName = "";
    firstName = "";
    lastName = "";
    city = "";
    street = "";
    zipCode = 0;  
    country = "";
    companyID = 0;
    taxID = 0;
    phone = "";
    email = "";
}

export class Voucher
{
    code = "";
    discountPercent = 0;
    discountPrice = 0;
    expirationDate = new Date();
}

export class ShoppingCart extends Component {
    static displayName = ShoppingCart.name;
    static contextType = AppStateContext;
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            customer: new Customer(),
            postCustomer: new Customer(),
            loading: false,
            isOpen: false,
            dialogMessage: "",
            voucher: new Voucher(),
            badVoucher: false,
            totalPrice: 0,
            lastCode: "",
            isRemove: false,
            removedId: 0,
            removedName: "",
            step: 1,
            validFirstName: true,
            validLastName: true,            
            validCity: true,
            validStreet: true,
            validZipCode: true,
            validCountry: true,
            validCompanyID: true,            
            validEmail: true,
            validPhone: true,            
            isFirm: false,
            isPostCustomer: false,
            validSecFirstName: true,
            validSecLastName: true,
            validSecCity: true,
            validSecStreet: true,
            validSecZipCode: true,
            validSecCountry: true,
            validSecPhone: true, 
            message: "",
            shipmentMethod: 1,
            paymentMethod: 1,
            confirmConditions: false,            
            validConfirmConditions: true,
            confirmEmailOffer: false            
        };
        this.handleConfigButton = this.handleConfigButton.bind(this);
        this.notebooksCount = this.notebooksCount.bind(this);
        this.countMinus = this.countMinus.bind(this);
        this.countPlus = this.countPlus.bind(this);
        this.handleCode = this.handleCode.bind(this);
        this.handleCheckVoucher = this.handleCheckVoucher.bind(this);
        this.handleRemove = this.handleRemove.bind(this);  
    }
        
    handleConfigButton(e) {
        const { id } = e.target;
        this.props.history.replace('/configurator', { id: id, type: this.type });
    }

    componentDidMount() {
        this.getLocalStorageData();       
    }

    notebooksCount(e) {
        const { id } = e.target;
        const { value } = e.target;
        const { models } = this.state;
        //const index = models.findIndex(x => x.notebook.id == id);        
        if (value < 1) {
            models[id].notebooksCount = 1;
        }
        else {
            models[id].notebooksCount = value;
        }   
        var totalPrice = models.reduce((acc, val) => acc + val.price * val.notebooksCount, 0)
        if (this.state.voucher.discountPercent != 0) {
            totalPrice = totalPrice - (totalPrice / 100 * this.state.voucher.discountPercent);
        }
        if (this.state.voucher.discountPrice != 0) {
            totalPrice = totalPrice - this.state.voucher.discountPrice;
        }
        localStorage.setItem(`gmscitem-${id}`, JSON.stringify(models[id]));
        this.setState({ models, totalPrice });
        
    }
    countMinus(e) {
        const { id } = e.target;
        const { models } = this.state;
        //const index = models.findIndex(x => x.notebook.id == id);
        if (models[id].notebooksCount > 1) {
            models[id].notebooksCount--;
            var totalPrice = models.reduce((acc, val) => acc + val.price * val.notebooksCount, 0)
            if (this.state.voucher.discountPercent != 0) {
                totalPrice = totalPrice - (totalPrice / 100 * this.state.voucher.discountPercent);
            }
            if (this.state.voucher.discountPrice != 0) {
                totalPrice = totalPrice - this.state.voucher.discountPrice;
            }
            localStorage.setItem(`gmscitem-${id}`, JSON.stringify(models[id]));
            this.setState({ models, totalPrice });
        }        
    }
    countPlus(e) {
        const { id } = e.target;
        const { models } = this.state;
        //const index = models.findIndex(x => x.notebook.id == id);
        models[id].notebooksCount++;
        var totalPrice = models.reduce((acc, val) => acc + val.price * val.notebooksCount, 0)
        if (this.state.voucher.discountPercent != 0) {
            totalPrice = totalPrice - (totalPrice / 100 * this.state.voucher.discountPercent);
        }
        if (this.state.voucher.discountPrice != 0) {
            totalPrice = totalPrice - this.state.voucher.discountPrice;
        }
        localStorage.setItem(`gmscitem-${id}`, JSON.stringify(models[id]));
        this.setState({ models, totalPrice });
    }

    handleRemove(e) {
        const { id } = e.target;        
        const model = this.state.models[id];
        this.setState({ isRemove: true, removedId: id, removedName: model.notebook.name });
    }
    toggleOK = () => {        
        var { models } = this.state;        
        models.splice(this.state.removedId, 1); //models = models.filter(x => x.notebook.id != this.state.removedId);

        /* PREUSPORADAT PRVKY V POLI - KVULI UPRAVE INDEXACE NA KTERE JE ZALOZEN VZTAH MEZI POLEM OBJEKTU (this.state.models) A LOKALNIM ULOZISTEM (id local store == index v poli) */
        let keys = Object.keys(localStorage);
        keys = keys.filter(x => x.includes("gmscitem")).sort();
        /* ODSTRANENI VSECH */
        for (var i = 0; i < keys.length; i++) {
            localStorage.removeItem(keys[i]);            
        }
        /* VRACENI NESMAZANYCH A JEJICH PREINDEXOVANI (zmena id) */
        for (var i = 0; i < models.length; i++) {
            localStorage.setItem(`gmscitem-${i}`, JSON.stringify(models[i]));
        }

        //keys = Object.keys(localStorage);
        ///*keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();    */

        //keys = keys.filter(x => x.includes("gmscitem")).sort();
        this.context[1]((prevState) =>
            ({ ...prevState, scart: models.length })
        );
        if (models.length == 0) {
            this.setState({ models, voucher: new Voucher(), badVoucher: false, totalPrice: 0, lastCode: "", isRemove: false, removedId: 0, removedName: "" });
        }
        else {
            var totalPrice = models.reduce((acc, val) => acc + val.price * val.notebooksCount, 0)
            if (this.state.voucher.discountPercent != 0) {
                totalPrice = totalPrice - (totalPrice / 100 * this.state.voucher.discountPercent);
            }
            if (this.state.voucher.discountPrice != 0) {
                totalPrice = totalPrice - this.state.voucher.discountPrice;
            }
            this.setState({ models, totalPrice, isRemove: false, removedId: 0, removedName: "" });
        } 
    }
    toggleNOK = () => {
        this.setState({ isRemove: false, removedId: 0, removedName: "" });
    }

    handleCode(e) {
        const { voucher } = this.state;
        voucher.code = e.target.value;
        voucher.discountPercent = 0;
        voucher.discountPrice = 0;
        var totalPrice = this.state.models.reduce((acc, val) => acc + val.price * val.notebooksCount, 0)
        this.setState({ voucher, badVoucher: false, totalPrice, lastCode: "" });        
    }

    handleCheckVoucher(e) {
        if (this.state.lastCode != this.state.voucher.code) {
            this.setState({ loading: true });
            this.checkVoucherAsync(this.state.voucher);
        }        
    }



/*Form handlers*/
    firstname = (e) => {
        const cust = this.state.customer;
        cust.firstName = e.target.value;
        this.setState({ customer: cust });
    }
    secFirstname = (e) => {
        const cust = this.state.postCustomer;
        cust.firstName = e.target.value;
        this.setState({ postCustomer: cust });
    }
    lastname = (e) => {
        const cust = this.state.customer;
        cust.lastName = e.target.value;
        this.setState({ customer: cust });
    }  
    secLastname = (e) => {
        const cust = this.state.postCustomer;
        cust.lastName = e.target.value;
        this.setState({ postCustomer: cust });
    }  
    street = (e) => {
        const cust = this.state.customer;
        cust.street = e.target.value;
        this.setState({ customer: cust });
    }
    secStreet = (e) => {
        const cust = this.state.postCustomer;
        cust.street = e.target.value;
        this.setState({ postCustomer: cust });
    }
    city = (e) => {
        const cust = this.state.customer;
        cust.city = e.target.value;
        this.setState({ customer: cust });
    }
    secCity = (e) => {
        const cust = this.state.postCustomer;
        cust.city = e.target.value;
        this.setState({ postCustomer: cust });
    }
    zipCode = (e) => {
        const cust = this.state.customer;
        cust.zipCode = e.target.value;
        this.setState({ customer: cust });
    }
    secZipCode = (e) => {
        const cust = this.state.postCustomer;
        cust.zipCode = e.target.value;
        this.setState({ postCustomer: cust });
    }
    country = (e) => {
        const cust = this.state.customer;
        cust.country = e.target.value;
        this.setState({ customer: cust });
    }
    secCountry = (e) => {
        const cust = this.state.postCustomer;
        cust.country = e.target.value;
        this.setState({ postCustomer: cust });
    }
    email = (e) => {
        const cust = this.state.customer;
        cust.email = e.target.value;
        this.setState({ customer: cust });
    }
    phone = (e) => {
        const cust = this.state.customer;
        cust.phone = e.target.value;
        this.setState({ customer: cust });
    }
    secPhone = (e) => {
        const cust = this.state.postCustomer;
        cust.phone = e.target.value;
        this.setState({ postCustomer: cust });
    }
    companyID = (e) => {
        const cust = this.state.customer;
        cust.companyID = e.target.value;
        this.setState({ customer: cust });
    }    
    taxID = (e) => {
        const cust = this.state.customer;
        cust.taxID = e.target.value;
        this.setState({ customer: cust });
    } 
    companyName = (e) => {
        const cust = this.state.customer;
        cust.companyName = e.target.value;
        this.setState({ customer: cust });
    } 
    secCompanyName = (e) => {
        const cust = this.state.postCustomer;
        cust.companyName = e.target.value;
        this.setState({ postCustomer: cust });
    } 
    message = (e) => {        
        this.setState({ message: e.target.value });
    }
/*End Form handlers*/

    shipmentMethod = (e) => {
        this.setState({ shipmentMethod: e.target.value });
    }
    paymentMethod = (e) => {
        this.setState({ paymentMethod: e.target.value });
    }

    handleNext = () => {             
        if (this.state.step == 2)
        {
            var validFirstName = !isEmpty(this.state.customer.firstName);
            var validLastName = !isEmpty(this.state.customer.lastName);                       
            var validCity = !isEmpty(this.state.customer.city);
            var validStreet = !isEmpty(this.state.customer.street);
            var validZipCode = !isEmpty(this.state.customer.zipCode);
            var validCountry = !isEmpty(this.state.customer.country);
            var validEmail = validateEmail(this.state.customer.email);
            var validPhone = validatePhone(this.state.customer.phone);            

            if (this.state.isFirm && this.state.isPostCustomer) {
                var validCompanyID = !isEmpty(this.state.customer.companyID);                

                var validSecFirstName = !isEmpty(this.state.postCustomer.firstName);
                var validSecLastName = !isEmpty(this.state.postCustomer.lastName);
                var validSecCity = !isEmpty(this.state.postCustomer.city);
                var validSecStreet = !isEmpty(this.state.postCustomer.street);
                var validSecZipCode = !isEmpty(this.state.postCustomer.zipCode);
                var validSecCountry = !isEmpty(this.state.postCustomer.country);
                var validSecPhone = validatePhone(this.state.postCustomer.phone);

                this.setState({
                    validFirstName, validSecFirstName,
                    validLastName, validSecLastName,
                    validCity, validSecCity,
                    validStreet, validSecStreet,
                    validZipCode, validSecZipCode,
                    validCountry, validSecCountry,
                    validEmail,
                    validPhone, validSecPhone,
                    validCompanyID                    
                });
                if (
                    validFirstName && validSecFirstName &&
                    validLastName && validSecLastName &&
                    validCity && validSecCity &&
                    validStreet && validSecStreet &&
                    validZipCode && validSecZipCode &&
                    validCountry && validSecCountry &&
                    validEmail &&
                    validPhone && validSecPhone &&
                    validCompanyID                     
                    ) {
                    this.setState({ step: this.state.step + 1 });
                }
            }
            else if (this.state.isFirm) {
                var validCompanyID = !isEmpty(this.state.customer.companyID);                
                
                this.setState({ validFirstName, validLastName, validCity, validStreet, validZipCode, validCountry, validEmail, validPhone, validCompanyID });
                if (validFirstName && validLastName && validCity && validStreet && validZipCode && validCountry && validEmail && validPhone && validCompanyID) {
                    this.setState({ step: this.state.step + 1 });
                }  
            }
            else if (this.state.isPostCustomer) {
                var validSecFirstName = !isEmpty(this.state.postCustomer.firstName);
                var validSecLastName = !isEmpty(this.state.postCustomer.lastName);
                var validSecCity = !isEmpty(this.state.postCustomer.city);
                var validSecStreet = !isEmpty(this.state.postCustomer.street);
                var validSecZipCode = !isEmpty(this.state.postCustomer.zipCode);
                var validSecCountry = !isEmpty(this.state.postCustomer.country);                
                var validSecPhone = validatePhone(this.state.postCustomer.phone);

                this.setState({
                    validFirstName, validSecFirstName,
                    validLastName, validSecLastName,
                    validCity, validSecCity,
                    validStreet, validSecStreet,
                    validZipCode, validSecZipCode,
                    validCountry, validSecCountry,
                    validEmail,
                    validPhone, validSecPhone                    
                });
                if (
                    validFirstName && validSecFirstName &&
                    validLastName && validSecLastName &&
                    validCity && validSecCity &&
                    validStreet && validSecStreet &&
                    validZipCode && validSecZipCode &&
                    validCountry && validSecCountry &&
                    validEmail &&
                    validPhone && validSecPhone                    
                    ) {
                    this.setState({ step: this.state.step + 1 });
                }

            }

            else {
                this.setState({ validFirstName, validLastName, validCity, validStreet, validZipCode, validCountry, validEmail, validPhone });
                if (validFirstName && validLastName && validCity && validStreet && validZipCode && validCountry && validEmail && validPhone) {
                    this.setState({ step: this.state.step + 1 });
                }    
            }                  
        }         
        else
        {
            this.setState({ step: this.state.step + 1 });
        }
        window.scrollTo(0, 0);
    }
    handleBack = () => {
        this.setState({ step: this.state.step - 1 });
        window.scrollTo(0, 0);
    }
    handleFinish = () => {
        var validConfirmConditions = this.state.confirmConditions;
        this.setState({ validConfirmConditions })
        if (validConfirmConditions) {
            this.setState({ loading: true })
            this.sendAsync();
        }        
    }

    toggleModal = () => {
        if (this.state.message == "") {
            this.setState({ isOpen: !this.state.isOpen });
        }
        else {
            this.setState({ isOpen: !this.state.isOpen });            
            /*localStorage.clear();*/
            let keys = Object.keys(localStorage);
            keys = keys.filter(x => x.includes("gmscitem")).sort();
            /* ODSTRANENI VSECH */
            for (var i = 0; i < keys.length; i++) {
                localStorage.removeItem(keys[i]);
            }
            this.context[1]((prevState) =>
                ({ ...prevState, scart: 0 })
            );
            this.props.history.goBack();  
            window.scrollTo(0, 0);
        }
    }

    renderSwitch = () => {
        let shipmentPrice = this.state.shipmentMethod == 3 ? 1033 : this.state.shipmentMethod == 2 ? 412 : 0
        switch (this.state.step) {
            case 1:
                return <>
                    {/*<div className="row-container" style={{ paddingTop: '24px' }}>
                        <div className="col-sm-2 padding-right-only">
                            <span>&nbsp;</span>
                        </div>
                        <div className="col-sm-4"><p><b>Název</b></p></div>
                        <div className="col-sm-3"><b>Množství</b></div>

                        <div className="col-sm-2"><b>Cena</b></div>
                        <div className="col-sm-1"><span>&nbsp;</span></div>
                    </div>
                    */}
                {
                    this.state.models.map((model, i) =>
                        <div className="sc-item-container">
                            <div className="sc-item-img">
                                <img src={model ? (model.notebook ? (model.notebook.notebookImages ? model.notebook.notebookImages[0].link : "") : "") : ""} />
                            </div>
                            <div className="sc-item-mobile-container">
                                <div className="sc-item-name">{model.notebook.name}</div>
                                <div className="sc-item-count">
                                    <React.Fragment >
                                        <span id={i} onClick={this.countMinus} className="minus">-</span>
                                        <input id={i} className="counter" type="number" value={model.notebooksCount} onChange={this.notebooksCount} />
                                        <span id={i} onClick={this.countPlus} className="plus">+</span>
                                    </React.Fragment>
                                </div>
                                <div className="sc-item-price"><GetCurrency value={model.price} /><span> s DPH</span></div>
                            </div>
                            <div className="sc-item-remove" id={i} key={i}>
                                <Icon link id={i} key={i} onClick={this.handleRemove} name='trash' />
                            </div>
                        </div>
                    )
                }
                    <div className="sc-result">
                        <div className="sc-result-container">
                            <div className="sc-result-container-text">Celková cena bez DPH</div>
                            <div className="sc-result-container-value">
                                {/*{(Math.floor((this.state.totalPrice / 1.21) * 100) / 100).toLocaleString()} Kč*/}   
                                <GetCurrency value={this.state.totalPrice} round={true}/>
                            </div>
                        </div>
                        <div className="row-container">
                            <div className="sc-result-container-text">21% DPH</div>
                            <div className="sc-result-container-value">
                                {/*{(this.state.totalPrice - (Math.floor((this.state.totalPrice / 1.21) * 100) / 100)).toLocaleString()} Kč*/}    
                                <GetCurrency value={this.state.totalPrice} defference={true} round={true}/>
                            </div>
                        </div>
                        <div className="row-container">
                            <div className="sc-result-container-text">včetně DPH</div>
                            <div className="sc-result-container-value" style={{ fontSize: '18px', fontWeight: '600' }}>
                                {/*{this.state.totalPrice.toLocaleString()} Kč*/}
                                <GetCurrency value={this.state.totalPrice} />
                            </div>
                        </div>
                    </div>

                    <div className="sc-voucher-container">
                        <div className="sc-voucher-input">
                            <input type="text" placeholder="Slevový kupón" value={this.state.voucher.code == "" ? "" : this.state.voucher.code} onChange={this.handleCode} className="form-control input-text" />
                        </div>
                        <div className="sc-voucher-button">
                            <button onClick={this.handleCheckVoucher} className="form-button rounded">Použít</button>
                        </div>
                        <div className="sc-voucher-result">
                            {this.state.badVoucher
                                ? <span className="nok-code">Slevový kupón je neplatný!</span>
                                : ((this.state.voucher.discountPercent != 0 || this.state.voucher.discountPrice != 0)
                                    ? (this.state.voucher.discountPercent != 0
                                        ? <span className="ok-code">Z celkové ceny bylo odečteno {this.state.voucher.discountPercent}% <GetCurrency value={(this.state.models.reduce((acc, val) => acc + val.price * val.notebooksCount, 0) - this.state.totalPrice)} />.</span>
                                        /*<span className="ok-code">Z celkové ceny bylo odečteno {this.state.voucher.discountPercent}% ({(this.state.models.reduce((acc, val) => acc + val.price * val.notebooksCount, 0) - this.state.totalPrice).toLocaleString()} Kč).</span>*/
                                        : <span className="ok-code">Z celkové ceny bylo odečteno <GetCurrency value={this.state.voucher.discountPrice} />.</span>)
                                    : "")
                            }
                        </div>
                    </div>
                    </>
            case 2:
                return <>                    
                    <div className="row-container" style={{ paddingTop: '16px' }}>
                        <h5 style={{ paddingBottom: '8px'}}>Kontaktní údaje</h5>
                        <div class="form-row">
                            <div class="col-md-6 mb-3">
                                <label for="firstname">Jméno</label>
                                <input type="text" class={this.state.validFirstName ? "form-control" : "form-control is-invalid"} id="firstname" placeholder="Vaše jméno" required
                                    onChange={this.firstname} value={this.state.customer.firstName == "" ? "" : this.state.customer.firstName} />
                                <div class="invalid-feedback">
                                    Zadejte prosím Vaše křetní jméno.
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="lastname">Příjmení</label>
                                <input type="text" class={this.state.validLastName ? "form-control" : "form-control is-invalid"} id="lastname" placeholder="Vaše příjmení" required
                                    onChange={this.lastname} value={this.state.customer.lastName == "" ? "" : this.state.customer.lastName} />
                                <div class="invalid-feedback">
                                    Zadejte prosím Vaše příjmení.
                                </div>
                            </div>                            
                                               
                            <div class="col-md-6 mb-3">
                                <label for="email">E-mail</label>
                                <input type="email" class={this.state.validEmail ? "form-control" : "form-control is-invalid"} id="email" placeholder="Váš e-mail" required
                                    onChange={this.email} value={this.state.customer.email == "" ? "" : this.state.customer.email} />
                                <div class="invalid-feedback">
                                    Zadejte prosím platnou emailovou adresu.
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="phone">Telefonní číslo</label>
                                <input type="text" class={this.state.validPhone ? "form-control" : "form-control is-invalid"} id="phone" placeholder="Včetně předčíslí (+420)" required
                                    onChange={this.phone} value={this.state.customer.phone == "" ? "" : this.state.customer.phone} />
                                <div class="invalid-feedback">
                                    Zadejte prosím platné telefonní číslo (+420123456789).
                                </div>
                            </div>
                        </div>  
                        {/*Company*/}
                        <div className="table-row" style={{ paddingTop: '24px', paddingBottom: '8px' }}>
                            <input type="checkbox" name="isFirm" id="isFirm" value="isFirm" onChange={() => { this.setState({ isFirm: !this.state.isFirm }) }} checked={this.state.isFirm} />
                            <label className="config-radio-label" for="isFirm">
                                <div className="left-text-md">Nakupuji na firmu</div>  
                                {
                                    this.state.isFirm
                                        ? <Icon className="checkbox-right-icon" fitted name='check square outline' size='large' />
                                        : <div onClick={() => { this.setState({ isFirm: !this.state.isFirm }) }}><Icon className="checkbox-right-icon" fitted disabled name='square outline' size='large' /></div>
                                }                                
                            </label>
                        </div>
                       
                        <SlideDown style={{ overflow: 'hidden'}}>
                            {this.state.isFirm
                                ? <div class="form-row">
                                    <div class="col-md-4 mb-3">
                                        <label for="companyID">IČO</label>
                                        <input type="text" class={this.state.validCompanyID ? "form-control" : "form-control is-invalid"} id="companyID" required
                                            onChange={this.companyID} value={this.state.customer.companyID == "" ? "" : this.state.customer.companyID} />
                                        <div class="invalid-feedback">
                                            Zadejte prosím IČO.
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="taxID">DIČ</label>
                                        <input type="text" class="form-control" id="taxID" 
                                            onChange={this.taxID} value={this.state.customer.taxID == "" ? "" : this.state.customer.taxID} />
                                        <div class="invalid-feedback">
                                            Zadejte prosím DIČ.
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="companyName">Obchodní jméno</label>
                                        <input type="text" class="form-control" id="companyName" placeholder="Obchodní jméno (volitelné)"
                                            onChange={this.companyName} value={this.state.customer.companyName == "" ? "" : this.state.customer.companyName} />                                       
                                    </div>                                   
                                </div>  
                                : null}
                        </SlideDown>
                        {/*END Company*/}

                        <h5 style={{ paddingTop: '24px', paddingBottom: '8px' }}>Fakturační údaje</h5>
                        <div class="form-row">
                            <div class="col-md-6 mb-3">
                                <label for="street">Ulice a číslo popisné</label>
                                <input type="text" class={this.state.validStreet ? "form-control" : "form-control is-invalid"} id="street" placeholder="Ulice a číslo popisné/orientační" required
                                    onChange={this.street} value={this.state.customer.street == "" ? "" : this.state.customer.street} />
                                <div class="invalid-feedback">
                                    Zadejte prosím platnou ulici a číslo pospisné.
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="zipCode">PSČ</label>
                                <input type="number" class={this.state.validZipCode ? "form-control" : "form-control is-invalid"} id="zipCode" placeholder="PSČ" required
                                    onChange={this.zipCode} value={this.state.customer.zipCode == "" ? "" : this.state.customer.zipCode} />
                                <div class="invalid-feedback">
                                    Zadejte prosím platné poštovní směrovací číslo.
                                </div>
                            </div>                                            
                        </div>
                        <div class="form-row">
                            <div class="col-md-6 mb-3">
                                <label for="city">Obec</label>
                                <input type="text" class={this.state.validCity ? "form-control" : "form-control is-invalid"} id="city" placeholder="Město nebo obec" required
                                    onChange={this.city} value={this.state.customer.city == "" ? "" : this.state.customer.city} />
                                <div class="invalid-feedback">
                                    Zadejte prosím platnou obec nebo město.
                                </div>
                            </div>           
                            <div class="col-md-6 mb-3">
                                <label for="country">Stát</label>
                                <input type="text" class={this.state.validCountry ? "form-control" : "form-control is-invalid"} id="country" placeholder="Stát" required
                                    onChange={this.country} value={this.state.customer.country == "" ? "" : this.state.customer.country} />
                                <div class="invalid-feedback">
                                    Zadejte prosím platný stát.
                                </div>
                            </div>                 
                        </div>   

                        {/*PostCustomer*/}
                        <div className="table-row" style={{ paddingTop: '24px', paddingBottom: '8px' }}>
                            <input type="checkbox" name="isPostCustomer" id="isPostCustomer" value="isPostCustomer"
                                onChange={() => { this.setState({ isPostCustomer: !this.state.isPostCustomer }) }}
                                checked={this.state.isPostCustomer} />
                            <label className="config-radio-label" for="isPostCustomer">
                                <div className="left-text-md">Doručit na jinou adresu</div>
                                {
                                    this.state.isPostCustomer
                                        ? <Icon className="checkbox-right-icon" fitted name='check square outline' size='large' />
                                        : <div onClick={() => { this.setState({ isPostCustomer: !this.state.isPostCustomer }) }}><Icon className="checkbox-right-icon" fitted disabled name='square outline' size='large' /></div>
                                }
                            </label>
                        </div>

                        <SlideDown style={{ overflow: 'hidden' }}>
                            {this.state.isPostCustomer
                                ? <>
                                    {this.state.isFirm
                                        ? <div class="form-row">
                                            <div class="col-md-6 mb-3">
                                                <label for="secFirstname">Jméno</label>
                                                <input type="text" class={this.state.validSecFirstName ? "form-control" : "form-control is-invalid"} id="secFirstname" placeholder="Vaše jméno" required
                                                    onChange={this.secFirstname} value={this.state.postCustomer.firstName == "" ? "" : this.state.postCustomer.firstName} />
                                                <div class="invalid-feedback">
                                                    Zadejte prosím Vaše křetní jméno.
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label for="secLastname">Příjmení</label>
                                                <input type="text" class={this.state.validSecLastName ? "form-control" : "form-control is-invalid"} id="secLastname" placeholder="Vaše příjmení" required
                                                    onChange={this.secLastname} value={this.state.postCustomer.lastName == "" ? "" : this.state.postCustomer.lastName} />
                                                <div class="invalid-feedback">
                                                    Zadejte prosím Vaše příjmení.
                                                </div>
                                            </div>

                                            <div class="col-md-6 mb-3">
                                                <label for="secCompanyName">Obchodní jméno</label>
                                                <input type="text" class="form-control" id="secCompanyName" placeholder="Obchodní jméno (volitelné)"
                                                    onChange={this.secCompanyName} value={this.state.postCustomer.companyName == "" ? "" : this.state.postCustomer.companyName} />
                                            </div>
                                            <div class="col-md-6 mb-3">
                                                <label for="secPhone">Telefonní číslo</label>
                                                <input type="text" class={this.state.validSecPhone ? "form-control" : "form-control is-invalid"} id="secPhone" placeholder="Včetně předčíslí (+420)" required
                                                    onChange={this.secPhone} value={this.state.postCustomer.phone == "" ? "" : this.state.postCustomer.phone} />
                                                <div class="invalid-feedback">
                                                    Zadejte prosím platné telefonní číslo (+420123456789).
                                                </div>
                                            </div>
                                        </div>
                                        : <div class="form-row">
                                            <div class="col-md-4 mb-3">
                                                <label for="secFirstname">Jméno</label>
                                                <input type="text" class={this.state.validSecFirstName ? "form-control" : "form-control is-invalid"} id="secFirstname" placeholder="Vaše jméno" required
                                                    onChange={this.secFirstname} value={this.state.postCustomer.firstName == "" ? "" : this.state.postCustomer.firstName} />
                                                <div class="invalid-feedback">
                                                    Zadejte prosím Vaše křetní jméno.
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label for="secLastname">Příjmení</label>
                                                <input type="text" class={this.state.validSecLastName ? "form-control" : "form-control is-invalid"} id="secLastname" placeholder="Vaše příjmení" required
                                                    onChange={this.secLastname} value={this.state.postCustomer.lastName == "" ? "" : this.state.postCustomer.lastName} />
                                                <div class="invalid-feedback">
                                                    Zadejte prosím Vaše příjmení.
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-3">
                                                <label for="secPhone">Telefonní číslo</label>
                                                <input type="text" class={this.state.validSecPhone ? "form-control" : "form-control is-invalid"} id="secPhone" placeholder="Včetně předčíslí (+420)" required
                                                    onChange={this.secPhone} value={this.state.postCustomer.phone == "" ? "" : this.state.postCustomer.phone} />
                                                <div class="invalid-feedback">
                                                    Zadejte prosím platné telefonní číslo (+420123456789).
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    

                                    <div class="form-row">
                                        <div class="col-md-6 mb-3">
                                            <label for="secStreet">Ulice a číslo popisné</label>
                                            <input type="text" class={this.state.validSecStreet ? "form-control" : "form-control is-invalid"} id="secStreet" placeholder="Ulice a číslo popisné/orientační" required
                                                onChange={this.secStreet} value={this.state.postCustomer.street == "" ? "" : this.state.postCustomer.street} />
                                            <div class="invalid-feedback">
                                                Zadejte prosím platnou ulici a číslo pospisné.
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="secZipCode">PSC</label>
                                            <input type="number" class={this.state.validSecZipCode ? "form-control" : "form-control is-invalid"} id="secZipCode" placeholder="PSČ" required
                                                onChange={this.secZipCode} value={this.state.postCustomer.zipCode == "" ? "" : this.state.postCustomer.zipCode} />
                                            <div class="invalid-feedback">
                                                Zadejte prosím platné poštovní směrovací číslo.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-6 mb-3">
                                            <label for="secCity">Obec</label>
                                            <input type="text" class={this.state.validSecCity ? "form-control" : "form-control is-invalid"} id="secCity" placeholder="Město nebo obec" required
                                                onChange={this.secCity} value={this.state.postCustomer.city == "" ? "" : this.state.postCustomer.city} />
                                            <div class="invalid-feedback">
                                                Zadejte prosím platnou obec nebo město.
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="secCountry">Stát</label>
                                            <input type="text" class={this.state.validSecCountry ? "form-control" : "form-control is-invalid"} id="secCountry" placeholder="Stát" required
                                                onChange={this.secCountry} value={this.state.postCustomer.country == "" ? "" : this.state.postCustomer.country} />
                                            <div class="invalid-feedback">
                                                Zadejte prosím platný stát.
                                            </div>
                                        </div>
                                    </div>                                     
                                </>
                                : null}
                        </SlideDown>
                        {/*END PostCustomer*/}

                        <h5 style={{ paddingTop: '24px', paddingBottom: '8px' }}>Doplňující údaje</h5>      
                        <div class="form-row">
                            <div class="col-md-12 mb-3">
                                <label for="message">Poznámka k objednávce</label>
                                <textarea class="form-control" id="message" placeholder="Vaše zpráva" rows="4"
                                    onChange={this.message} value={this.state.message} />
                            </div>
                        </div> 
                    </div> 
                </>;                
            case 3:
                return <>
                    <div className="row-container" style={{ paddingTop: '16px' }}>
                        <div className="table-row">
                            <div className="radio-group-header">
                                <h5>Způsob dodání</h5>
                            </div>
                            <div className="radio-btn-container">
                                <input type="radio" name="shipmentMethod" id="1" value="1" onChange={this.shipmentMethod} checked={this.state.shipmentMethod == 1} />
                                <label className="config-radio-label" for="1">
                                    <div className="left-text">PPL (Česká Republika)</div>
                                    <div className="right-text">ZDARMA</div>
                                </label>
                                <input type="radio" name="shipmentMethod" id="2" value="2" onChange={this.shipmentMethod} checked={this.state.shipmentMethod == 2} />
                                <label className="config-radio-label" for="2">
                                    <div className="left-text">DHL (Slovensko)</div>
                                    <div className="right-text"><GetCurrency value={412} /><div className="dph-container"> s DPH</div></div>
                                </label>
                                <input type="radio" name="shipmentMethod" id="3" value="3" onChange={this.shipmentMethod} checked={this.state.shipmentMethod == 3} />
                                <label className="config-radio-label" for="3">
                                    <div className="left-text">DHL (EU)</div>
                                    <div className="right-text"><GetCurrency value={1033} /><div className="dph-container"> s DPH</div></div>
                                </label>
                            </div>
                        </div>
                        <div className="table-row" style={{ paddingTop: '32px' }}>
                            <div className="radio-group-header">
                                <h5>Typ platby</h5>
                            </div>
                            <div className="radio-btn-container">
                                <input type="radio" name="paymentMethod" id="p1" value="1" onChange={this.paymentMethod} checked={this.state.paymentMethod == 1} />
                                <label className="config-radio-label" for="p1">
                                    <div className="left-text">Převodem z účtu</div>
                                    <div className="right-text">ZDARMA</div>
                                    <div style={{ color: '#f9a825' }} className="left-text">Platební údaje obdržíte do emailu.</div>
                                </label>    
                                <input type="radio" name="paymentMethod" id="p2" value="2" onChange={this.paymentMethod} checked={this.state.paymentMethod == 2} />
                                <label className="config-radio-label" for="p2">
                                    <div className="left-text">Na splátky</div>
                                    <div className="right-text">ZDARMA</div>
                                    <div style={{ color: '#f9a825' }} className="left-text">Akontace 1/3, splácíte 2/3. Schvalovící proces je individuální.</div>
                                </label> 
                            </div>
                        </div>
                        <p style={{ paddingTop: '24px' }}><b>Dodací lhůta</b> notebooků stavěných na zakázku činí orientačně <b>10-14 dnů</b>. <b>Skladové</b> notebooky expedujeme zpravidla <b>do 3 dnů</b>. Dodací lhůty <b>běží okamžikem přijetí platby</b>, nikoli od přijetí Vaší objednávky.</p>
                    </div>
                </>;
            case 4:
                return <>
                    <div className="row-container" style={{ paddingTop: '16px' }}>
                        {this.state.models.map((model, i) =>
                            <>
                                {
                                    i == 0
                                    ? <div className="summary-row">
                                        <div className="summary-item-classname">{model.notebook.name} </div>
                                        <div className="summary-item-name">(Počet kusů: {model.notebooksCount})</div>
                                            <div className="summary-item-price"><GetCurrency value={model.price * model.notebooksCount} /> s DPH</div>
                                    </div>
                                    : <div className="summary-row" style={{ paddingTop: '24px' }}>
                                        <div className="summary-item-classname">{model.notebook.name} </div>
                                        <div className="summary-item-name">(Počet kusů: {model.notebooksCount})</div>
                                            <div className="summary-item-price"><GetCurrency value={model.price * model.notebooksCount} /> s DPH</div>
                                    </div>
                                }                                
                                {                                    
                                    Object.keys(model.componentRSDisctionary).map(key =>
                                        <div className="summary-row" style={{ paddingLeft: '4px' }}>
                                            <div className="summary-item-classname">• {classNameToString(key)}:</div>

                                            {
                                                
                                                model.componentRSDisctionary[key][0].componentClassName == "Others"
                                                    ?
                                                    model.componentRSDisctionary[key].map(p =>
                                                        <div className="summary-row" style={{ paddingLeft: "8px" }}>
                                                            {p.isDefault && (p.component.name == "NONE"
                                                                ? <div className="summary-item-name">NENÍ</div>
                                                                : <div className="summary-item-name"> {p.component.name}; {p.component.description}</div>)}
                                                            {p.isDefault && p.component.name != "NONE" &&
                                                                (p.discountPrice != 0
                                                                ? <div className="summary-item-price"><GetCurrency value={p.discountPrice} /> s DPH</div>
                                                                : <div className="summary-item-price"><GetCurrency value={p.price} /> s DPH</div>
                                                                )}
                                                        </div>
                                                    )
                                                    :
                                                    model.componentRSDisctionary[key].map(p =>
                                                        <React.Fragment>
                                                            {p.isDefault && (p.component.name == "NONE"
                                                                ? <div className="summary-item-name">NENÍ</div>
                                                                : <div className="summary-item-name">{p.component.name}; {p.component.description}</div>)}
                                                            {p.isDefault && p.component.name != "NONE" &&
                                                                (p.discountPrice != 0
                                                                ? <div className="summary-item-price"><GetCurrency value={p.discountPrice} /> s DPH</div>
                                                                : <div className="summary-item-price"><GetCurrency value={p.price} /> s DPH</div>
                                                                )}
                                                        </React.Fragment>
                                                    )
                                            }
                                        </div>
                                )} 
                            </>
                        )}                         
                        <div className="summary-row" style={{ paddingTop: '24px' }}>
                            <div className="summary-item-classname">Způsob dodání: </div>
                            {this.state.shipmentMethod == 1 && <div className="summary-item-name">PPL (Česká Republika)</div>}
                            {this.state.shipmentMethod == 2 && <div className="summary-item-name">DHL (Slovensko)</div>}
                            {this.state.shipmentMethod == 3 && <div className="summary-item-name">DHL (EU)</div>}
                            {this.state.shipmentMethod == 1 && <div className="summary-item-price">ZDARMA</div>}
                            {this.state.shipmentMethod == 2 && <div className="summary-item-price"><GetCurrency value={412} /> s DPH</div>}
                            {this.state.shipmentMethod == 3 && <div className="summary-item-price"><GetCurrency value={1033} /> s DPH</div>}                            
                        </div>
                        <div className="summary-row">
                            <div className="summary-item-classname">Typ platby: </div>
                            {this.state.paymentMethod == 1 && <div className="summary-item-name">Převodem z účtu</div>}
                            {this.state.paymentMethod == 2 && <div className="summary-item-name">Na splátky</div>}                            
                            {this.state.paymentMethod == 1 && <div className="summary-item-price">ZDARMA</div>}
                            {this.state.paymentMethod == 2 && <div className="summary-item-price">ZDARMA</div>}
                            
                        </div>
                    </div>                    
                    <div className="sc-result">
                        <div className="sc-result-container">
                            <div className="sc-result-container-text">Celková cena bez DPH</div>
                            <div className="sc-result-container-value">
                                {/*{(Math.floor((this.state.totalPrice / 1.21) * 100) / 100).toLocaleString()} Kč*/}
                                <GetCurrency value={this.state.totalPrice + shipmentPrice} round={true} />
                            </div>
                        </div>
                        <div className="row-container">
                            <div className="sc-result-container-text">21% DPH</div>
                            <div className="sc-result-container-value">
                                {/*{(this.state.totalPrice - (Math.floor((this.state.totalPrice / 1.21) * 100) / 100)).toLocaleString()} Kč*/}
                                <GetCurrency value={this.state.totalPrice + shipmentPrice} defference={true} round={true} />
                            </div>
                        </div>
                        <div className="row-container">
                            <div className="sc-result-container-text">včetně DPH</div>
                            <div className="sc-result-container-value" style={{ fontSize: '18px', fontWeight: '600' }}>
                                {/*{this.state.totalPrice.toLocaleString()} Kč*/}
                                <GetCurrency value={this.state.totalPrice + shipmentPrice} />
                            </div>
                        </div>
                    </div>
                    <div className="sc-voucher-result">
                        {this.state.badVoucher
                            ? null
                            : ((this.state.voucher.discountPercent != 0 || this.state.voucher.discountPrice != 0)
                                ? (this.state.voucher.discountPercent != 0
                                    ? <span className="ok-code">Z celkové ceny (bez poštovného) bylo odečteno {this.state.voucher.discountPercent}% <GetCurrency value={(this.state.models.reduce((acc, val) => acc + val.price * val.notebooksCount, 0) - this.state.totalPrice)} />.</span>
                                    : <span className="ok-code">Z celkové ceny bylo odečteno <GetCurrency value={this.state.voucher.discountPrice} />.</span>)
                                : "")
                        }
                    </div>
                    {/*Souhlasím s obchodními podmínkami*/}
                    <div className="table-row" style={{ paddingTop: '44px', paddingBottom: '8px' }}>
                        <input type="checkbox" name="confirmConditions" id="confirmConditions" value="confirmConditions"
                            onChange={() => { this.setState({ confirmConditions: !this.state.confirmConditions }) }}
                            checked={this.state.confirmConditions} />
                        <label className="config-radio-label" for="confirmConditions">
                            <div className="left-text-md">Souhlasím s obchodními podmínkami.
                                <Icon
                                    onClick={() => { window.open(window.location.origin + "/obchodni-podminky", '_blank'); }}
                                    style={{ paddingLeft: '8px' }}
                                    name="question circle outline" />
                            </div>
                            {
                                this.state.confirmConditions
                                    ? <Icon className="checkbox-right-icon" fitted name='check square outline' size='large' />
                                    : <div onClick={() => { this.setState({ confirmConditions: !this.state.confirmConditions }) }}><Icon className="checkbox-right-icon" fitted disabled name='square outline' size='large' /></div>
                            }
                            {
                                this.state.validConfirmConditions
                                    ? null
                                    : <div style={{ color: '#dc3545' }} className="left-text">Potvrďte souhlas s obchodními podmínkami.</div>
                            }
                        </label>
                    </div>
                    <div className="table-row" style={{ paddingBottom: '16px' }}>
                        <input type="checkbox" name="confirmEmailOffer" id="confirmEmailOffer" value="confirmEmailOffer"
                            onChange={() => { this.setState({ confirmEmailOffer: !this.state.confirmEmailOffer }) }}
                            checked={this.state.confirmEmailOffer} />
                        <label className="config-radio-label" for="confirmEmailOffer">
                            <div className="left-text-md">Chci být informován o slevách a akcích.</div>
                            {
                                this.state.confirmEmailOffer
                                    ? <Icon className="checkbox-right-icon" fitted name='check square outline' size='large' />
                                    : <div onClick={() => { this.setState({ confirmEmailOffer: !this.state.confirmEmailOffer }) }}><Icon className="checkbox-right-icon" fitted disabled name='square outline' size='large' /></div>
                            }
                        </label>
                    </div>
                </>;
            default:
                return 'BAD STEP';
        }
    }

    render() {        
        let loader = this.state.loading && <Loader loading={this.state.loading} />
        let dialogheader = this.state.message == "" ? "Objednávka" : "Děkujeme za Vaši objednávku č. " + this.state.message
        return (            
            <React.Fragment>
                {SEO({ title: "Nákupní košík | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
                {loader} 
                <DialogInfo
                    header={dialogheader} show={this.state.isOpen}
                    onClose={this.toggleModal}>
                    {this.state.message == ""
                        ? <p>Došlo k chybě při zpracování, kontaktujte nás prostřednictvím e-mailu nebo telefonu.</p>
                        : <> 
                            {this.state.paymentMethod == 2 && <div><p>Ohledně splátkového prodeje Vás budeme kontaktovat se žádostí o poskytnutí osobních údajů pro schvalovací proces splátkového kalendáře.</p><br /></div>}
                            <p>Platební údaje najdete v příloze emailu, který obdržíte s fakturou ve formátu .PDF</p>
                            <br />
                            <div style={{ }}><p>Jestliže si přejete učinit změny v objednávce nebo žádáte dodatečné informace ke lhůtě dodání, cenám nebo komponentám, kontaktujte nás.</p></div>
                        </>                        
                    }                    
                </DialogInfo>
                <DialogConfirm
                    header="Odebrání položky" show={this.state.isRemove}
                    onOKButton={this.toggleOK}
                    onNOKButton={this.toggleNOK}>
                    Opravdu chcete notebook <b>{this.state.removedName}</b> odebrat z košíku?
                </DialogConfirm>

                {this.state.models.length == 0
                    ? <h4 style={{ textAlign: 'center' }}>Košík je prázdný.</h4>
                    : <>
                        <ShoppingSteps step={this.state.step} />
                        {this.renderSwitch()}


                        <div className="row-container">
                            <div className="col-sm-12 text-center">
                                {this.state.step > 1 ? <button style={{ margin: '24px 8px' }} onClick={this.handleBack} className="submit-button rounded">ZPĚT</button> : null}
                                {this.state.step == 4 ? null : <button style={{ margin: '24px 8px' }} onClick={this.handleNext} className="submit-button rounded">POKRAČOVAT</button>}
                                {this.state.step == 4 ? <button style={{ margin: '24px 8px' }} onClick={this.handleFinish} className="submit-button rounded">OBJEDNAT</button> : null}
                            </div>
                        </div>
                    </>
                }

                       
                                              
            </React.Fragment>
        );
    } 

    getLocalStorageData() {        
        const { models } = this.state;
        var keys = Object.keys(localStorage);
        /* STARY ZPUSOB RESIL JEN CELKOVY POCET POLOZEK V LOKALNIM ULOZISTI - (NELZE POUZIT) APPLE SAFARI SI UKLADA VLASTNI DATA DO LOKALNIHO ULOZISTE
        keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();
        */

        keys = keys.filter(x => x.includes("gmscitem")).sort();
        var i = keys.length;
        while (i--) {
            models.unshift(JSON.parse(localStorage.getItem(keys[i])));
        }
        //var totalPrice = models.reduce((acc, val) => acc + val.order.price * val.order.notebooksCount, 0)
        var totalPrice = models.reduce((acc, val) => acc + val.price * val.notebooksCount, 0)
        this.setState({ models, totalPrice });
    }

    async checkVoucherAsync(voucher) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(voucher)
        };
        const response = await fetch('order/checkvoucher', requestOptions);        
        const data = await response.json();
        if (data.code) {
            var totalPrice = this.state.totalPrice;
            if (data.discountPercent != 0) {
                totalPrice = totalPrice - (totalPrice / 100 * data.discountPercent);
            }
            else {
                totalPrice = totalPrice - data.discountPrice;
            }
            this.setState({ loading: false, voucher: data, badVoucher: false, totalPrice, lastCode: voucher.code });
        }
        else {
            this.setState({ loading: false, badVoucher: true, lastCode: voucher.code });
        }        
    }

    async sendAsync() {   
        let { currency } = this.context[0];
        const model = new InquiryOrder_VM();
        model.configuration_VMs = this.state.models;
        model.customer = this.state.customer;
        model.postCustomer = this.state.postCustomer;
        model.shipmentMethod = this.state.shipmentMethod;
        model.paymentMethod = this.state.paymentMethod;
        model.emailMessage = this.state.message;
        model.voucher = this.state.voucher;
        model.newsletter = this.state.confirmEmailOffer;
        
        // PREDELAT A POSILAT NA BACKEND KOD MENY !!!!!!!!! NYNÍ JEN PRO CZK A EURO
        if (currency.code == "EUR") { model.isEUR = true; }
        else { model.isEUR = false; }
        model.exchangeRate = currency.exchangeRate;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        };
        const response = await fetch('order/createorder', requestOptions);
        var message = "";
        if (response.ok) {    
            let responseText = await response.text();
            if (responseText.length == 0) {
                message = "";
            }
            else {
                message = responseText;
                window.history.replaceState("", "", "/dekujem")
            }
            //message = await response.json();
            //window.history.replaceState("", "", "/dekujem")
        }
        else {
            message = "";
        }
        this.setState({ loading: false, isOpen: true, message });
    }

    //async getARES() {
    //    const requestOptions = {
    //        method: 'GET'            
    //    };
    //    const response = await fetch('order/createorder', requestOptions);
    //    var message = "";
    //    if (response.ok) {
    //        message = "Vaše objednávka byla přijata,..."
    //    }
    //    else {
    //        message = "Došlo k chybě při zpracování, kontaktujte nás prostřednictvím e-mailu nebo telefonu."
    //    }
    //    this.setState({ loading: false, isOpen: true, message });
    //}
}

import React, { Component } from 'react';
import Loader from './Loader';
import { authHeader } from '../helpers/auth-header';
import { Route } from 'react-router-dom';
import { authenticationService } from '../services/authentication.service';
import { ErrorNotFound } from './ErrorNotFound';

export class ANotebookList extends Component {
    static displayName = ANotebookList.name;

    constructor(props) {
        super(props);
        this.state = { notebooks: [], loading: true, logged: false };
        this.handleCreate = this.handleCreate.bind(this);
        this.handleDetail = this.handleDetail.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDuplicate = this.handleDuplicate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleForSale = this.handleForSale.bind(this);

        if (authenticationService.currentUserValue) {
            this.state.logged = true;
        }
    }

    handleCreate() {
        this.props.history.push('/admin-notebook-create');
        window.scrollTo(0, 0);
    }
    handleDetail(e) {
        const { id } = e.target;
        this.props.history.push('/admin-notebook-detail', { id: id });
        window.scrollTo(0, 0);
    }
    handleUpdate(e) {
        const { id } = e.target;
        this.props.history.push('/admin-notebook-update', { id: id });
        window.scrollTo(0, 0);
    }
    handleDuplicate(e) {
        const { id } = e.target;
        this.props.history.push('/admin-notebook-duplicate', { id: id });
        window.scrollTo(0, 0);
    }
    handleDelete(e) {
        const { id } = e.target;
        const notebook = this.state.notebooks.find((rec) => { return rec.id == id });
        if (!window.confirm("Opravdu chceš smazat notebook: " + notebook.name)){
            return;
        }
        else {
            fetch('admin/deletenotebook/' + id, {
                method: 'DELETE',
                headers: authHeader()
            }).then(data => {
                this.setState(
                    {
                        notebooks: this.state.notebooks.filter((rec) => {
                            return (rec.id != id);
                        })
                    });
            });
        } 
    }

    getStatus(resp) {
        if (resp.status == 200) {
            alert("Notebook byl úspěšně upraven.");
            return true;
        }
        else {
            alert("Notebook se nepovedlo upravit!");
            return false;
        }
    }

    handleForSale(e) {
        const { id } = e.target;
        const index = this.state.notebooks.findIndex(rec => rec.id == id);
        const { notebooks } = this.state;
        notebooks[index].forSale = !this.state.notebooks[index].forSale;
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(notebooks[index])
        };
        fetch('admin/updateonlynotebook', requestOptions)
            .then(resp => this.getStatus(resp) && this.setState({ notebooks }));        
    }

    getType(number) {
        switch (number) {
            case 0:
                return "Top";                
            case 1:
                return "Workstation";               
            case 2:
                return "Server";               
            case 3:
                return "Enterprise";                
            case 4:
                return "Gaming";  
        }
    }

    componentDidMount() {
        if (this.state.logged) {
            this.getNotebookAsync();
        }        
    }

    async getNotebookAsync() {
        const requestOptions = { method: 'GET', headers: authHeader() };
        const response = await fetch('admin/getallnotebook', requestOptions);
        const data = await response.json();
        this.setState({ notebooks: data, loading: false });
    }
    
    static renderNotebookTable(_this) {

        return (
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th>Název</th>
                        <th>Typ</th>
                        <th>Cena</th>
                        <th>Konfigurovatelný</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {_this.state.notebooks.map(notebook =>
                        <tr key={notebook.id}>
                            <td>{notebook.name}</td>
                            <td>{_this.getType(notebook.type)}</td>
                            <td>{notebook.price}</td>
                            <td>{notebook.isUnconfigurable ? 'NE' : 'ANO'}</td>
                            <td><div id={notebook.id} key={notebook.id} onClick={_this.handleDetail} className="text-button">DETAIL</div></td>
                            <td><div id={notebook.id} key={notebook.id} onClick={_this.handleUpdate} className="text-button">UPRAVIT</div></td>
                            <td><div id={notebook.id} key={notebook.id} onClick={_this.handleDuplicate} className="text-button">DUPLIKOVAT</div></td>
                            <td><div id={notebook.id} key={notebook.id} onClick={_this.handleDelete} className="text-button">SMAZAT</div></td> 
                            <td><div id={notebook.id} key={notebook.id} onClick={_this.handleForSale} className={notebook.forSale ? "text-button-check text-button-check-selected" : "text-button-check"}>NA PRODEJ</div></td> 
                        </tr>
                    )}
                </tbody>
            </table>
            )       
    }

    render() {
        if (!this.state.logged) {
            return <Route path="*" component={ErrorNotFound} />
        }
        let contents = this.state.loading
            ? <Loader loading={this.state.loading} />
            : ANotebookList.renderNotebookTable(this);

        return (
            <React.Fragment>
                <div className="col-sm-12 text-center">
                    <button className="submit-button rounded" onClick={this.handleCreate}>VYTVOŘIT</button>
                </div>
                {contents}
            </React.Fragment>

        );
    }
}
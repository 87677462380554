import './custom.css'
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Configurator } from './components/Configurator';
import { NotebooksOverview } from './components/NotebooksOverview';
import { Admin } from './components/Admin'
import { ANotebookList } from './components/ANotebookList'
import { ANotebookCreate } from './components/ANotebookCreate';
import { ANotebookUpdate } from './components/ANotebookUpdate';
import { ANotebookDuplicate } from './components/ANotebookDuplicate';
import { ANotebookDetail } from './components/ANotebookDetail';
import { ErrorNotFound } from './components/ErrorNotFound';
import { NotebookDetail } from './components/NotebookDetail';
import { QuotationConfig } from './components/QuotationConfig';
import { ShoppingCart } from './components/ShoppingCart';
import { AdminVoucher } from './components/AdminVoucher';
import { AdminCarousel } from './components/AdminCarousel';
import { AdminCurrency } from './components/AdminCurrency';
import { AComponent } from './components/AComponent';
import { ANewsletter } from './components/ANewsletter';
import { GDPR } from './components/GDPR';
import { WhyEurocom } from './components/WhyEurocom';
import { CaseStudies } from './components/CaseStudies';
import { VR } from './components/VR';
import { Students } from './components/Students';
import { MultiGPU } from './components/MultiGPU';
import { Review } from './components/Review';
import { Tenders } from './components/Tenders';
import { Cleaning } from './components/Cleaning';
import { RMA } from './components/RMA';
import { Pickup } from './components/Pickup';
import { Lib } from './components/Lib';
import { Drivers } from './components/Drivers';
import { Eurocom } from './components/Eurocom';
import { Cooperation } from './components/Cooperation';
import { TestLend } from './components/TestLend';
import { QuotationRequest } from './components/QuotationRequest';
import { Upgrade } from './components/Upgrade';
import { Terms } from './components/Terms';
import { authenticationService } from './services/authentication.service';
//import { Prepareing } from './components/Prepareing';
import { Contact } from './components/Contact';

export default class App extends Component {
  static displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            currentUser: null            
        };
    }
   
    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x })); 
    }


    render() {
        const { currentUser } = this.state;
        return (
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home} />

                    {/*<Route path='/notebooks/:type' component={NotebooksOverview} />*/}
                    <Route path='/notebooky-skladem' component={NotebooksOverview} />
                    <Route path='/top-notebooky' component={NotebooksOverview} />
                    <Route path='/pracovni-stanice' component={NotebooksOverview} />
                    <Route path='/mobilni-servery' component={NotebooksOverview} />
                    <Route path='/korporatni-laptopy' component={NotebooksOverview} />
                    <Route path='/herni-notebooky' component={NotebooksOverview} />

                    {/*<Route path='/detail/:id' component={NotebookDetail} />*/}
                    {/*<Route path='/configurator' component={Configurator} />*/}
                    {/*<Route path='/quotation-config' component={QuotationConfig} />*/}

                    {/* Tohe doresit, momentalne vede k 404 protoze nelze provolat bez zvolene konfigurace */}
                    <Route path='/cenova-nabidka-konfigurace' component={QuotationConfig} />

                    <Route path='/proc-eurocom' component={WhyEurocom} />
                    <Route path='/pripadove-studie' component={CaseStudies} />
                    <Route path='/virtualni-realita' component={VR} />
                    <Route path='/slevy-pro-studenty' component={Students} />
                    <Route path='/multi-gpu' component={MultiGPU} />
                    <Route path='/recenze' component={Review} />
                                        
                    <Route path='/cisteni-notebooku' component={Cleaning} />
                    <Route path='/reklamace' component={RMA} />
                    <Route path='/objednavka-svozu' component={Pickup} />

                    <Route path='/upgrade' component={Upgrade} />
                    <Route path='/videa-a-navody' component={Lib} />
                    <Route path='/ovladace' component={Drivers} />
                    
                    <Route path='/cenova-nabidka-notebooku' component={QuotationRequest} />
                    <Route path='/tendery-verejne-zakazky' component={Tenders} />
                    <Route path='/firemni-testovaci-zapujcka' component={TestLend} />

                    <Route path='/kontakt' component={Contact} />
                    <Route path='/eurocom' component={Eurocom} />
                    <Route path='/spoluprace' component={Cooperation} />

                    <Route path='/obchodni-podminky' component={Terms} />
                    <Route path='/ochrana-osobnich-udaju' component={GDPR} />
                    <Route path='/nakupni-kosik' component={ShoppingCart} />

                    { /* Admin url addresses */ }
                    <Route path="/admin/*" component={ErrorNotFound} />
                    <Route path='/admin' component={Admin} />
                    <Route path='/admin-notebook' component={ANotebookList} />
                    <Route path='/admin-notebook-create' component={ANotebookCreate} />
                    <Route path='/admin-notebook-update' component={ANotebookUpdate} />
                    <Route path='/admin-notebook-duplicate' component={ANotebookDuplicate} />
                    <Route path='/admin-notebook-detail' component={ANotebookDetail} />
                    <Route path='/admin-voucher' component={AdminVoucher} />
                    <Route path='/admin-carousel' component={AdminCarousel} />
                    <Route path='/admin-currency' component={AdminCurrency} />
                    <Route path='/admin-component' component={AComponent} />
                    <Route path='/admin-newsletter' component={ANewsletter} />

                    { /* Redirect 301 for old url addresses */ }
                    <Redirect from="/whyeurocom" to={{ pathname: "/proc-eurocom", state: { status: 301 } }} />    
                    <Redirect from="/casestudies" to={{ pathname: "/pripadove-studie", state: { status: 301 } }} />
                    <Redirect from="/vr" to={{ pathname: "/virtualni-realita", state: { status: 301 } }} />
                    <Redirect from="/students" to={{ pathname: "/slevy-pro-studenty", state: { status: 301 } }} />
                    <Redirect from="/multigpu" to={{ pathname: "/multi-gpu", state: { status: 301 } }} />
                    <Redirect from="/review" to={{ pathname: "/recenze", state: { status: 301 } }} />
                    <Redirect from="/cleaning" to={{ pathname: "/cisteni-notebooku", state: { status: 301 } }} />
                    <Redirect from="/rma" to={{ pathname: "/reklamace", state: { status: 301 } }} />
                    <Redirect from="/pickup" to={{ pathname: "/objednavka-svozu", state: { status: 301 } }} />
                    <Redirect from="/lib" to={{ pathname: "/videa-a-navody", state: { status: 301 } }} />
                    <Redirect from="/drivers" to={{ pathname: "/ovladace", state: { status: 301 } }} />
                    <Redirect from="/quotationrequest" to={{ pathname: "/cenova-nabidka-notebooku", state: { status: 301 } }} />
                    <Redirect from="/tenders" to={{ pathname: "/tendery-verejne-zakazky", state: { status: 301 } }} />
                    <Redirect from="/testlend" to={{ pathname: "/firemni-testovaci-zapujcka", state: { status: 301 } }} />
                    <Redirect from="/contact" to={{ pathname: "/kontakt", state: { status: 301 } }} />
                    <Redirect from="/cooperation" to={{ pathname: "/spoluprace", state: { status: 301 } }} />
                    <Redirect from="/terms" to={{ pathname: "/obchodni-podminky", state: { status: 301 } }} />
                    <Redirect from="/gdpr" to={{ pathname: "/ochrana-osobnich-udaju", state: { status: 301 } }} />
                    <Redirect from="/shoppingcart" to={{ pathname: "/nakupni-kosik", state: { status: 301 } }} />
                    {/* Redirect for url addresses with params */}
                    <Redirect from="/notebooks" to={{ pathname: "/notebooky-skladem", state: { status: 301 } }} />
                    <Redirect from="/notebooks/*" to={{ pathname: "/notebooky-skladem", state: { status: 301 } }} />                    
                    <Redirect from="/detail" to={{ pathname: "/notebooky-skladem", state: { status: 301 } }} />
                    <Redirect from="/detail/*" to={{ pathname: "/notebooky-skladem", state: { status: 301 } }} />                    
                    <Redirect from="/configurator" to={{ pathname: "/top-notebooky", state: { status: 301 } }} />
                    <Redirect from="/configurator/*" to={{ pathname: "/top-notebooky", state: { status: 301 } }} />
                    {/* Tohe doresit, momentalne vede k 404 protoze nelze provolat bez zvolene konfigurace */}
                    <Redirect from="/quotation-config" to={{ pathname: "/cenova-nabidka-konfigurace", state: { status: 301 } }} />
                    { /* Redirect 301 for old addresses from old wordpress web */ }
                    <Redirect from="/notebook-konfigurator/*" to={{ pathname: "/top-notebooky", state: { status: 301 } }} />
                    <Redirect from="/zapujcka-notebooku" to={{ pathname: "/firemni-testovaci-zapujcka", state: { status: 301 } }} />
                    <Redirect from="/podpora" to={{ pathname: "/", state: { status: 301 } }} />

                    { /* New url for configurator and detail */}
                    <Route path='/konfigurace-notebooku/:urlName' component={Configurator} />
                    <Route path='/:urlName' component={NotebookDetail} />

                    { /*<Route path='/prepareing' component={Prepareing} />*/ /* STRANKA PRIPRAVUJEME, POKUD BY BYLA POTREBA */}
                    <Route path="*" component={ErrorNotFound} />
                </Switch>
            </Layout>
        );
    }

    //render() {
    //    const { currentUser } = this.state;
    //    return (
    //        <Layout>
    //            <Switch>
    //                <Route exact path='/' component={Home} />                    
    //                <Route path='/notebooks/:type' component={NotebooksOverview} />
    //                <Route path='/detail/:id' component={NotebookDetail} />
    //                <Route path='/configurator' component={Configurator} />
    //                <Route path='/quotation-config' component={QuotationConfig} />
    //                <Route path='/shoppingcart' component={ShoppingCart} />
    //                <Route path='/whyeurocom' component={WhyEurocom} />
    //                <Route path='/casestudies' component={CaseStudies} />
    //                <Route path='/vr' component={VR} />
    //                <Route path='/students' component={Students} />
    //                <Route path='/multigpu' component={MultiGPU} />
    //                <Route path='/review' component={Review} />
    //                <Route path='/tenders' component={Tenders} />
    //                <Route path='/cleaning' component={Cleaning} />
    //                <Route path='/rma' component={RMA} />
    //                <Route path='/pickup' component={Pickup} />
    //                <Route path='/lib' component={Lib} />
    //                <Route path='/drivers' component={Drivers} />
    //                <Route path='/cooperation' component={Cooperation} />
    //                <Route path='/eurocom' component={Eurocom} />
    //                <Route path='/testlend' component={TestLend} />
    //                <Route path='/quotationrequest' component={QuotationRequest} />
    //                <Route path='/contact' component={Contact} />
    //                <Route path='/upgrade' component={Upgrade} />
    //                <Route path='/terms' component={Terms} />
    //                <Route path='/gdpr' component={GDPR} />
    //                <Route path="/admin/*" component={ErrorNotFound} />
    //                <Route path='/admin' component={Admin} />
    //                <Route path='/admin-notebook' component={ANotebookList} />
    //                <Route path='/admin-notebook-create' component={ANotebookCreate} />
    //                <Route path='/admin-notebook-update' component={ANotebookUpdate} />
    //                <Route path='/admin-notebook-duplicate' component={ANotebookDuplicate} />
    //                <Route path='/admin-notebook-detail' component={ANotebookDetail} />
    //                <Route path='/admin-voucher' component={AdminVoucher} />
    //                <Route path='/admin-carousel' component={AdminCarousel} />
    //                <Route path='/admin-currency' component={AdminCurrency} />
    //                <Route path='/admin-component' component={AComponent} />
    //                <Route path='/admin-newsletter' component={ANewsletter} />  
    //                <Redirect from="/notebook-konfigurator/*" to={{ pathname: "/notebooks/configured", state: { status: 301 } }} />
    //                <Redirect from="/zapujcka-notebooku" to={{ pathname: "/notebooks/configured", state: { status: 301 } }} />
    //                <Redirect from="/podpora" to={{ pathname: "/notebooks/configured", state: { status: 301 } }} />
    //                { /*<Route path='/prepareing' component={Prepareing} />*/ /* STRANKA PRIPRAVUJEME, POKUD BY BYLA POTREBA */ }
    //                <Route path="*" component={ErrorNotFound} />                     
    //            </Switch>
    //        </Layout>
    //    );
    //}
}

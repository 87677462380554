import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import SEO from './SEO';

export const WhyEurocom = () => {
    return (
        <div>
            {SEO({ title: "Notebook srovnání Proč EUROCOM | Notebooky na míru | Goldmax", description: "Prémiové špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
            <h1>Nejčastější dotazy uživatelů aneb „Proč zvolit právě výkonné notebooky EUROCOM?“</h1>
            <p style={{ paddingBottom: '20px', lineHeight: '1.6' }}>
                <Accordion multiple>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>Co jsou unikátní možnosti upgrade notebooku? Mohu notebook časem vylepšit?</span>}>
                        Naše <strong>prémiové notebooky</strong> jsou vysoce modulární a díky celé škále volitelných komponent nabízejí možnost upgradovat (vylepšit) Váš notebook značky EUROCOM zakoupený na tomto e-shopu, Alze, CZC nebo dalších partnerských místech. Výměna komponent probíhá vždy v kooperaci s technikem naší společnosti, který Vám nacení jednotlivé součástky (případně i práci/samotnou výměnu) a poradí s požadavkem na upgrade PC. Možnosti upgrade notebooku se liší v konkrétních případech a u konkrétních modelů notebooků, proto nelze obecně stanovit rozsah výměny, respektive Upgrade. Umožňujeme však výměnu následujících komponent : Procesoru, grafické karty, operační paměti, displeje notebooku, klávesnice, WiFi modulu, optické mechaniky, pevného disku či SSD. Požádejte našeho technika o ceny a dostupnost upgrade právě pro Váš notebook značky EUROCOM na info@goldmax.cz <i>PS: Speciálně věnujte pozornost možnosti upgrade grafické karty MXM pro notebooky EUROCOM, ktere nabízíme unikátně pouze my a to na území ČR a Slovenska.</i>
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>Nikdy jsem neslyšel o značce EUROCOM ani českém zastoupení GOLDMAX.CZ</span>}>
                        Již více jak 11 let spolupracujeme s předními česko-slovenskými IT médii, která naše produkty a služby nezávazně testují. Kvalitní a seriozní zpětnou vazbu dokládáme řadou renomovaných médií: Nejčtenější noviny na českém internetu novinky.cz, největší český IT magazín spadající pod vydavatelství Mladé fronty živě.cz či sesterské žive.sk. Mezi naše pravidelné partnery patří také webové IT magazíny jako PCtuning.cz, SvetHardware.cz, diit.cz nebo časopisy IT CAD, IT Systems, LEVEL a Chip. Dlouhodobě spolupracujeme s herním pořadem RE-PLAY, který běží každou sobotu na kanálu PRIMA-COOL. Konkrétní odkazy na naši společnost či testované produkty najdete v sekci Recenze.
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>Má GOLDMAX.CZ nějaké kvalitní reference?</span>}>
                        Mezi naše zákazníky se řadí nejvýznamnější soukromé subjekty v zemi jako například český dodavatel energií RWE, dále státní instituce Ministerstvo spravedlnosti České republiky nebo Evropská komise, která sídlí v Bruselu. Řada menších a středních firem dnes intenzivně užívá produkty značky EUROCOM, které GOLDMAX.CZ distribuuje již od roku 2009 pro ČR a Evropu. V řadách našich zákazníků najdete mnoho profesí, které se zabývají tvorbou digitálního obsahu (3D grafika a střih videa, konstrukce a vývoj, prezentace výroby) ale také domácí uživatele (hraní her, studium a další…). Někteří naši zákazníci se problematikou notebooků EUROCOM a spokojeností s GOLDMAX.CZ zabývají na tomto fóru:
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>Máte výkonné notebooky skladem/k odběru ihned?</span>}>
                        Notebooky EUROCOM skladem má například náš partner <a href="https://www.czc.cz/notebooky/eurocom/produkty">CZC.cz</a>, doporučujeme Vám také sledovat naši sekci <strong>Dostupné IHNED/SLEVY</strong>, kde najdete nové, předváděcí, ale i zánovní notebooky z testů a recenzí, které jsou silně cenově zvýhodněné a s okamžitou dostupností. Produkty běžně skladem však nedržíme, jelikož se každý notebook staví na míru dle individuálních požadavků konkrétního zákazníka a ty se logicky vždy vzájemně liší.
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>Kdy po objednání notebook obdržím?</span>}>
                        Dodací lhůta notebooků činí cca 2 týdny. Ta začíná běžet přijetím zálohové platby na účtu společnosti, nikoli bezprostředně po realizaci objednávky na webu. Jedná se o orientační lhůtu a nelze ji jakkoli garantovat z důvodu nezávislosti mezinárodních přepravců či celních orgánů ČR, u 95 % objednávek však lhůtu dodržujeme, ba zkracujeme. Notebooky se staví na zakázku, procházejí zátěžovým testem a teprve poté putují k zákazníkovi. Tato procedura má vliv na konečnou kvalitu a dlouhodobou spolehlivost produktů EUROCOM, proto ji nelze jakkoli urychlit či vypustit. Jedná se o zakázkovou výrobu, počet kusů skladem je tak omezen – viz sekce <strong>Dostupné IHNED/SLEVY</strong> nebo sklad <a href="https://www.czc.cz/notebooky/eurocom/produkty">CZC.cz</a>.
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>Jaké poskytujete možnosti financování?</span>}>
                        Notebook u nás můžete pořídit hotově nebo na splátky. V případě hotovosti notebook vždy hradí zákazník předem, bezhotovostní platbou a to formou úhrady zálohové faktury (proforma faktury). Není možné notebooky zasílat na dobírku, jelikož se jedná o zakázkovou výrobu a GOLDMAX.cz jako distributor notebooků veškeré objednávky hradí dodavateli rovněž předem.
                        <br />
                        GOLDMAX.CZ nabízí také financování notebooků na splátky, které nepodléhá dokládání potvrzení o pravidelném příjmu. Doba splácení je volitelná, maximálně však činí 24 měsíců. Vždy je nutné uhradit předem cca 1/3 ceny notebooku. Pro konkrétní informace se prosím obracejte na email info@goldmax.cz, kde Vám splátkový program přizpůsobíme na míru.
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>Notebook na práci, virtualizaci či výpočty? Notebook jako grafická či pracovní stanice, na střih 4K videa či úpravu RAW fotografií? Autocad, Solidworks, práce na CAx systémech NX8.5 a Solid Edge ST5? CATIA a process simulate? Notebook s linuxem a ubuntu?</span>}>
                        <strong><i>Výkonné notebooky</i></strong> u nás pořizují lidé, které často práce na počítači živí. Pokud stříháte video, pracujete s fotografiemi, vytváříte jakýkoli digitální obsah CAD/CAM/CAE/PLM/GIS/BIM nebo pracujete v AUTODESKU, Solidworks, Adobe suit, Rhino 3D a podobných aplikacích, hledáte notebook na virtualizaci, rendering či tvorbu muziky, zpracováváte databáze, věnujete se pre-inženýringu či strojirenství, fluidním animacím a výpočtům, jste na správném místě. Máme klienty požadující množství vysokých nároků, které budou právě pro jejich profesi přinosem. Našim zákazníkem je typicky profesionál, občas požadujicí podporu LINUXU, který si od notebooku EUROCOM stavěného na míru slibuje zrychlení a zefektivnění své práce nebo koníčku. Investice několika desítek tisíc se Vám pak vrátí na prvních zakázkách, které zpracujete rychleji, efektivněji a pohodlně na nové mobilní pracovní stanici EUROCOM.
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>Kdo využívá prémiových notebooků EUROCOM?</span>}>
                        <ul>
                            <li>Státní sektor i soukromé podniky využívající špičkové výpočetní techniky a serverů</li>
                            <li>Strojírenská produkce, subdodavatelé automobilového průmyslu a simulace výroby</li>
                            <li>Grafická studia zaměřená na střih videa ve vysokém rozlišení a úpravu fotografií</li>
                            <li>Konstrukce CAx/GIS/PLM ve 3D, vývoj technologií v oblasti mechaniky a proudění částic</li>
                            <li>IT specialisté a programátoři poskytující servis a vývoj firemních/cloudových aplikací</li>
                            <li>Inženýrské a projektové kanceláře zabezpečující realizaci a koordinaci staveb</li>
                            <li>Správci databází, dodavatelé ERP a komplexních podnikových informačních systémů</li>                           
                        </ul>
                    </AccordionTab>
                    <AccordionTab header={<span style={{ lineHeight: '1.6' }}>Herní notebook, notebook na hry pro studenta? Notebook na cesty, notebook doplňující Váš desktop?</span>}>
                        Druhou významnou částí našich klientů jsou studenti i lidé ve středním věku, kteří vyžadují špičkový herní výkon, maximální spolehlivost notebooku při dlouhodobé zátěži, kvalitní zpracování šasi při cestování s notebookem a výkonné dvouokruhové chlazení. To vše samozřejmě nabídneme, navíc s možnostmi upgrade notebooku, které nikdo jiný v ČR reálně neposkytuje. S notebookem EUROCOM nebudete jedním z tuctu ale jedinečným majitelem výjimečného notebooku, který Vás nezklame. Uspokojí Vaše dlouhodobé potřeby.
                    </AccordionTab>
                </Accordion>
            </p>
            <p>
                Pokud jsme některou z Vašich otázek nezodpověděli, obraťte se na <a href="mailto:info@goldmax.cz">info@goldmax.cz</a> a neváhejte nás kontaktovat již dnes!
            </p>
        </div>
    );
};
import React from 'react';
import SEO from './SEO';

export const Tenders = () => {
    return (
        <>
            {SEO({ title: "Tendery/veřejné zákazky | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
            <h1>Tendery/Veřejné zakázky</h1>
            <p>Máme z uplynulých let zkušenosti s tendery a výběrovými řízeními pro státní ale i evropské zakázky v rámci EU. Jsme velmi flexibilní a vstřícní, nabízíme zcela unikátní produkt, který v naší krajině neumí nikdo jiný dodat (s plnou zárukou), proto jsme v této oblasti ve zcela exkluzivním postavení a bez přímé konkurence.</p>
            <p>Máme zájem navázat s novými partnery spolupráci v této oblasti a to například formou, že vybraný produkt zapůjčíme budoucímu soutěžiteli veřejné zakázky, který ho poskytne na testování a ozkoušení konečnému klientovi, samozřejmě zcela zdarma. Naše filozofie bezplatných zápůjček nabízí přidanou hodnotu v tom smyslu, aby na základě pozitivní zkušenosti vypsal koncový klient řádné výběrové řízení na dodavatele výpočetní techniky, které bude reflektovat výjimečnou a vysokou specifikaci, které naše produkty splňují, což mnohdy konkurence zkrátka a dobře neumí. O bezplatnou firemní zápůjčku můžete žádat zde:  - bude tady link na zápůjčku</p>
            <p>Veškeré další dotazy směřujte na <a href="mailto:info@goldmax.cz">info@goldmax.cz</a> či telefon <a href="tel:+420724583823">724 583 823</a>.</p>
        </>
    );
};
import React, { Component, useContext } from 'react';
import Loader from './Loader';
import { Route, useHistory } from 'react-router-dom';
import { ErrorNotFound } from './ErrorNotFound';
import { classNameToString } from '../helpers/classNameToString';
import SEO from './SEO';
import './Configurator.css';
import { DialogConfirm } from './DialogConfirm';
//import { onCountChange } from './NavMenu';
//import { AppStateDispatchContext, AppStateContext } from './AppStateContext'
import { AppStateContext } from './AppStateContext';
import { Icon } from 'semantic-ui-react'

export const GetCurrency = ({ value = 0, defference = false, round = false, withoutCurrencySymbol = false }) => {
    const [appState, setAppState] = useContext(AppStateContext);

    if (withoutCurrencySymbol) {
        if (round) {
            if (defference) {
                return <>{(value / appState.currency.exchangeRate - (Math.floor((value / appState.currency.exchangeRate / 1.21) * 100) / 100)).toLocaleString(undefined, { maximumFractionDigits: 2 })}</>
            } else {
                return <>{(Math.floor((value / appState.currency.exchangeRate / 1.21) * 100) / 100).toLocaleString(undefined, { maximumFractionDigits: 2 })}</>
            }
        } else {
            return <>{(value / appState.currency.exchangeRate).toLocaleString(undefined, { maximumFractionDigits: 2 })}</>
        }
    } else {
        if (round) {
            if (defference) {
                return <>{(value / appState.currency.exchangeRate - (Math.floor((value / appState.currency.exchangeRate / 1.21) * 100) / 100)).toLocaleString(undefined, { maximumFractionDigits: 2 })} {appState.currency.symbol}</>
            } else {
                return <>{(Math.floor((value / appState.currency.exchangeRate / 1.21) * 100) / 100).toLocaleString(undefined, { maximumFractionDigits: 2 })} {appState.currency.symbol}</>
            }
        } else {
            return <>{(value / appState.currency.exchangeRate).toLocaleString(undefined, { maximumFractionDigits: 2 })} {appState.currency.symbol}</>
        }
    }
    //const { currency } = useContext(AppStateContext);
    //return <>{(value / currency.exchangeRate).toLocaleString(undefined, { maximumFractionDigits: 2 })} {currency.symbol}</>
}

export class Configurator extends Component {
    static displayName = Configurator.name;
    //static contextType = AppStateDispatchContext;
    static contextType = AppStateContext;
    

    constructor(props) {
        super(props);
        this.state = { model: null, loading: true, configurated: false, summary: [], count: 0, isOpen: false, pathname: null, toggleParam: false };
        this.handleRadioButton = this.handleRadioButton.bind(this);
        this.handleCheckboxButton = this.handleCheckboxButton.bind(this);
        this.handleSummaryButton = this.handleSummaryButton.bind(this);
        this.handleBackOnConfiguration = this.handleBackOnConfiguration.bind(this);
        this.handleSubmitButton = this.handleSubmitButton.bind(this);
        this.handleQuotation = this.handleQuotation.bind(this);
        //window.onpopstate = this.onBackButtonEvent.bind(this);

        this.props.history.listen((location, action) => {
            //if (location.pathname !== '/konfigurace-notebooku') {
            //    this.context[1]((prevState) =>
            //        ({ ...prevState, footer: true })
            //    );
            //}

            if (!location.pathname.includes("/konfigurace-notebooku/")) {
                this.context[1]((prevState) =>
                    ({ ...prevState, footer: true })
                );
            }            
        })

        //this.setState({ pathname: this.props.location.state.pathname });
        //this.id = 0;
        //if (this.props.location.state) {
        //    this.id = this.props.location.state.id;
        //}
        const id = 0;
        const { urlName } = this.props.match.params || "";
        if (urlName) {
            this.urlName = urlName;
        } else {
            this.urlName = "";
        }













        //this.historyListener = this.props.history.listen((location, action) => {
        //    if (action === "PUSH") {
        //        if (location.pathname !== "/configurator") {
        //            window.onpopstate = () => { };
        //        }
        //    }
        //});
        //    //if (action === "PUSH") {
        //    //    if (location.pathname !== "/configurator") {

        //    //        const { model } = this.state;
        //    //        const { configurated } = this.state;
        //    //        this.props.history.replace('/configurator', { model: model, config: configurated, summary: this.state.summary, count: this.state.count, isOpen: true, pathname: location.pathname });
        //    //        //this.setState({ isOpen: true, pathname: location.pathname })

        //    //    }
        //    //    else {
        //    //        this.setState({
        //    //            model: this.props.location.state.model,
        //    //            configurated: this.props.location.state.config,
        //    //            loading: false,
        //    //            summary: this.props.location.state.summary,
        //    //            count: this.props.location.state.count,
        //    //            isOpen: this.props.location.state.isOpen,
        //    //            pathname: this.props.location.state.pathname
        //    //        });
        //    //    }
        //    //    return true;
        //    //    console.log("AFTER: " + this.props.history.length);
        //    //    window.onpopstate = this.onBackButtonEvent.bind(this);
        //    //}
        //    //if (action === "REPLACE") {
        //    //    this.props.history.go(-1);
        //    //}

        //});
    }    

    //componentWillReceiveProps(nextProps) {
    //    //console.log('componentWillReceiveProps');
    //    //this.setState(nextProps);
    //    //console.log(nextProps);
    //    //console.log("-------------------" + nextProps.location.state.pathname)
    //    if (nextProps.location.state.pathname) {
            
    //        this.setState({
    //            model: nextProps.location.state.model,
    //            configurated: nextProps.location.state.config,
    //            loading: false,
    //            summary: nextProps.location.state.summary,
    //            count: nextProps.location.state.count,
    //            isOpen: nextProps.location.state.isOpen,
    //            pathname: nextProps.location.state.pathname
    //        });
    //    }       
    //}
    
    //onBackButtonEvent = (e) => {
    //    e.preventDefault();
    //    const { model } = this.state;
    //    const { configurated } = this.state;
    //    this.props.history.push('/configurator', { model: model, config: configurated, summary: this.state.summary, count: this.state.count, isOpen: true });
    //}
    


    toggleOK = () => {
        //window.onpopstate = () => { };
        //if (this.state.pathname) {
        //    //this.props.history.go(-1);
        //    this.props.history.replace(this.state.pathname, { type: this.state.model.notebook.type });
        //} else {
            let strType = "";
            switch (this.state.model.notebook.type) {
                case 0:
                    strType = "top-notebooky";
                    break;
                case 1:
                    strType = "pracovni-stanice";
                    this.type = 1;
                    break;
                case 2:
                    strType = "mobilni-servery";
                    break;
                case 3:
                    strType = "korporatni-laptopy";
                    break;
                case 4:
                    strType = "herni-notebooky";
                    break;
                default:
                    break;
            }
            this.props.history.replace('/' + strType, { type: this.state.model.notebook.type });
        //} 
        this.context[1]((prevState) =>
            ({ ...prevState, footer: true })
        );
    }
    toggleNOK = () => {
        this.setState({
            isOpen: !this.state.isOpen,
            pathname: null
        });
    }
    handleCheckboxButton(e) {
        const index = e.target.value;
        const key = e.target.getAttribute('data-key')
        const { model } = this.state;
        if (model.componentRSDisctionary[key][index].isDefault) {
            model.price -= model.componentRSDisctionary[key][index].price
        }
        else {
            model.price += model.componentRSDisctionary[key][index].price
        }
        model.componentRSDisctionary[key][index].isDefault = !model.componentRSDisctionary[key][index].isDefault;
        this.setState({ model });
    }
    handleRadioButton(e) {
        const index = e.target.value;
        const key = e.target.getAttribute('data-key')
        const { model } = this.state;
        var price = this.state.model.price;
        const oldindex = model.componentRSDisctionary[key].findIndex(rec => rec.isDefault == true);
        model.componentRSDisctionary[key][oldindex].isDefault = false;
        model.componentRSDisctionary[key][index].isDefault = true;
        var oldprice = model.componentRSDisctionary[key][oldindex].price;
        if (model.componentRSDisctionary[key][oldindex].discountPrice != 0) {
            oldprice = model.componentRSDisctionary[key][oldindex].discountPrice;
        }
        var newprice = model.componentRSDisctionary[key][index].price;
        if (model.componentRSDisctionary[key][index].discountPrice != 0) {
            newprice = model.componentRSDisctionary[key][index].discountPrice;
        }
        model.price = price - oldprice + newprice;

        let { summary } = this.state;
        let compname = " ";
        if (model.componentRSDisctionary[key][index].component.name != "NONE") {
            if (key == "Ram") {
                compname = model.componentRSDisctionary[key][index].component.name + " RAM • ";
            }
            else {
                if (key == "OS") {
                    compname = model.componentRSDisctionary[key][index].component.name;
                }
                else {
                    compname = model.componentRSDisctionary[key][index].component.name + " • ";
                }

            }
        }
        if (key == "Display") {
            summary[0] = compname;
        }
        else if (key == "Cpu") {
            summary[1] = compname;
        }
        else if (key == "Gpu" || key == "GpuSlot1") {
            summary[2] = compname;
        }
        else if (key == "Ram") {
            summary[3] = compname;
        }
        else if (key == "M2" || key == "M2Slot1") {
            summary[4] = compname;
        }
        else if (key == "OS") {
            summary[5] = compname;
        }

        this.setState({ model, summary });
    }

    handleSummaryButton() {
        //window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        window.scroll({ top: 0, left: 0 });
        this.setState({ configurated: true })        
    }
    handleBackOnConfiguration() {
        //window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        window.scroll({ top: 0, left: 0 });
        this.setState({ configurated: false })        
    }
    handleSubmitButton() {
        let keys = Object.keys(localStorage);


        /* Prideleni id notebooku v lokalnim ulozisti - prefix a celkovy pocet plus 1 */

        /* STARY ZPUSOB RESIL JEN CELKOVY POCET POLOZEK V LOKALNIM ULOZISTI - (NELZE POUZIT) APPLE SAFARI SI UKLADA VLASTNI DATA DO LOKALNIHO ULOZISTE 
        keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();
        localStorage.setItem(keys.length, JSON.stringify(this.state.scmodel));
        */
        //let keys1 = keys.filter(x => x.includes("gmscitem"));
        //let keys2 = keys.filter(x => x.includes("gmscitem")).sort();


        keys = keys.filter(x => x.includes("gmscitem")).sort();

        //keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();

        var tempModel = this.state.model;
        tempModel.notebook.detail = null;

        localStorage.setItem(`gmscitem-${keys.length}`, JSON.stringify(tempModel));
        /*onCountChange(); */

        //const { currency } = this.context
        //this.context({ ...this.context, scart: localStorage.length })

        /* ZBYTECNE - NEMELO BY NASTAT TO ZE PRIBYDE VICE JAK JEDEN PRVEK 
        * keys = Object.keys(localStorage);
       keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();
       this.context[1]((prevState) =>
           ({ ...prevState, scart: keys.length, footer: true })
       );
       */      
        this.context[1]((prevState) =>
            ({ ...prevState, scart: keys.length + 1, footer: true })
        );
        window.onpopstate = () => { };
        this.props.history.replace('/nakupni-kosik');
        window.scrollTo(0, 0);
    }
    handleQuotation() {
        const { model } = this.state;
        const { configurated } = this.state;
        window.onpopstate = () => { };
        //this.context[1]((prevState) =>
        //    ({ ...prevState, footer: false })
        //);
        this.props.history.replace('/cenova-nabidka-konfigurace', { model: model, config: configurated, summary: this.state.summary, count: this.state.count });
    }

    //componentWillUnmount() {
    //    this.context[1]((prevState) =>
    //        ({ ...prevState, footer: true })
    //    );
    //}

    //componentWillUnmount() {
    //    console.log('unmount')
    //}

    componentDidMount() {
        this.context[1]((prevState) =>
            ({ ...prevState, footer: false })
        );

        //if (typeof this.id !== 'undefined') {
        //    if (this.id !== 0) {
        //        this.getConfiguratorAsync();
        //    }
        //}
        //if (typeof this.urlName !== 'undefined') {
        //    if (this.urlName !== "") {
        //        this.getConfiguratorAsync();
        //    }
        //}
        //else {
        //    if (this.props.location.state.model) {
        //        this.setState({
        //            model: this.props.location.state.model,
        //            configurated: this.props.location.state.config,
        //            loading: false,
        //            summary: this.props.location.state.summary,
        //            count: this.props.location.state.count,
        //            isOpen: this.props.location.state.isOpen,
        //            pathname: this.props.location.state.pathname
        //        });
        //    }
        //    else this.getConfiguratorAsync();
            
        //}


        if (typeof this.urlName !== 'undefined') {
            if (this.urlName !== "") {                
                if (this.props.location.state && this.props.location.state.model) {
                    this.setState({
                        model: this.props.location.state.model,
                        configurated: this.props.location.state.config,
                        loading: false,
                        summary: this.props.location.state.summary,
                        count: this.props.location.state.count,
                        isOpen: this.props.location.state.isOpen,
                        pathname: this.props.location.state.pathname,
                        toggleParam: !this.state.toggleParam
                    });
                    
                }
                else {
                    this.getConfiguratorAsync();
                }
            }
        }        
    }
    //componentWillUnmount() {        
    //    //console.log("unmount");
    //}

    static addItemToSummary(_this, item, key) {
        if (key == "Display" || key == "Cpu" || (key == "Gpu" || key == "GpuSlot1") || key == "Ram" || (key == "M2" || key == "M2Slot1") || key == "OS") {
            let compname = " ";
            if (item.component.name != "NONE") {
                if (key == "Ram") {
                    compname = item.component.name + " RAM • ";
                }
                else {
                    if (key == "OS") {
                        compname = item.component.name;
                    }
                    else {
                        compname = item.component.name + " • ";
                    }

                }

            }
            _this.state.count += 1;
            _this.state.summary.push(compname);
        }
    }

    static remderConfiguratorRadioGroups(_this, item, key) {
        return (
            item.map((p, i) =>
                <React.Fragment>
                    {p.componentClassName == "Others"
                        ? <input type="checkbox" name={p.id} key={p.id} id={p.id} data-key={key} value={i} onChange={_this.handleCheckboxButton} checked={p.isDefault ? "checked" : ""} />
                        : <input type="radio" name={p.id} key={p.id} id={p.id} data-key={key} value={i} onChange={_this.handleRadioButton} checked={p.isDefault ? "checked" : ""} />}
                    <label className="config-radio-label" for={p.id}>
                        {p.discountPrice != 0 && <span className="left-text discount-note"><b>{p.discountNote}</b></span>}
                        {p.discountPrice != 0 && <div className="right-text discount-price"><GetCurrency value={p.discountPrice} /></div>}
                        {p.component.name == "NONE"
                            ? <div className="left-text">NENÍ</div>
                            : <div className="left-text">{p.component.name}; {p.component.description}</div>}
                        {p.component.name != "NONE" &&
                            (p.discountPrice != 0
                                ? <div className="right-text"><span className="canceled-price"><GetCurrency value={p.price} /></span></div>
                            : <div className="right-text"><GetCurrency value={p.price} /></div>
                            )
                        }
                    </label>
                    {_this.state.count < 6 && (p.isDefault && Configurator.addItemToSummary(_this, p, key))}
                </React.Fragment>
            )
        );
    }
    static renderConfiguratorRadio(_this) {
        //window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        return (
            <>
                <div className="summary-icon-container">
                    <img className="summary-icon" src="icons/wrench.svg" />
                    {/*<Icon name='clipboard outline' size='huge' />*/}
                    <div>
                        <h5>Konfigurace notebooku</h5>
                        <h2>{_this.state.model && _this.state.model.notebook.name}</h2>
                    </div>
                </div>                
                {Object.keys(_this.state.model.componentRSDisctionary).map(key => (
                    <React.Fragment>
                        <div className="table-row">
                            <div className="radio-group-header">
                                <h5>{classNameToString(key)}</h5>
                            </div>
                            <div className="radio-btn-container">
                                {Configurator.remderConfiguratorRadioGroups(_this, _this.state.model.componentRSDisctionary[key], key)}
                            </div>
                        </div>
                        <hr />
                    </React.Fragment>
                ))}
                <div style={{ height: '184px', clear: 'both' }}></div>
            </>
        );
    }
    static renderSummaryItem(item) {
        return (
            item[0].componentClassName == "Others"
                ?
                item.map(p =>
                    <div className="summary-row" style={{ paddingLeft: "8px" }}>
                        {p.isDefault && (p.component.name == "NONE"
                            ? <div className="summary-item-name">NENÍ</div>
                            : <div className="summary-item-name">• {p.component.name}; {p.component.description}</div>)}
                        {p.isDefault && p.component.name != "NONE" &&
                            (p.discountPrice != 0
                                ? <div className="summary-item-price"><GetCurrency value={p.discountPrice} /></div>
                                : <div className="summary-item-price"><GetCurrency value={p.price} /></div>
                            )}
                    </div>
                )
                :
                item.map(p =>
                    <React.Fragment>
                        {p.isDefault && (p.component.name == "NONE"
                            ? <div className="summary-item-name">NENÍ</div>
                            : <div className="summary-item-name">{p.component.name}; {p.component.description}</div>)}
                        {p.isDefault && p.component.name != "NONE" &&
                            (p.discountPrice != 0
                                ? <div className="summary-item-price"><GetCurrency value={p.discountPrice} /></div>
                                : <div className="summary-item-price"><GetCurrency value={p.price} /></div>
                            )}
                    </React.Fragment>
                )
        );
    }
    static renderSummary(_this) {
        //window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        return (
            <React.Fragment>
                <div className="summary-icon-container">
                    <img className="summary-icon" src="icons/summary.svg" />
                    {/*<Icon name='clipboard outline' size='huge' />*/}
                    <div>
                        <h5>Souhrn konfigurace notebooku</h5>
                        <h2>{_this.state.model && _this.state.model.notebook.name}</h2>                        
                    </div>
                </div>
                {Object.keys(_this.state.model.componentRSDisctionary).map(key => (
                    <div className="summary-row">
                        <div className="summary-item-classname">{classNameToString(key)}:</div>
                        {Configurator.renderSummaryItem(_this.state.model.componentRSDisctionary[key])}
                    </div>
                ))}
                <hr style={{ margin: '64px 0 8px 0' }} />
                <h5>Žádost o cenovou nabídku</h5>
                <div class="col-s-10">
                    <p style={{ margin: '0', padding: '8px 0 0 0' }}>Poptávate do tenderu, firmy nebo chcete lepší cenu? Nejste si jisti vybranou konfigurací notebooku? Pak využijte individuálního posouzení a získejte tak nejlepší podmínky na trhu. Naši specialisté vždy zhodnotí vybranou specifikaci a provedou optimalizaci sestavy s cílem nejvyššího poměru cena/výkon/výbava.</p>

                </div>
                <div class="col-s-2" style={{ textAlign: 'center' }}>
                    <button style={{ width: '188px' }} className="submit-button-green rounded" onClick={_this.handleQuotation}>Nezávazná poptávka</button>
                </div>
                <div style={{ height: '104px', clear: 'both' }}></div>
            </React.Fragment>
        );
    }

    render() {
        if (typeof this.id !== 'undefined') {
            if (this.id === 0) {
                return <Route path="*" component={ErrorNotFound} />
            }
        }
        let contents = this.state.loading
            ? <Loader loading={this.state.loading} />
            : this.state.configurated ? Configurator.renderSummary(this) : Configurator.renderConfiguratorRadio(this);

        //console.log(this.props.location.state.isOpen);
        return (
            <div className="config-container">
                {this.state.model && SEO({
                    title: "Konfigurace notebooku | " + this.state.model.notebook.seoTitle,
                    description: this.state.model.notebook.seoDescription,
                    siteUrl: this.state.model.notebook.urlName,
                    sourceImgUrl: this.state.model.notebook.notebookImages[0].link
                })}
                <DialogConfirm
                    header="Ukončení konfigurátoru" show={this.state.isOpen}
                    onOKButton={this.toggleOK}
                    onNOKButton={this.toggleNOK}>
                    Opravdu si přejete opustit konfigurátor? Vaše <b>konfigurace nebude uložena!</b>
                </DialogConfirm>                             
                {contents}                
                <div className="bottom-panel-container">
                    <div className="container">
                        <div className="left-container">
                            <h4>Shrnutí</h4>
                            <h5>{this.state.model && this.state.model.notebook.name}</h5>
                        </div>
                        <div className="middle-container">
                            <div>{this.state.summary.map((key, i) => key)}</div>
                        </div>
                        <div className="right-container" >
                            <div className="right-container-temp">
                                <div className="right-container-price"><b><GetCurrency value={this.state.model ? this.state.model.price : 0} /></b></div>
                                <div className="right-container-dph">včetně 21% DPH</div>
                            </div>
                        </div>
                        <div className="button-container">
                            {!this.state.configurated && <a className="button" onClick={this.handleSummaryButton}>SOUHRN</a>}
                            {this.state.configurated && <a class="button" onClick={this.handleBackOnConfiguration}>KONFIGUROVAT</a>}
                            {this.state.configurated && <a class="button" onClick={this.handleSubmitButton}>DO KOŠÍKU</a>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async getConfiguratorAsync() {
        const requestOptions = { method: 'GET', 'Content-Type': 'application/json' };
        //const response = await fetch('configurator/' + this.id, requestOptions);

        if (this.urlName) {
            const response = await fetch('configurator/getonebyurl/' + this.urlName, requestOptions);
            if (response.status == 200) {
                const data = await response.json();
                this.id = data.id;
                this.setState({ model: data, loading: false });
            } else {
                this.id = 0;
                this.setState({ loading: false });
            }
        } else {
            this.id = 0;
            this.setState({ loading: false });
        }
    }
}

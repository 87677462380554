import React from 'react';
import SEO from './SEO';

export const Cooperation = () => {
    return (
        <>
            {SEO({ title: "Spolupráce | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
            <h1>Firemní testovací zápůjčka</h1>

            <p><b>Vítáme zájem o vzájemnou spolupráci, pojďme společně jednat.</b></p>

            <p>Nabízíme bezkonkurenční produkt, ale nejsme marketingově ani obchodně dostatečně silní a prosadit se bez partnerů je v dnešní době složité. Pro koho je spolupráce určena?</p>

            <ul>
                <li><i>Líbí se Vám naše produkty, chcete je oficiálně dodávat Vašim klientům?</i></li>
                <li><i>Jste odborným magazínem, časopisem nebo pořadem a chcete testovat EUROCOM?</i></li>
                <li><i>Vybavujete IT společnosti a rádi byste nasadili naše špičkové IT řešení?</i></li>
                <li><i>Chcete dělat reklamu produktům EUROCOM za účelem zisku?</i></li>
                <li><i>Jste vývojová společnost v oblasti výzkum, vědy, akademické půdy?</i></li>
                <li><i>Máte jinou představu a chcete nám ji sdělit?</i></li>
            </ul>

            <p>Tyto záležitosti prosím směřujte na vedení firmy níže:</p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}>Ing. Ondřej Káhn, jednatel společnosti</p>
            <p><a href="mailto:info@goldmax.cz">info@goldmax.cz</a> | <a href="tel:+420724583823">+420 724 583 823</a></p>
        </>
    );
};
import React, { useState } from 'react';
import { DynamicForm } from './DynamicForm';
import SEO from './SEO';

const formSubmitBtnName = 'ODESLAT';
const dialogHeader = 'Kontaktní formulář';
const successMessage = 'Vaše zpráva byla odeslána, budeme Vás kontaktovat během následujícího pracovního dne.';
const errorMessage = 'Omlouváme se, ale Vaši zprávu se nepodařilo odeslat, kontaktujte nás prostřednictvím e-mailu nebo telefonu.';
const fields = [
    { prop: 'name', label: 'Celé jméno', placeholder: 'Jméno a příjmení', type: 'text', size: 6, validation: 'Zadejte prosím Vaše celé jméno.', validationtype: 'required' },
    { prop: 'firm', label: 'Firma', placeholder: 'Název firmy', type: 'text', size: 6, validation: '', validationtype: '' },
    { prop: 'email', label: 'E-mail', placeholder: 'Váš e-mail', type: 'email', size: 6, validation: 'Zadejte prosím platnou emailovou adresu.', validationtype: 'email' },
    { prop: 'phone', label: 'Telefonní číslo', placeholder: 'Včetně předčíslí +420', type: 'text', size: 6, validation: 'Zadejte prosím platné telefonní číslo (+420123456789).', validationtype: 'phone' },   
    { prop: 'message', label: 'Vaše zpráva', placeholder: 'Napište nám co máte na srdci...', type: 'textarea', size: 12, validation: '', validationtype: '' }
];
let dynamicObject = {};
fields.map(item => dynamicObject[item.prop] = '');

export const Contact = () => {    
    const [object, setObject] = useState(dynamicObject);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBody, setDialogBody] = useState('');

    const handleObjectChange = e => {
        const { id, value } = e.target;
        setObject(prevObj => ({ ...prevObj, [id]: value }));
    };
    const onSubmit = () => {
        setLoading(true);
        sendFomrAsync();
    };
    const onCloseDialog = () => {
        setDialogOpen(false);
    }

    const sendFomrAsync = async () => {       
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        const response = await fetch('form?id=5', requestOptions);
        if (response.ok) {
            setDialogBody(successMessage);
            setObject(dynamicObject);
        }
        else {
            setDialogBody(errorMessage);
        }
        setLoading(false);
        setDialogOpen(true);
    }

    return (
        <>
            {SEO({ title: "Kontakt | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
            <h1>Kontakt</h1>           
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><strong>GOLDMAX SERVICES s.r.o.</strong></p>
            <p>Dolany 616, Dolany 783 16</p>

            <p style={{ paddingBottom: 0, marginBottom: 0 }}>IČ: 28572807</p>
            <p>DIČ: CZ28572807</p>
            <p><i>Společnost je zapsána u Krajského soudu v Ostravě oddíl C vložka 43781.</i></p>

            <p style={{ paddingBottom: '2px', marginBottom: '2px', fontWeight: 600 }}>Vedení společnosti a kontakt e-shopu:</p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}>Ing. Ondřej Káhn, jednatel společnosti</p>
            <p><a href="mailto:info@goldmax.cz">info@goldmax.cz</a> | <a href="tel:+420724583823">+420 724 583 823</a></p>

            <DynamicForm fields={fields} object={object} submitBtnName={formSubmitBtnName} onChangeObject={handleObjectChange} onSubmitForm={onSubmit} loading={loading} dialogHeader={dialogHeader} dialogBody={dialogBody} dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />

            <p style={{ paddingTop: '24px' }}>Neváhejte nás kontaktovat prostřednictvím výše uvedeného formuláře, nebo na <a href="mailto:info@goldmax.cz">info@goldmax.cz</a> či telefon <a href="tel:+420724583823">+420 724 583 823</a>.</p>
        </>
    );
};
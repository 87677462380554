import React, { Component } from 'react';
import Loader from './Loader';
import { authHeader } from '../helpers/auth-header';
import { Route } from 'react-router-dom';
import { authenticationService } from '../services/authentication.service';
import { ErrorNotFound } from './ErrorNotFound';
import { DialogInfo } from './DialogInfo';
import { DialogConfirm } from './DialogConfirm';

export class Currency {
    code = "";
    exchangeRate = 0;
    symbol = "";   
}

export class AdminCurrency extends Component {
    static displayName = AdminCurrency.name;

    constructor(props) {
        super(props);
        this.state = {
            currencies: [],
            currency: new Currency(),
            loading: true,
            logged: false,
            working: false,
            isUpdate: false,
            isOpen: false,
            header: "",
            message: "",
            isDelete: false,
            codeForDel: "",
            deleteId: -1
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.changeUpdate = this.changeUpdate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleUpdateCancel = this.handleUpdateCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.currencyCode = this.currencyCode.bind(this);
        this.currencyExchangeRate = this.currencyExchangeRate.bind(this);
        this.currencySymbol = this.currencySymbol.bind(this);        
        this.toggleModal = this.toggleModal.bind(this);

        if (authenticationService.currentUserValue) {
            this.state.logged = true;
        }
    }

    handleCreate() {
        this.setState({ loading: true, working: true, header: "Vytvoření měny" });
        this.createcurrencyAsync(this.state.currency);
    }
    changeUpdate(e) {
        const { id } = e.target;
        this.setState(
            {
                currency: this.state.currencies.find(x => x.id == id),
                isUpdate: true
            });
    }
    handleUpdateCancel() {
        this.setState(
            {
                currency: new Currency(),
                isUpdate: false
            });
    }
    handleUpdate(e) {
        this.setState({ loading: true, working: true, header: "Úprava měny" });
        this.updatecurrencyAsync(this.state.currency);
    }
    toggleOK = () => {
        this.setState({ isDelete: false, loading: true, working: true, header: "Smazání měny" });
        this.deletecurrencyAsync(this.state.deleteId);
    }
    toggleNOK = () => {
        this.setState({ isDelete: false });
    }
    handleDelete(e) {
        const { id } = e.target;
        var currency = this.state.currencies.find(x => x.id == id)
        this.setState({ deleteId: id, isDelete: true, codeForDel: currency.code });
    }

    currencyCode(e) {
        var value = e.target.value;
        const { currency } = this.state;
        currency.code = value;
        this.setState({ currency });
    }
    currencyExchangeRate(e) {
        var value = e.target.value;
        if (value == "") {
            value = 0;
        }
        const { currency } = this.state;
        currency.exchangeRate = parseFloat(value);
        this.setState({ currency });
    }
    currencySymbol(e) {
        var value = e.target.value;       
        const { currency } = this.state;
        currency.symbol = value;
        this.setState({ currency });
    }
    
    toggleModal() {
        if (this.state.message == "Měna byla úspěšně vytvořena." ||
            this.state.message == "Měna byla úspěšně upravena." ||
            this.state.message == "Měna byla úspěšně smazána.") {
            this.setState({
                currency: new Currency(),
                isOpen: !this.state.isOpen,
                isUpdate: false
            });
        }
        else {
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }

    componentDidMount() {
        if (this.state.logged) {
            this.getcurrenciesAsync();
        }
    }

    static rendercurrencyTable(_this) {

        return (
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th>Kód</th>
                        <th>Symbol</th>
                        <th>Kurz</th>                        
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {_this.state.currencies.map(currency =>
                        <tr key={currency.id}>
                            <td>{currency.code}</td>
                            <td>{currency.symbol}</td>
                            <td>{currency.exchangeRate}</td>                           
                            <td><div id={currency.id} key={currency.id} onClick={_this.changeUpdate} className="text-button">UPRAVIT</div></td>
                            <td><div id={currency.id} key={currency.id} onClick={_this.handleDelete} className="text-button">SMAZAT</div></td>
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

    render() {
        if (!this.state.logged) {
            return <Route path="*" component={ErrorNotFound} />
        }

        let loader = (this.state.working && this.state.loading) && <Loader loading={this.state.loading} />
        let contents = (!this.state.working && this.state.loading)
            ? <Loader loading={this.state.loading} />
            : AdminCurrency.rendercurrencyTable(this);

        return (
            <React.Fragment>
                {loader}
                <DialogConfirm
                    header="Smazání měny" show={this.state.isDelete}
                    onOKButton={this.toggleOK}
                    onNOKButton={this.toggleNOK}>
                    Opravdu chceš smazat měnu s kódem <b>{this.state.codeForDel}</b>?
                </DialogConfirm>
                <DialogInfo
                    header={this.state.header} show={this.state.isOpen}
                    onClose={this.toggleModal}>
                    {this.state.message}
                </DialogInfo>
                <h5>{this.state.isUpdate ? "Úprava měny" : "Nová měna"}</h5>
                <div className="group-container">
                    <div className="input-r-padding" style={{ width: '33.3333%', float: 'left'}}>
                        <input type="text" placeholder="Kód pro výběrové tlačítko" value={this.state.currency.code == "" ? "" : this.state.currency.code}
                            onChange={this.currencyCode} className="form-control input-text" />
                    </div>
                    <div className="input-c1-padding" style={{ width: '33.3333%', float: 'left' }}>
                        <input type="text" placeholder="Symbol za měnou" value={this.state.currency.symbol == "" ? "" : this.state.currency.symbol}
                            onChange={this.currencySymbol} className="form-control input-text" />
                    </div>                    
                    <div className="input-l-padding" style={{ width: '33.3333%', float: 'left' }}>
                        <input type="number" placeholder="Kurz" value={this.state.currency.exchangeRate == 0 ? "" : this.state.currency.exchangeRate}
                            onChange={this.currencyExchangeRate} className="form-control input-text" />
                    </div>
                </div>
                <div className="col-sm-12 text-center" style={{ marginBottom: '24px' }}>
                    {this.state.isUpdate
                        ? <React.Fragment>
                            <button style={{ marginRight: '12px' }} className="submit-button rounded" onClick={this.handleUpdate}>UPRAVIT</button>
                            <button style={{ marginLeft: '12px' }} className="submit-button rounded" onClick={this.handleUpdateCancel}>ZRUŠIT</button>
                        </React.Fragment>
                        : <button className="submit-button rounded" onClick={this.handleCreate}>VYTVOŘIT</button>}
                </div>
                {contents}
            </React.Fragment>

        );
    }

    async getcurrenciesAsync() {
        const requestOptions = { method: 'GET', headers: authHeader() };
        const response = await fetch('admin/getallcurrency', requestOptions);
        const data = await response.json();
        this.setState({ currencies: data, loading: false });
    }

    async createcurrencyAsync(currency) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(currency)
        };
        const response = await fetch('admin/createcurrency', requestOptions);
        const data = await response.json();
        const { currencies } = this.state;
        var currency = this.state.currency;
        var message = "";
        if (response.ok) {
            message = "Měna byla úspěšně vytvořena.";
            currencies.unshift(data);
        }
        else {
            message = "Měnu se nepovedlo vytvořit.";
        }
        this.setState({ loading: false, working: false, isOpen: true, message, currencies });
    }

    async updatecurrencyAsync(currency) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(currency)
        };
        const response = await fetch('admin/updatecurrency', requestOptions);
        const data = await response.json();
        var { currencies } = this.state;
        var message = "";
        if (response.ok) {
            message = "Měna byla úspěšně upravena."
            const index = currencies.findIndex(x => x.id == data.id);
            currencies[index] = data;
        }
        else {
            message = "Měnu se nepovedlo upravit."
        }
        this.setState({ loading: false, working: false, isOpen: true, message, currencies });
    }

    async deletecurrencyAsync(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: authHeader()
        };
        const response = await fetch('admin/deletecurrency/' + id, requestOptions);
        var { currencies } = this.state;
        var message = "";
        if (response.ok) {
            message = "Měna byla úspěšně smazána."

            currencies = currencies.filter(x => x.id != id);
        }
        else {
            message = "Měnu se nepovedlo smazat."
        }
        this.setState({ loading: false, working: false, isOpen: true, message, currencies });
    }
}
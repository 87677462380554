import React from 'react';
import SEO from './SEO';

export const VR = () => {
    return (
        <>    
            {SEO({ title: "Notebook Virtuální realita | EUROCOM | Goldmax", description: "Notebooky pro strojírenství na míru. Flexibilní a unikátní řešení." })}
            <h1>VR/AR Technologie</h1>
            <h2>Notebook či PC na virtuální realitu?</h2>

            <p style={{ paddingTop: "4px" }}>Nejprve si shrneme, jaké virtuální headsety lze dne pořídit. Jsou ty typicky Oculus Rift/Go, HTC Vive, Pimax atd. Virtuální realita působí ve spojení s notebookem jako strašák (zejména v oblasti grafické karty), ale obavy nejsou na místě. Specifikace jakožto minimální požadavek pro HW začíná již na staré GTX 1060 6GB, což je 5 let stará grafická karta. Samozřejmě dnes je doba jinde a od prvních pokusů s VR uplynulo několik let, tudíž se nabízí otázka, jaký HW bychom dnes doporučili pro Virtuální realitu, ať už z pohledu užívání, nebo dokonce vývoje VR aplikací?</p>
            <p><b>Doporučujeme: RTX 3070/RTX 3080 v kombinaci s 64GB RAM 3200MHz + rychlý NVMe Disk. Procesor pak volme spíše výkonnější, takže plnohodnotný Core i7/Core i9 z roku 2020 a novější.</b> Důležitým aspektem je však chlazení, protože budete-li notebook zatěžovat, měl by na to být připraven, a to se zdaleka o konkurenčních a levnějších noteboocích říci nedá. Klasický herní notebook za 30-40tis Vám vydrží sotva v záruce, bohužel tyto modely nejsou určeny pro dlouhodobou zátěž.</p>
            <p style={{ paddingBottom: "48px" }}>EUROCOM však počítá s tím, že klienti notebooky používají klidně 24/7, tudíž se nebojíme prodávat ani stroje, kde jede notebook na rendering řadu hodin či několik dní v kuse, i takové klienty evidujeme a fungují spokojeně dodnes. A že jsme dražší? Představte si, že máte 6 měsíců starý notebook, který se Vám pokazí, že ho musíte poslat někam servisovat a ani s ním nejste příliš spokojeni. V tu dobu je Vám úplně jedno, zda stál o 10tis méně či více, investice jako taková Vás netěší a pozbývá smyslu, takže ušetřené peníze nemají téměř žádnou hodnotu. Důležité je fungovat a každodenně se moc spolehnout na náš kus HW, nemyslíte?</p>

            <img className="full-img" src="images/static-content/vr_1.jpg" />
            <img className="full-img" src="images/static-content/vr_2.jpg" />
            <h5 style={{ marginTop: '24px' }} ><b>Obory uplatňující moderní VR/AR Technologie:</b></h5>
            <img className="full-img" src="images/static-content/vr_3.jpg" />
            <img className="full-img" src="images/static-content/vr_4.jpg" />
            <img style={{ marginTop: '40px' }} className="full-img" src="images/static-content/vr_5.jpg" />
            <img className="full-img" src="images/static-content/vr_6.jpg" />
            <img style={{ marginTop: '40px' }} className="full-img" src="images/static-content/vr_7.jpg" />           
        </>
    );
};
import React, { useState } from 'react';
import { DynamicForm } from './DynamicForm';
import SEO from './SEO';

const formSubmitBtnName = 'ODESLAT';
const dialogHeader = 'Čištění notebooku';
const successMessage = 'Váš požadavek byl odeslán, budeme Vás kontaktovat během následujícího pracovního dne.';
const errorMessage = 'Omlouváme se, ale Váš pořadavek se nepodařilo odeslat, kontaktujte nás prostřednictvím e-mailu nebo telefonu.';
const fields = [
    { prop: 'name', label: 'Celé jméno', placeholder: 'Jméno a příjmení', type: 'text', size: 6, validation: 'Zadejte prosím Vaše celé jméno.', validationtype: 'required' },
    { prop: 'firm', label: 'Firma', placeholder: 'Název firmy', type: 'text', size: 6, validation: '', validationtype: '' },
    { prop: 'email', label: 'E-mail', placeholder: 'Váš e-mail', type: 'email', size: 6, validation: 'Zadejte prosím platnou emailovou adresu.', validationtype: 'email' },
    { prop: 'phone', label: 'Telefonní číslo', placeholder: 'Včetně předčíslí +420', type: 'text', size: 6, validation: 'Zadejte prosím platné telefonní číslo (+420123456789).', validationtype: 'phone' },
    { prop: 'street', label: 'Ulice a číslo popisné', placeholder: 'Ulice a číslo popisné/orientační', type: 'text', size: 6, validation: 'Zadejte prosím platnou ulici a číslo pospisné.', validationtype: 'required' },
    { prop: 'zipcode', label: 'PSČ', placeholder: 'PSČ', type: 'number', size: 6, validation: 'Zadejte prosím platné poštovní směrovací číslo.', validationtype: 'required' },
    { prop: 'serialnumber', label: 'Sériové číslo notebooku', placeholder: 'Sériové číslo notebooku', type: 'text', size: 12, validation: 'Zadejte prosím platné sériové číslo.', validationtype: 'required' },
    { prop: 'message', label: 'Poznámky a další instrukce', placeholder: 'Vaše zpráva...', type: 'textarea', size: 12, validation: '', validationtype: '' }
]; 
let dynamicObject = {};
fields.map(item => dynamicObject[item.prop] = '');

export const Cleaning = () => { 
    const [cardIndex, setCardIndex] = useState(1);
    const [object, setObject] = useState(dynamicObject);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBody, setDialogBody] = useState('');    

    const handleObjectChange = e => {
        const { id, value } = e.target;              
        setObject(prevObj => ({...prevObj, [id]: value }));
    };
    const onSubmit = () => {   
        setLoading(true);
        sendFomrAsync();
    };
    const onCloseDialog = () => {
        setDialogOpen(false);
    }

    const sendFomrAsync = async () => {
        object['variant'] = cardIndex;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        const response = await fetch('form?id=1', requestOptions);         
        if (response.ok) {   
            setDialogBody(successMessage);
            setObject(dynamicObject);
        }
        else {
            setDialogBody(errorMessage);
        } 
        setLoading(false);
        setDialogOpen(true);        
    }

    return (
        <> 
            {SEO({ title: "Čištění notebooku | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
            <h1>Čištění notebooku</h1>
            <p>Zde si můžete jako zákazník a majitel notebooku EUROCOM požádat o čištění notebooku a reinstalaci Windows včetně driverů. Zvolte jednu z variant:</p>

            <div className="card-button-container">
                <div className={cardIndex === 1 ? "card-s-6 card-active" : "card-s-6"} onClick={() => setCardIndex(1)}>
                    <h5>BASIC</h5>
                    <ul>
                        <li>vyzvednutí a dovoz notebooku zpět pomocí kurýra PPL</li>
                        <li>sejmutí okruhu chlazení, čištění žebrování a podložek</li>
                        <li>nanesení prémiové termální pasty Kryonaut</li>
                        <li>zahoření a teplotní test chladícího okruhu</li>                                              
                    </ul>
                    <p>3 490 Kč bez DPH 21%</p>
                </div>
                <div className={cardIndex === 2 ? "card-s-6 card-active" : "card-s-6"} onClick={() => setCardIndex(2)}>
                    <h5>ADVANCED</h5>
                    <ul>
                        <li>varianta <b>BASIC</b></li>
                        <li>čistá instalace Windows O/S</li>
                        <li>instalace driverů</li>
                        <li>update BIOSu</li>
                    </ul>
                    <p>4 790 bez DPH 21%</p>
                </div>
            </div>

            <DynamicForm fields={fields} object={object} submitBtnName={formSubmitBtnName} onChangeObject={handleObjectChange} onSubmitForm={onSubmit} loading={loading} dialogHeader={dialogHeader} dialogBody={dialogBody} dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />

            <p style={{ paddingTop: '24px' }}>Veškeré další dotazy směřujte na <a href="mailto:info@goldmax.cz">info@goldmax.cz</a> či telefon <a href="tel:+420724583823">+420 724 583 823</a>.</p>
        </>
    );
};
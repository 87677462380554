import React from 'react';
import SEO from './SEO';
import "./Terms.css";

export const Terms = () => {    
    return <div className="terms-container">
        {SEO({ title: "Obchodní podmínky | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
        <h1>I. Základní ustanovení</h1>
        <p>Tyto VOP upravují vztahy mezi smluvními stranami kupní smlouvy/licenční smlouvy, kdy na jedné straně je společnost <b>GOLDMAX SERVICES s.r.o.</b>, IČ 28572807, DIČ CZ28572807, se sídlem Dolany 616, 783 , Dolany, zapsaná v obchodním rejstříku v oddíle C 43781 vedeném u Krajského soudu v Ostravě , vložka 8573 jako prodávající (dále jen „<b>Goldmax.cz</b>“) a na straně druhé je kupující (dále jen „<b>Kupující</b>“).</p>
        <p>Další informace o Goldmax.cz jsou uvedeny na webové stránce www.goldmax.cz</p>
        <p><b>Kupujícím</b> je spotřebitel nebo podnikatel.</p>
        <p><b>Spotřebitelem</b> je každý člověk, který mimo rámec své podnikatelské činnosti nebo mimo rámec samostatného výkonu svého povolání uzavírá smlouvu s Goldmax.cz nebo s ní jinak jedná.</p>
        <p><b>Podnikatelem</b> je ten, kdo samostatně vykonává na vlastní účet a odpovědnost výdělečnou činnost živnostenským nebo obdobným způsobem se záměrem činit tak soustavně za účelem dosažení zisku. Za podnikatele je považován mj. pro účely ochrany spotřebitele také každá osoba, která uzavírá smlouvy související s vlastní obchodní, výrobní nebo obdobnou činností či při samostatném výkonu svého povolání, popřípadě osoba, která jedná jménem nebo na účet podnikatele.</p>
        <p>Kupující podáním objednávky potvrzuje, že se seznámil s těmito VOP, jejichž nedílnou součást tvoří sdělení před uzavřením smlouvy, reklamační řád a dokument „Způsoby dopravy“, a že s nimi výslovně souhlasí, a to ve znění platném a účinném v momentě odeslání objednávky.</p>
        <p>Kupující si je vědom, že mu koupí produktů, jež jsou v obchodní nabídce Goldmax.cz, nevznikají žádná práva na používání registrovaných značek, obchodních názvů, firemních log, aj. Goldmax.cz nebo smluvních partnerů Goldmax.cz, není-li v konkrétním případě zvláštní smlouvou sjednáno jinak.</p>
        <p>Kopii VOP obdrží Kupující jako přílohu potvrzení objednávky na zadanou emailovou adresu, přičemž daňový doklad obsahující základní údaje smlouvy obdrží Kupující při převzetí zboží a/nebo služby.</p>

        <h1>II. Sdělení před uzavřením smlouvy</h1>
        <h5>Goldmax.cz sděluje, že</h5>
        <ol className="lower-latin">
            <li>náklady na prostředky komunikace na dálku se neliší od základní sazby (v příp. internetového i telefonického připojení dle podmínek Vašeho operátora, Goldmax.cz si neúčtuje žádné další poplatky, to se netýká příp. smluvní přepravy)</li>
            <li>požaduje úhradu kupní ceny před převzetím plnění Kupujícím od Goldmax.cz, příp. povinnost zaplatit zálohu nebo obdobnou platbu se týká požadavků Kupujícího na poskytnutí specifických služeb, jsou-li vyžadovány a poskytovány</li>
            <li>Goldmax.cz neuzavírá smlouvy, jejímž předmětem je opakované plnění, pokud takové smlouvy zprostředkovává, nejkratší dobu, po kterou bude smlouva strany zavazovat, sděluje poskytovatel daného plnění, včetně údajů o ceně, nebo způsobu jejího určení za jedno zúčtovací období, kterým je vždy jeden měsíc, pokud je tato cena neměnná</li>
            <li>v případě licenčních smluv uzavíraných na dobu neurčitou, je cena licence sjednána za celou dobu poskytování licence, není-li uvedeno jinak</li>
            <li>ceny zboží a služeb jsou na webu provozovaném Goldmax.cz uváděny včetně i bez DPH, včetně veškerých poplatků stanovených zákonem, nicméně náklady na dodání zboží nebo služby se liší podle zvolené metody a poskytovatele dopravy a způsobu úhrady</li>
            <li>v případě, že Kupujícím je spotřebitel, má takový spotřebitel právo od smlouvy odstoupit (není-li níže uvedeno jinak), a to ve lhůtě čtrnácti dnů, která běží, jde-li o
                <ol className="lower-roman">
                    <li>kupní smlouvu, ode dne převzetí zboží</li>
                    <li>smlouvu, jejímž předmětem je několik druhů zboží nebo dodání několika částí, ode dne převzetí poslední dodávky zboží</li>
                    <li>smlouvu, jejímž předmětem je pravidelná opakovaná dodávka zboží, ode dne převzetí první dodávky zboží</li>    
                </ol>
            </li>
            <span>přičemž toto odstoupení musí zaslat na adresu sídla Goldmax.cz</span>
            <li>spotřebitel nemůže odstoupit od smlouvy
                <ol className="lower-roman">
                    <li>o poskytování služeb, které Goldmax.cz splnil s předchozím výslovným souhlasem spotřebitele před uplynutím lhůty pro odstoupení od smlouvy</li>
                    <li>o dodávce zboží nebo služby, jejichž cena závisí na výchylkách finančního trhu nezávisle na vůli Goldmax.cz a k němuž může dojít během lhůty pro odstoupení od smlouvy</li>
                    <li>o dodávce zboží, které bylo upraveno podle přání spotřebitele nebo pro jeho osobu (individuální konfigurace)</li>
                    <li>o dodávce zboží, které podléhá rychlé zkáze, jakož i zboží, které bylo po dodání nenávratně smíseno s jiným zbožím</li>
                    <li>o opravě nebo údržbě provedené v místě určeném spotřebitelem na jeho žádost; to však neplatí v případě následného provedení jiných než vyžádaných oprav či dodání jiných než vyžádaných náhradních dílů</li>
                    <li>o dodávce zboží v uzavřeném obalu, které spotřebitel z obalu vyňal a z hygienických důvodů jej není možné vrátit</li>
                    <li>o dodávce zvukové nebo obrazové nahrávky nebo počítačového programu, pokud porušil jejich původní obal</li>
                    <li>o dodávce novin, periodik nebo časopisů</li>
                    <li>o dopravě nebo využití volného času, pokud podnikatel tato plnění poskytuje v určeném termínu</li>
                    <li>o dodání digitálního obsahu, pokud nebyl dodán na hmotném nosiči a byl dodán s předchozím výslovným souhlasem spotřebitele před uplynutím lhůty pro odstoupení od smlouvy, Goldmax.cz zdůrazňuje, že v případě dodání digitálního obsahu dodávaného on-line nelze od takové smlouvy odstoupit</li>
                </ol>
            </li>
            <li>v případě odstoupení od smlouvy ponese spotřebitel náklady spojené s navrácením zboží, a jde-li o smlouvu uzavřenou prostřednictvím prostředku komunikace na dálku, náklady za navrácení zboží, jestliže toto zboží nemůže být vráceno pro svou povahu obvyklou poštovní cestou</li>
            <li>spotřebitel má povinnosti uhradit poměrnou část ceny v případě odstoupení od smlouvy, jejímž předmětem je poskytování služeb a jejichž plnění již začalo</li>
            <li>smlouva, resp. příslušný daňový doklad, bude uložena v elektronickém archivu Goldmax.cz, přičemž registrovaní uživatelé Goldmax.cz mají k těmto údajům také přístup ve svém profilu</li>
            <li>v případě, že spotřebitel má stížnost, může tuto uplatnit u ombudsmana Goldmax.cz, příp. se lze obrátit se stížností na orgán dohledu nebo státního dozoru</li>
            <li>v případě odstoupení od smlouvy uhradí spotřebitel, který odstupuje, prokazatelné náklady, které vznikly druhé smluvní straně v souladu s kupní smlovou avšak do maximální výše ceny předmětu plnění</li>
            <li>dojde-li k odstoupení od kupní smlouvy v době provádění díla z důvodů na straně Kupujícího, Kupující je povinen zaplatit  nezbytné, účelně vynaložené a prokazatelné náklady na provádění díla Goldmax.cz, které se výlučně k předmětu plnění váží, čímž se rozumí příprava, produkce a servisní úkony individuální konfigurace dle přání spotřebitele, veškerá dílčí administrativa a také sankci (storno poplatek) 20% z kupní ceny předmětu plnění</li>
        </ol> 

        <h1>III. Smlouva</h1>
        <p>Smlouvu může Kupující uzavřít tak, že přijme návrh k uzavření smlouvy na webu provozovaném Goldmax.cz tím, že požadované plnění (zboží, službu, elektronický obsah) vloží do košíku. Kupujícímu může při uzavírání smlouvy asistovat pracovník Goldmax.cz, ať už přímo po telefonu nebo při faxové či emailové objednávce. Než Kupující závazně potvrdí objednávku, má Kupující právo měnit jak požadované plnění, dopravu i způsob úhrady, tedy zkontrolovat všechny údaje, které do objednávky vložil. Kupní smlouva vzniká odesláním objednávky Kupujícím po zvolení dopravy a způsobu úhrady a přijetím objednávky pracovníkem Goldmax.cz, za případné chyby při přenosu dat Goldmax.cz nenese odpovědnost. Uzavření smlouvy Goldmax.cz neprodleně potvrdí Kupujícímu informativním emailem na Kupujícím zadaný email.</p>
        <p>Přílohou tohoto potvrzení je aktuální znění VOP včetně Reklamačního řádu v nich obsažených. Vzniklou smlouvu (včetně dohodnuté ceny) lze měnit nebo rušit pouze na základě dohody stran nebo na základě zákonných důvodů. Pro výjimku z tohoto postupu viz bod Objednávání.</p>
        <p>Vztahy a případné spory, které vzniknou na základě smlouvy, budou řešeny výhradně podle práva České republiky a budou řešeny příslušnými soudy České republiky.</p>
        <p>Smlouva je uzavírána v českém jazyce. Pokud vznikne pro potřebu kupujícího překlad textu smlouvy, platí, že v případě sporu o výklad pojmů platí výklad smlouvy v českém jazyce.</p>
        <p>Uzavřená smlouva je prodávajícím archivována po dobu nejméně pěti let od jejího uzavření, nejdéle však na dobu dle příslušných právních předpisů, za účelem jejího úspěšného splnění a není přístupná třetím nezúčastněným stranám. Informace o jednotlivých technických krocích vedoucích k uzavření smlouvy jsou patrné z těchto obchodních podmínek, kde je tento proces srozumitelně popsán.</p>
        <p>Kupní smlouvou s Goldmax.cz zavazuje, že Kupujícímu odevzdá věc, poskytne digitální obsah/licenci, která je předmětem koupě, a umožní mu nabýt vlastnické právo/licenci k ní, a Kupující se zavazuje, že věc/digitální obsah převezme a zaplatí Goldmax.cz kupní cenu.</p>
        <p>Goldmax.cz si vyhrazuje vlastnické právo k věci, a proto se Kupující stane vlastníkem teprve úplným zaplacením kupní ceny.</p>
        <p>Goldmax.cz Kupujícímu odevzdá věc, jakož i doklady, které se k věci vztahují, a umožní Kupujícímu nabýt vlastnického práva k věci/licenci v souladu se smlouvou.</p>
        <p>Goldmax.cz splní povinnost odevzdat věc Kupujícímu, umožní-li mu nakládat s věcí v místě plnění a včas mu to oznámí.</p>
        <p>Má-li Goldmax.cz věc odeslat, odevzdá věc Kupujícímu (podnikateli) předáním prvnímu dopravci k přepravě pro Kupujícího a umožní Kupujícímu uplatnit práva z přepravní smlouvy vůči dopravci, Goldmax.cz odevzdá Kupujícímu – spotřebiteli až, jakmile mu věc předá dopravce.</p>
        <p>Dodá-li Goldmax.cz větší množství věcí, než bylo ujednáno, je kupní smlouva uzavřena i na přebytečné množství, ledaže je Kupující bez zbytečného odkladu odmítl.</p>
        <p>Goldmax.cz odevzdá Kupujícímu předmět koupě v ujednaném množství, jakosti a provedení.</p>
        <p>Není-li ujednáno, jak má být věc zabalena, zabalí Goldmax.cz věc podle zvyklostí; nejsou-li, pak způsobem potřebným pro uchování věci a její ochranu. Stejným způsobem opatří Goldmax.cz věc pro přepravu.</p>
        <p>Věc je vadná, nemá-li sjednané vlastnosti. Za vadu se považuje i plnění jiné věci a vady v dokladech nutných pro užívání věci.</p>
        <p>Právo Kupujícího z vadného plnění zakládá vada, kterou má věc při přechodu nebezpečí škody na Kupujícího, byť se projeví až později. Právo Kupujícího založí i později vzniklá vada, kterou Goldmax.cz způsobil porušením své povinnosti.</p>
        <p>Kupující věc podle možnosti prohlédne co nejdříve po přechodu nebezpečí škody na věci a přesvědčí se o jejích vlastnostech a množství.</p>
        <p>Nebezpečí škody přechází na Kupujícího převzetím věci. Týž následek má, nepřevezme-li Kupující věc, ač mu s ní Goldmax.cz umožnil nakládat.</p>
        <p>Škoda na věci, vzniklá po přechodu nebezpečí škody na věci na Kupujícího, nemá vliv na jeho povinnost zaplatit kupní cenu, ledaže Goldmax.cz škodu způsobil porušením své povinnosti.</p>
        <p>Prodlením strany s převzetím věci vzniká druhé straně právo věc po předchozím upozornění na účet prodlévajícího vhodným způsobem prodat poté, co prodlévajícímu poskytla dodatečnou přiměřenou lhůtu k převzetí. To platí i tehdy, prodlévá-li strana s placením, kterým je předání věci podmíněno.</p>
        <p>Za zrušení objednávky ze strany Kupujícího je považováno nezaplacení objednávky v termínu splatnosti. Při odstoupení od smlouvy je zákazník povinen zaplatit odstupné (storno poplatek) 20% z ceny předmětu plnění. Storno poplatek je stanoven zárověň na hodnotu 10.000 kč a vyšší, dle ceny Předmětu plnění.</p>
        <h4>Odpovědnost Goldmax.cz</h4>
        <h5>Goldmax.cz odpovídá Kupujícímu, že věc při převzetí nemá vady. Zejména Goldmax.cz odpovídá Kupujícímu, že v době, kdy Kupující věc převzal,</h5>
        <ol className="lower-latin">
            <li>má věc vlastnosti, které si strany ujednaly, a chybí-li ujednání, takové vlastnosti, které Goldmax.cz nebo výrobce popsal nebo které Kupující očekával s ohledem na povahu zboží a na základě reklamy jimi prováděné,</li>
            <li>se věc hodí k účelu, který pro její použití Goldmax.cz uvádí nebo ke kterému se věc tohoto druhu obvykle používá,</li>
            <li>je věc v odpovídajícím množství, míře nebo hmotnosti a</li>
            <li>věc vyhovuje požadavkům právních předpisů.</li>            
        </ol>
        <p>Projeví-li se vada v průběhu šesti měsíců od převzetí, má se za to, že věc byla vadná již při převzetí.</p>
        <h5>Kupující je oprávněn uplatnit právo z vady, která se vyskytne u spotřebního zboží v době dvaceti čtyř měsíců od převzetí, to se však netýká</h5>
        <ol className="lower-latin">
            <li>u věci prodávané za nižší cenu na vadu, pro kterou byla nižší cena ujednána,</li>
            <li>na opotřebení věci způsobené jejím obvyklým užíváním,</li>
            <li>u použité věci na vadu odpovídající míře používání nebo opotřebení, kterou věc měla při převzetí kupujícím, nebo</li>
            <li>vyplývá-li to z povahy věci.</li>           
        </ol>
        <p>Právo z vadného plnění Kupujícímu nenáleží, pokud Kupující před převzetím věci věděl, že věc má vadu, anebo pokud Kupující vadu sám způsobil.</p>
        <p>Má-li věc vadu, z níž je Goldmax.cz zavázán, a jedná-li se o věc prodávanou za nižší cenu nebo o věc použitou, má Kupující místo práva na výměnu věci právo na přiměřenou slevu.</p>       
        <h4>Podstatné porušení smlouvy</h4>
        <h5>Je-li vadné plnění podstatným porušením smlouvy, má Kupující právo</h5>
        <ol className="lower-latin">
            <li>na odstranění vady dodáním nové věci bez vady nebo dodáním chybějící věci, pokud to není vzhledem k povaze vady nepřiměřené, ale pokud se vada týká pouze součásti věci, může Kupující požadovat jen výměnu součásti; není-li to možné, může odstoupit od smlouvy. Je-li to však vzhledem k povaze vady neúměrné, zejména lze-li vadu odstranit bez zbytečného odkladu, má kupující právo na bezplatné odstranění vady,</li>
            <li>na odstranění vady opravou věci,</li>
            <li>na přiměřenou slevu z kupní ceny, nebo</li>
            <li>odstoupit od smlouvy.</li>            
        </ol>
        <p>Kupující sdělí Goldmax.cz, jaké právo si zvolil, při oznámení vady, nebo bez zbytečného odkladu po oznámení vady. Provedenou volbu nemůže Kupující změnit bez souhlasu Goldmax.cz; to neplatí, žádal-li Kupující opravu vady, která se ukáže jako neopravitelná. Neodstraní-li Goldmax.cz vady v přiměřené lhůtě či oznámí-li Kupujícímu, že vady neodstraní, může Kupující požadovat místo odstranění vady přiměřenou slevu z kupní ceny, nebo může od smlouvy odstoupit. Nezvolí-li si Kupující své právo včas, má práva jako v případě nepodstatného porušení smlouvy – viz níže.</p>
        <p>Kupující-spotřebitel má právo na přiměřenou slevu i v případě, že mu Goldmax.cz nemůže dodat novou věc bez vad, vyměnit její součást nebo věc opravit, jakož i v případě, že Goldmax.cz nezjedná nápravu v přiměřené době nebo že by zjednání nápravy spotřebiteli působilo značné obtíže.</p>
        <p>
            <h4>Nepodstatné porušení smlouvy</h4>
            <h5>Je-li vadné plnění nepodstatným porušením smlouvy, má Kupující právo na odstranění vady, anebo na přiměřenou slevu z kupní ceny.</h5>
        </p>
        <p>Dokud Kupující neuplatní právo na slevu z kupní ceny nebo neodstoupí od smlouvy, může Goldmax.cz dodat to, co chybí, nebo odstranit právní vadu. Jiné vady může Goldmax.cz odstranit podle své volby opravou věci nebo dodáním nové věci.</p>
        <p>Neodstraní-li Goldmax.cz vadu věci včas nebo vadu věci odmítne odstranit, může Kupující požadovat slevu z kupní ceny, anebo může od smlouvy odstoupit. Provedenou volbu nemůže Kupující změnit bez souhlasu Goldmax.cz.</p>
        <p>Právo na dodání nové věci, nebo výměnu součásti má Kupující i v případě odstranitelné vady, pokud nemůže věc řádně užívat pro opakovaný výskyt vady po opravě nebo pro větší počet vad. V takovém případě má Kupující i právo od smlouvy odstoupit.</p>
        <p>Při dodání nové věci vrátí Kupující Goldmax.cz na jeho náklady věc původně dodanou.</p>
        <p>Neoznámil-li Kupující vadu bez zbytečného odkladu poté, co ji mohl při včasné prohlídce a dostatečné péči zjistit, soud mu právo z vadného plnění nepřizná. Jedná-li se o skrytou vadu, platí totéž, nebyla-li vada oznámena bez zbytečného odkladu poté, co ji Kupující mohl při dostatečné péči zjistit, nejpozději však do dvou let po odevzdání věci.</p>
        <p>Zárukou za jakost se Goldmax.cz zavazuje, že věc bude po určitou dobu způsobilý k použití pro obvyklý účel nebo že si zachová obvyklé vlastnosti. Tyto účinky má i uvedení záruční doby nebo doby použitelnosti věci na obalu nebo v reklamě. Záruka může být poskytnuta i na jednotlivou součást věci.</p>
        <p>Záruční doba běží od odevzdání věci Kupujícímu; byla-li věc podle smlouvy odeslána, běží od dojití věci do místa určení. Má-li koupenou věc uvést do provozu někdo jiný než Goldmax.cz, běží záruční doba až ode dne uvedení věci do provozu, pokud Kupující objednal uvedení do provozu nejpozději do tří týdnů od převzetí věci a řádně a včas poskytl k provedení služby potřebnou součinnost.</p>
        <p>Kupující nemá právo ze záruky, způsobila-li vadu po přechodu nebezpečí škody na věci na Kupujícího vnější událost.</p>

        <h1>IV. Odstoupení od smlouvy</h1>
        <h4>Odstoupení od smlouvy spotřebitelem</h4>
        <h5>Spotřebitel má právo odstoupit od smlouvy ve lhůtě čtrnácti dnů. Lhůta podle věty první běží ode dne uzavření smlouvy a jde-li o</h5>
        <ol className="lower-latin">
            <li>kupní smlouvu, ode dne převzetí zboží,</li>
            <li>smlouvu, jejímž předmětem je několik druhů zboží nebo dodání několika částí, ode dne převzetí poslední dodávky zboží, nebo</li>
            <li>smlouvu, jejímž předmětem je pravidelná opakovaná dodávka zboží, ode dne převzetí první dodávky zboží.</li>
        </ol>
        <h5>Odstoupit lze také korespondenčně, a to na adrese Goldmax.cz – reklamace, Ing. Ivana Šperková, Dolany 616, Dolany</h5>
        <p>Odstoupí-li spotřebitel od smlouvy, zašle nebo předá Goldmax.cz bez zbytečného odkladu, nejpozději do čtrnácti dnů od odstoupení od smlouvy, zboží, které od Goldmax.cz obdržel, a to na své náklady.</p>
        <p>Zboží by měl spotřebitel vrátit úplné, s kompletní dokumentací, nepoškozené, čisté, pokud možno včetně originálního obalu, ve stavu a hodnotě, v jakém zboží převzal.</p>
        <p>Rozhodne-li se spotřebitel pro odstoupení v uvedené lhůtě, doporučujeme pro urychlení vyřízení odstoupení zboží doručit na adresu Goldmax.cz spolu s přiloženým průvodním dopisem s případným důvodem odstoupení od kupní smlouvy (není podmínkou), s číslem nákupního dokladu a uvedeným číslem bankovního účtu.</p>
        <p>Spotřebitel odpovídá Goldmax.cz pouze za snížení hodnoty zboží, které vzniklo v důsledku nakládání s tímto zbožím jinak, než je nutné s ním nakládat s ohledem na jeho povahu a vlastnosti.</p>
        <p>Odstoupí-li spotřebitel od smlouvy, vrátí mu Goldmax.cz bez zbytečného odkladu, nejpozději do třiceti dnů od odstoupení od smlouvy, všechny peněžní prostředky, které od něho na základě smlouvy přijal, stejným způsobem.</p>
        <p>Odstoupí-li spotřebitel od smlouvy, Goldmax.cz však není povinna vrátit přijaté peněžní prostředky spotřebiteli dříve, než jí spotřebitel zboží předá nebo prokáže, že zboží odeslal.</p>
        <p>Spotřebitel bere na vědomí skutečnost, že jsou-li se zbožím poskytovány dárky, je darovací smlouva mezi Goldmax.cz a Kupujícím uzavřena s podmínkou, že dojde-li k využití práva spotřebitele odstoupit od kupní smlouvy, darovací smlouva pozbývá účinnosti a spotřebitel je povinen spolu s vráceným zbožím vrátit i s ním související poskytnuté dárky včetně všeho, oč se obohatil. V případě, že tyto nebudou vráceny zpět, budou tyto hodnoty chápány jako bezdůvodné obohacení spotřebitele. Není-li vydání předmětu bezdůvodného obohacení dobře možné, má Goldmax.cz právo na peněžitou náhradu ve výši obvyklé ceny.</p>
        <h4>Odstoupení v ostatních případech</h4>
        <h5>Kupující nemůže odstoupit od smlouvy, ani požadovat dodání nové věci, nemůže-li věc vrátit v tom stavu, v jakém ji obdržel. To neplatí,</h5>
        <ol className="lower-latin">
            <li>došlo-li ke změně stavu v důsledku prohlídky za účelem zjištění vady věci,</li>
            <li>použil-li Kupující věc ještě před objevením vady,</li>
            <li>nezpůsobil-li Kupující nemožnost vrácení věci v nezměněném stavu jednáním anebo opomenutím, nebo</li>
            <li>prodal-li Kupující věc ještě před objevením vady, spotřeboval-li ji, anebo pozměnil-li věc při obvyklém použití; stalo-li se tak jen zčásti, vrátí Kupující Goldmax.cz, co ještě vrátit může, a dá Goldmax.cz náhradu do výše, v níž měl z použití věci prospěch.</li>
        </ol>
        <p>Neoznámil-li Kupující vadu věci včas, pozbývá právo odstoupit od smlouvy.</p>
        
        <h1>V. Bezpečnost a ochrana informací</h1>
        <p>Prodávající prohlašuje, že veškeré osobní údaje jsou důvěrné, budou použity pouze k uskutečnění plnění smlouvy s kupujícím a marketingových akcí prodávajícího a nebudou jinak zveřejněny, poskytnuty třetí osobě apod. s výjimkou situace související s distribucí či platebním stykem týkajícího se objednaného zboží (sdělení jména a adresy dodání). Prodávající postupuje tak, aby subjekt údajů neutrpěl újmu na svých právech, zejména na právu na zachování lidské důstojnosti, a také dbá na ochranu před neoprávněným zasahováním do soukromého a osobního života subjektu údajů. Osobní údaje, které jsou poskytnuty dobrovolně kupujícím prodávajícímu za účelem splnění objednávky a marketingových akcí prodávajícího, jsou shromažďovány, zpracovávány a uchovávány v souladu s platnými zákony České republiky, zejména se zákonem č. 101/2000 Sb., o ochraně osobních údajů, v platném a účinném znění. Kupující dává prodávajícímu svůj souhlas ke shromažďování a zpracování těchto osobních údajů pro účely splnění předmětu uzavírané kupní smlouvy a využití pro marketingové účely prodávajícího (zejm. pro zasílání obchodních sdělení, telemarketing, sms), a to až do doby jeho písemného vyjádření nesouhlasu s tímto zpracováním zaslaným na adresu <b>GOLDMAX SERVICES S.R.O., Dolany 616, 78316, Dolany</b>. Za písemné vyjádření se v tomto případě považuje i forma elektronická, zejména prostřednictvím kontaktního formuláře na webových stránkách Goldmaxcz. Kupující má právo přístupu ke svým osobním údajům a právo na jejich opravu (prostřednictvím citovaného kontaktního formuláře včetně práva požadovat vysvětlení a odstranění závadného stavu a dalších zákonných práv k těmto údajům.</p>
        <p>Goldmax.cz může dále při poskytnutí souhlasu zpracovávat tzv. „cookies“ tak, aby usnadnila poskytování služeb informační společnosti, v souladu s ustanovením Směrnice 95/46/ES o účelu „cookies“ či podobných nástrojů a je zajištěno, aby uživatelům byly známy informace, které se ukládají do koncového zařízení, jež používají, Goldmax.cz pro tento účel stanoví samostatné podmínky pro použití cookies. Uživatelé mají možnost odmítnout, aby „cookies“ nebo podobné nástroje byly ukládány do jejich koncových zařízení, např. tím, že spustí ve svém prohlížeči funkcionalitu anonymního prohlížení.</p>

        <h1>VI. Provozní doba</h1>
        <p>Objednávky přes internetové obchody Goldmax.cz: 24 hodin denně, 7 dní v týdnu vyjma „celozávodních dovolených“ ohlašených na www.goldmax.cz vždy před jejích zahájením.</p>
        
        <h1>VII. Ceny</h1>
        <p>
            Všechny ceny jsou smluvní. V on-line e-shopu jsou vždy aktuální a platné ceny.<br />
            Ceny jsou konečné, tj. včetně DPH, popř. všech dalších daní a poplatků, které musí spotřebitel pro získání zboží zaplatit, to se netýká případných poplatků za dopravné, dobírečné a nákladů na komunikace prostředky na dálku.<br />
            Akční ceny platí do vyprodání zásob při uvedení počtu kusů akčního zboží nebo po dobu časově určenou.<br />
            Běžná cena znamená výrobcem/dodavatelem doporučené koncové ceny.<br />
        </p>
        
        <h1>VIII. Objednávání</h1>
        <p>Kupující dostane plnění za cenu platnou v době objednání. Tato cena bude uvedena v objednávce a ve zprávě potvrzující přijetí objednávky zboží.</p>
        <h5>Objednávat je možno následujícími způsoby:</h5>
        <ul>
            <li>prostřednictvím elektronického obchodu Goldmax.cz (dále jen „e-shop“)</li>
            <li>elektronickou poštou na adrese order@goldmax.cz</li>
            <li>telefonicky</li>            
        </ul>
        <p>Goldmax.cz doporučuje Kupujícímu provádět objednávky přes e-shop prostřednictvím registrovaného profilu Kupujícího na Goldmax.cz. V případě veřejného přístupu k internetu dále doporučuje Kupujícímu odhlásit se ze svého profilu po provedení objednávky.</p>
        
        <h1>IX. Platební podmínky</h1>
        <h5>Prodávající akceptuje následující platební podmínky:</h5>
        <ol className="lower-latin">
            <li>platba předem bankovním převodem,</li>
            <li>na dobírku při doručení zboží (hotovost přebírá od zákazníka přepravce – kupující hradí navíc tzv. dobírečné – viz „Způsoby dopravy“),</li>
            <li>platba na fakturu se splatností (pouze při splnění podmínek a ve výjimečných případech, které posoudí pracovník Goldmax.cz),</li>
            <li>na splátky (pouze při splnění podmínek a ve výjimečných případech, které posoudí pracovník Goldmax.cz),</li>
        </ol>
        <p>Zboží zůstává do úplného zaplacení a převzetí ve vlastnictví Goldmax.cz, avšak nebezpečí škody na věci přechází převzetím zboží Kupujícím.</p>
        
        <h1>X. Dodací podmínky</h1>
        <h5>Osobní odběr:</h5>
        <p>Zboží může převzít pouze kupující. Tato osoba se musí dostatečně identifikovat a prokázat platným občanským průkazem nebo platným cestovním pasem.</p>
        <h5>Zasílání přepravní službou – ČR:</h5>
        <p>Zboží je možno kupujícímu zaslat vybranou přepravní službou.</p>
        <h5>Zasílání přepravní službou – SR:</h5>
        <p>Zboží je možno kupujícímu zaslat přepravní službou přímo na jím zvolenou adresu. Cena přepravy se řídí dle ceníku aktuálního v den objednávky.</p>
        <p>Ceny dopravy naleznete na webové stránce www.Goldmax.cz.</p>
        <p>Kupující je povinen bezprostředně při dodání překontrolovat spolu s dopravcem stav zásilky (počet balíků, neporušenost pásky s firemním logem, poškození krabice) podle přiloženého přepravního listu. Kupující je oprávněn odmítnout převzetí zásilky, která není ve shodě s kupní smlouvou tím, že zásilka je např. neúplná nebo poškozená. Pokud takto poškozenou zásilku Kupující od přepravce převezme, je nezbytné poškození popsat v předávacím protokolu přepravce.</p>
        <p>Neúplnou nebo poškozenou zásilku je nutno neprodleně oznámit e-mailem na adresu <b>info@goldmax.cz</b>, sepsat s dopravcem škodní protokol a tento bez zbytečného odkladu zaslat faxem, e-mailem, nebo poštou Goldmax.cz. Dodatečná reklamace neúplnosti nebo vnějšího poškození zásilky nezbavuje Kupujícího práva věc reklamovat, dává však Goldmax.cz možnost prokázat, že se nejedná o rozpor s kupní smlouvou.</p>
        
        <h1>XI. Záruční podmínky</h1>
        <p>Záruční podmínky na zboží se řídí Reklamačním řádem (součástí těchto VOP) Goldmax.cz a příslušnými právními předpisy ČR. Jako záruční list obvykle slouží nákupní doklad (podrobnosti viz Reklamační řád uvedený níže).</p>

        <h1>XII. Závěrečná ustanovení</h1>
        <p>Tyto Všeobecné obchodní podmínky včetně jejich součástí jsou platné a účinné od 1. 1. 2014 a ruší předchozí znění VOP včetně jejích součástí, přičemž jsou k dispozici v sídle  Goldmax.cz nebo elektronicky na www.goldmax.cz.</p>

        <hr />
        <h1 style={{ paddingTop: '24px' }}>Reklamační řád</h1>
        <h1>I. Všeobecná ustanovení</h1>
        <p>Reklamační řád je nedílnou součástí Všobechných obchodních podmínek prodávajícího GOLDMAX SERVICES S.R.O., IČ285728070, DIČ CZ28572807, se sídlem Dolany 616, 78316, Dolany, zapsaná v obchodním rejstříku v oddíle C 43781 vedeném u Krajského soudu v Ostravě , vložka 8573 a popisuje postup, jakým způsobem přistupovat při reklamaci zboží pořízeného od prodávajícího.</p>
        <p>Kupující je povinen se seznámit s Reklamačním řádem, který je součástí Všeobecných obchodních podmínek, ještě před objednáním zboží.</p>
        <p>Uzavřením kupní smlouvy a převzetím zboží od prodávajícího kupující souhlasí s tímto Reklamačním řádem.</p>
        <p>Definice pojmů obsažené v tomto Reklamačním řádu mají přednost před definicemi uvedenými v odstavcích  I.-X. ve VOP. Pokud tento Reklamační řád pojem nedefinuje, chápe se ve významu, v jakém je definován ve VOP. Pokud není definován ani tam, chápe se ve významu, v jakém jej užívají právní předpisy.</p>
        <p>Jako doklad o záruce (záruční list) vystavuje Goldmax.cz ke každému zakoupenému zboží nákupní doklad (faktura, nebo prodejka – dále jen záruční list) se zákonem uvedenými všemi potřebnými údaji pro uplatnění záruky (zejm. název zboží, délka záruky, cena, množství, sériové číslo apod).</p>
        <p>Na žádost Kupujícího Goldmax.cz poskytne záruku písemnou formou (záruční list). Záruční list obsahuje obchodní firmu Goldmax.cz, jeho identifikační číslo a sídlo. Standardně však, umožňuje-li to povaha věci, vydává prodávající namísto záručního listu Kupujícímu doklad o zakoupení věci obsahující uvedené údaje.</p>
        <p>Pokud je to s ohledem na poskytovanou záruku potřebné, Goldmax.cz v záručním listě srozumitelným způsobem vysvětlí obsah poskytované záruky, uvede její rozsah, podmínky, dobu platnosti a způsob, jakým je možno uplatnit nároky z ní plynoucí. V záručním listu Goldmax.cz zároveň uvede, že poskytnutím záruky nejsou dotčena práva kupujícího, která se ke koupi věci váží.</p>
        <p>rodloužená záruka – tato záruka musí být vždy v souladu se záručními podmínkami danými výrobcem a s jakoukoli související reklamou, přičemž v takovém případě záruční list vždy obsahuje shora uvedené náležitosti a je vydán v souladu s výše uvedenými podmínkami.</p>
        
        <h1>II. Délka záruky</h1>
        <p>Záruční doba začíná běžet zásadně dnem převzetí zboží Kupujícím, tj. dnem uvedeným na záručním listě.</p>
        <p>Při prodeji spotřebního zboží je zákonná záruční doba 24 měsíců, nestanoví-li zvláštní právní předpis lhůtu delší. Výjimku může tvořit zboží prodávané se slevou (zboží poškozené, použité, nekompletní atd. – taková charakteristika je vždy uvedena u zboží – pokud není, má se za to, že se jedná o zboží nové, nepoškozené a kompletní). Jde-li o věci použité, neodpovídá Goldmax.cz za vady odpovídající míře používání nebo opotřebení, které měla věc při převzetí kupujícím, a práva z odpovědnosti za vady věci zaniknou, nebyla-li uplatněna do 12 měsíců ode dne převzetí věci Kupujícím. Tuto dobu uvede Goldmax.cz v dokladu o prodeji věci a dostatečně výrazně i u nabízeného zboží a v potvrzení objednávky. U věcí prodávaných za nižší cenu z důvodu vady či nekompletnosti se záruka nevztahuje na vady, pro které byla nižší cena sjednána.</p>
        <p>Goldmax.cz může tuto zákonnou lhůtu prodloužit. Délka záruční doby je vždy vyznačena na záručním listě (ve sloupci záruka) nebo na daňovém dokladu. Záruční doba se skládá ze zákonné lhůty (24 měsíců) a případně prodloužené záruční lhůty. Záruční doba se dále prodlužuje o dobu, po kterou bylo zboží v reklamaci. Práva z odpovědnosti za vady zboží, pro které platí záruční doba, zaniknou, nebyla-li uplatněna v záruční době.</p>
        <h5>Rozsah záruky výrobce notebooků EUROCOM Corporation</h5>
        <table className='table table-striped' style={{ marginTop: '16px' }}>            
            <tr>
                <th>COMPONENT</th>
                <th>1st Year</th>
                <th>2nd Year</th> 
                <th>3rd Year</th> 
            </tr>                         
            <tr>
                <td>LCD PANEL</td>
                <td>YES</td>
                <td>YES</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>FLOPPY DRIVE</td>
                <td>YES</td>
                <td>YES</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>HARD DRIVE</td>
                <td>YES</td>
                <td>YES</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>PROCESSOR</td>
                <td>YES</td>
                <td>YES</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>CPU FAN</td>
                <td>YES</td>
                <td>YES</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>RAM</td>
                <td>YES</td>
                <td>YES</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>CD-ROM DRIVE</td>
                <td>YES</td>
                <td>YES</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>BATTERY</td>
                <td>6 MONTHS*</td>
                <td>NO</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>AC ADAPTER</td>
                <td>YES</td>
                <td>YES</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>LCD PC POWER SUPPLY</td>
                <td>YES</td>
                <td>YES</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>LCD PC POWER SUPPLY FAN</td>
                <td>YES</td>
                <td>NO</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>MOTHERBOARD</td>
                <td>YES</td>
                <td>YES</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>KEYBOARD</td>
                <td>YES</td>
                <td>YES</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>INVERTOR BOARD</td>
                <td>YES</td>
                <td>YES</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>DATA BOARD</td>
                <td>YES</td>
                <td>YES</td>
                <td>YES</td>
            </tr>
            <tr>
                <td>ACCESSORIES</td>
                <td>YES</td>
                <td>NO</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>PLASTIC PARTS (excluding normal wear and tear and physical damage)</td>
                <td>YES</td>
                <td>YES</td>
                <td>NO</td>
            </tr>
            <tr>
                <td>LABOR</td>
                <td>YES</td>
                <td>YES</td>
                <td>YES</td>
            </tr>            
        </table>
        <p>*U baterií, jež jsou součástí notebooku, se rozlišují dva pojmy „funkčnost baterie“ a „ životnost baterie“. Notebook musí po dobu 24 měsíců od přijetí Kupujícím s nabitou baterii nastartovat, doba výdrže notebooku na baterii se však odvíjí od počtu nabíjecích cyklů a dalších externích vlivů a předpokládá se její postupné snižování. Pokud notebook není možné po dobu 24-měsíců s baterii nastartovat, pak je jednoznačně porušena funkčnost baterie a Prodávající je povinen reklamaci uznat.</p>
        <p>U prodloužené lhůty se reklamace řídí výhradně tímto Reklamačním řádem.</p>

        <h1>III. Záruční podmínky</h1>
        <p>I. Kupujícímu je doporučeno, aby bezprostředně při dodání překontroloval spolu s dopravcem stav zásilky (počet balíků, neporušenost pásky s firemním logem, poškození krabice) podle přiloženého přepravního listu. Kupující je oprávněn odmítnout převzetí zásilky, která není ve shodě s kupní smlouvou, s tím, že zásilka je např. neúplná nebo poškozená. Pokud takto poškozenou zásilku kupující od přepravce převezme, je nezbytné poškození popsat v předávacím protokolu přepravce.</p>
        <p>II. Neúplnou nebo poškozenou zásilku je nutno neprodleně oznámit e-mailem na adresu: info@goldmax.cz, sepsat s dopravcem škodní protokol a tento bez zbytečného odkladu zaslat faxem, e-mailem, nebo poštou prodávajícímu. Dodatečná reklamace neúplnosti nebo vnějšího poškození zásilky nezbavuje Kupujícího práva věc reklamovat, dává však Goldmax.cz možnost prokázat, že se nejedná o rozpor s kupní smlouvou.</p>
        <p>III. Kupující může zboží reklamovat osobně ve všech pobočkách prodávajícího, nebo odeslat poštou na adresu  GOLDMAX.CZ – reklamace, Ing. Ivana Šperková, Dolany 616, 783 16, Dolany. Je-li však v záručním listě zboží uveden autorizovaný servis, který je v místě prodávajícího nebo v místě pro kupujícího bližším, uplatní Kupující právo v autorizovaném servisním středisku.</p>
        <p>IV. Kupující může vadné zboží na reklamaci zaslat přepravní službou na adresu provozovny prodávajícího nebo autorizovaného servisu. Reklamované zboží by mělo být důkladně zabezpečeno, aby nedošlo v průběhu přepravy k jeho poškození, balík by měl být označen viditelně „REKLAMACE“ a obsahovat: reklamované zboží (včetně kompletního příslušenství), doporučujeme přiložit kopii nákupního dokladu, podrobný popis závady a dostatečné kontaktní údaje Kupujícího (zejm. zpáteční adresa a tel. číslo). Bez výše uvedeného je znemožněna identifikace původu i závady zboží. Tento postup je doporučen i Kupujícímu-spotřebiteli, pokud neprokáže a nedoloží uvedené skutečnosti jinak. Taktéž doporučujeme zvolit si požadovaný způsob vyřízení reklamace (viz níže).</p>
        <p>V. Kupující-podnikatel doloží platnost záruky předložením nákupního dokladu, pokud bylo v minulosti zboží reklamováno, též doloží doklad o reklamaci. Na nabývacím dokladu (nákupní nebo reklamační doklad) musí být stejné sériové číslo, jako na reklamovaném výrobku (pokud výrobek sériové číslo má). Tento postup je doporučen i Kupujícímu-spotřebiteli, pokud neprokáže a nedoloží uvedené skutečnosti jinak.</p>
        <p>VI. Goldmax.cz negarantuje plnou kompatibilitu prodaných součástí s jinými, pracovníky Goldmax.cz neschválenými součástmi, ani se softwarovými aplikacemi, jejichž funkčnost nebyla Kupujícím výslovně požadována v písemné objednávce, ledaže je taková kompatibilita u podobného zboží obvyklá a nebylo-li Goldmax.cz u zboží výslovně uvedeno, že je dané zboží kompatibilní pouze s uvedeným seznamem či není kompatibilní s uvedeným seznamem.</p>
        <p>VII. Záruka se nevztahuje na vady vzniklé použitím nesprávného nebo vadného programového vybavení, nesprávného spotřebního materiálu, ani na případné škody v důsledku toho vzniklé, pokud takové použití není obvyklé a přitom nebylo vyloučeno v přiloženém návodu k použití. Dále se záruka nevztahuje na závady vzniklé špatnou obsluhou, neodborným, nebo nepřiměřeným zacházením, použitím a instalací, které jsou v rozporu s uživatelskou příručkou, nebo poškozením účinky přepětí v rozvodné síti (např. bleskem) s výjimkou běžných odchylek.</p>
        <p>VIII. Goldmax.cz negarantuje plnou funkčnost aplikačního software ve verzích, které nejsou vhodné (nejsou vytvořeny) pro objednaný operační systém, ledaže je taková funkčnost u podobného zboží obvyklá a nebylo-li prodávajícím u zboží výslovně uvedeno, že je dané zboží funkční pouze s uvedeným seznamem či není funkční s uvedeným seznamem. Za případné problémy způsobené omezenou funkčností aplikací, které tuto podmínku nesplňují, nepřebírá Goldmax.cz žádnou zodpovědnost.</p>
        <p>IX. Porušením ochranné pečeti, informativní nálepky či sériového čísla se Kupující vystavuje riziku zamítnutí reklamace, ledaže k poškození dojde při obvyklém používání. Pečeti a sériová čísla jsou nedílnou součástí produktu a nijak neomezují právo zákazníka zboží užívat a manipulovat s ním v plném rozsahu toho, k čemu je zboží určeno.</p>
        <h5>Dále se záruka nevztahuje na poškození vzniklá (v případě, že taková činnost není činností obvyklou a přitom není zakázána v přiloženém návodu k použití):</h5>
        <ul>
            <li>mechanickým poškozením zboží,</li>
            <li>elektrickým přepětím (viditelně spálené součástky nebo plošné spoje) s výjimkou běžných odchylek,</li>
            <li>používáním zboží v podmínkách, které neodpovídají svojí teplotou, prašností, vlhkostí, chemickými a mechanickými vlivy prostředí, které je přímo prodejcem nebo výrobcem určeno,</li>
            <li>neodbornou instalací, zacházením, obsluhou, nebo zanedbáním péče o zboží,</li>
            <li>pokud bylo zboží nebo jeho část poškozeno počítačovým virem,</li>
            <li>pokud se závada projevuje pouze u software, u kterého není zákazník schopen doložit legální způsob nabytí, nebo použitím neautorizovaného software a spotřebního materiálu,</li>
            <li>zboží bylo poškozeno nadměrným zatěžováním nebo používáním v rozporu s podmínkami uvedenými v dokumentaci nebo všeobecnými zásadami,</li>
            <li>provedením nekvalifikovaného zásahu či změnou parametrů,</li>
            <li>zboží, které bylo upravováno zákazníkem (nátěry, ohýbání atd.), vznikla-li vada v důsledku této úpravy,</li>
            <li>při chybně provedeném upgrade BIOSu, firmware,</li>
            <li>zboží bylo poškozeno přírodními živly nebo vyšší mocí.</li>
        </ul>
        <p>Tato omezení neplatí, pokud byly vlastnosti zboží, jež jsou v rozporu s výše uvedenými podmínkami, kupujícím a Goldmax.cz výslovně dohodnuty, vymíněny nebo prodávajícím deklarovány, případně je lze očekávat vzhledem k prováděné reklamě nebo obvyklému způsobu užití zboží.</p>
        <p>X. V případě, že zboží je software, vztahuje se záruka výhradně na fyzickou čitelnost médií (média nesmí být poškrábána apod.). Okamžikem odstranění ochranných prostředků (fólie, pečeti, otevření obálky apod.) se Kupující stává oprávněným uživatelem softwarového produktu a akceptuje licenční ujednání výrobce software, u digitálního obsahu je to již samotným stažením. To neplatí pro rozpor s kupní smlouvou (viz níže).</p>
        <p>XI. Zboží předané k reklamaci bude testováno pouze na závadu uvedenou Kupujícím (v reklamačním formuláři, v přiloženém listě s popisem závady). Pro uvedení závady doporučujeme písemnou formu.</p>
        <p>XII. Zjistí-li technik, že příčinou problémů není reklamovaný výrobek (např. počítač), ale nekorektní instalace software (operační systém, antivirový program), pokud byla data poškozena nekorektním chováním některé aplikace (např. hry, viry) nedodané prodávajícím, nebo jestliže data poškodil kupující, nebo třetí osoba, bude reklamace zamítnuta. V případě, že Kupující souhlasí s placenou opravou, bude účtována dle aktuálního platného ceníku. Před provedením placené opravy bude kupující informován o ceně opravy, jejím rozsahu a době, nutné k jejímu provedení. K provedení placené opravy lze přistoupit jen po výslovném souhlasu Kupujícího (resp. na základě uzavřené servisní smlouvy) učiněném po informování dle předchozí věty.</p>
        <p>XIII. Při předání počítačového systému nebo zařízení pro ukládání dat k opravě, doporučujeme Kupujícímu zabezpečit si vytvoření odpovídající zálohy potřebných dat a zabránit jejich možnému zneužití nebo poškození. Goldmax.cz neručí za případnou ztrátu, poškození či zneužití dat, která jsou uložena na zařízeních pro ukládání dat v počítačovém systému.</p>
        <p>XIV. Zařízení pro ukládání dat jsou zařízení, jejichž poruchovost je objektivním jevem s náhodným charakterem výskytu poruch. Goldmax.cz upozorňuje tímto kupujícího na uvedenou skutečnost a doporučuje provádět systematické zálohování uživatelských dat na vhodném zařízení (např. ZIP, CD). Záruka však tímto upozorněním není omezena.</p>
        <p>XV. Goldmax.cz má právo odmítnout přijetí zboží do reklamace v případech, kdy reklamované zboží a/nebo jeho součásti jsou znečištěné nebo nesplňují základní předpoklady pro hygienicky bezpečné předání zboží k reklamačnímu řízení, ledaže se jedná o znečistění obvyklé.</p>
        <h4>LCD displej</h4>
        <p>Norma ISO 13406-2 – Jelikož se každý LCD displej skládá z minimálně 2,35 milionů subpixelů nebo tranzistorů (15″), není z hlediska výrobních technologií prakticky možné předcházet ztrátám pixelů. Do prodeje mohou být dány LCD s maximálně deseti vadnými pixely.</p>
        <h5>Norma stanovuje v souvislosti s vadnými pixely následující třídy:</h5>
        <p>Jsou rozděleny do čtyř tříd, tří typů vadných pixelů a dvou frekvenčních kritérií. Vadné pixely se mohou vyskytnout jako zářivé pixely, černé pixely nebo ztráta subpixelů (nebo blikající pixely). Frekvenční kritéria rozlišují zaprvé počet bílých nebo černých pixelů vyskytujících se v konkrétní oblasti (klastru) a zadruhé ztráty subpixelů nebo blikající pixely vyskytující se v klastru. Čtyři třídy určují maximální povolený počet chyb u každého typu vadných pixelů. LC displeje značky ADI, SONY, EIZO a většina dalších LCD na našem trhu jsou zařazeny do třídy II. To znamená, jestliže je počet těchto vadných bodů menší než deset, (2xTyp1, 2xTyp2 a 5xTyp3 = 2 černé body + 2 bílé body + 5 barevných bodů = max. 9 vadných), nevzniká zákazníkovi nárok na reklamaci či uplatnění práva z důvodu rozporu s kupní smlouvou (viz níže). V případě většího počtu vadných pixelů kontaktujte autorizovaný servis Vašeho LCD, případně prodejce.</p>

        <h1>IV. Vyřízení reklamace</h1>
        <p>Je-li Kupující-spotřebitel, o reklamaci Goldmax.cz rozhodne ihned, ve složitých případech do tří pracovních dnů. Do této lhůty se nezapočítává doba přiměřená podle druhu výrobku či služby potřebná k odbornému posouzení vady. Reklamaci včetně odstranění vady Goldmax.cz vyřídí bez zbytečného odkladu, nejpozději do 30 dnů ode dne uplatnění reklamace. Lhůtu 30 dnů je možné po uplatnění reklamace prodloužit po domluvě se spotřebitelem – takové prodloužení nesmí být na dobu neurčitou nebo nepřiměřeně dlouhou. Po uplynutí této lhůty se má za to, že vada na věci skutečně existovala a spotřebitel má stejná práva, jako by se jednalo o vadu, kterou nelze odstranit. Tato lhůta není závazná ke Kupujícímu-podnikateli, kdy o reklamace bude rozhodnuto nejpozději do 40 dnů ode dne uplatnění reklamace.</p>
        <p>Goldmax.cz vydá Kupujícímu-spotřebiteli písemné potvrzení o tom, kdy byla reklamace uplatněna, co je jejím obsahem, jaký způsob vyřízení reklamace je požadován, a to emailem bezprostředně po přijetí reklamace (v případě osobního uplatnění je předáno ihned); dále potvrzení o datu a způsobu vyřízení reklamace, včetně potvrzení o provedení opravy a době trvání reklamace, případně odůvodnění zamítnutí reklamace.</p>
        <p>Kupující má právo na úhradu nutných nákladů (zejména poštovného, které uhradil při odeslání reklamovaného zboží), které mu vznikly v souvislosti s uplatněním oprávněných práv z odpovědnosti za vady (doporučujeme požádat nejdéle do 30 dnů po vyřízení reklamace – zákonná lhůta tím není dotčena) a byly vynaloženy skutečně a účelně. V případě odstoupení od smlouvy z důvodu vady věci má spotřebitel také právo na úhradu nákladů k tomuto odstoupení.</p>
        <p>Po vyřízení oprávněné reklamace se záruční doba prodlužuje o dobu trvání reklamace. V případě neoprávněné reklamace se záruční doba neprodlužuje. Byla-li reklamace zboží v zákonné záruční lhůtě vyřízena výměnou zboží za nové, záruční doba se prodlužuje o dobu trvání reklamace. Doba trvání reklamace se počítá od následujícího dne po uplatnění reklamace do dne vyřízení reklamace, tedy doby, kdy byl Kupující povinen věc převzít. O vyřízení je zákazník informován e-mailem, který uvedl již při nákupu.</p>
        <p>Po vyřízení reklamace Goldmax.cz upozorní o ukončení reklamace kupujícího buď telefonicky, SMS, nebo e-mailem. Pokud bylo zboží zasláno přepravní službou, bude po vyřízení automaticky zasláno na adresu Kupujícího.</p>
        <p>V případě, že si kupující nevyzvedne zboží po skončení reklamačního řízení, postupuje prodávající v souladu s § 2609 zákona číslo 89/2012 Sb., v platném znění, tj. po uplynutí zákonné lhůty k opravě je oprávněn účtovat kupujícímu skladné. Naše společnost má nárok požadovat skladné ve výši: 490,- Kč bez DPH/denně.</p>
        <p>Při výdeji zboží po vyřízení reklamace je Kupující povinen předložit doklad, na základě kterého byla věc přijata do reklamace a musí prokázat svoji totožnost OP nebo platným cestovním pasem.</p>
        
        <h1>V. Spotřební materiál</h1>
        <p>Je-li obsahem balení či předmětem koupě spotřební materiál (např. cartridge, toner, tisková hlava, lampa projektoru, baterie), bývá jeho obvyklá životnost 6 měsíců, není-li výslovně uvedeno jinak. V případě sluchátek bývá životnost vždy 6 měsíců. Výjimkou jsou sluchátka, jež byla zakoupena jako samostatný produkt.</p>
        <p>Konkrétně u lampy projektoru jsou uváděny dvě doby obvyklé životnosti – v měsících a zároveň v počtu hodin svícení lampy. Pro úspěšnou reklamaci potenciální vady, musí být splněny obě tyto podmínky. Jednotlivé obvyklé životnosti se liší dle výrobce a tak zde uvádíme aktuální seznam obvyklých životností lamp k jednotlivým typům projektorů:</p>
        <p>
            HITACHI – 6 měsíců / 2000 hodin<br />
            EPSON – 3 měsíce / 500 hodin<br />
            ACER – 6 měsíců / 2000 hodin<br />
            BENQ – 3 měsíce / 2000 hodin (u produktů koupených před 1.7.2005) – 6 měsíců / 2000 hodin (u produktů koupených po 1.7.2005)<br />
            HEWLETT-PACKARD – řady 63XX, 7XXX a 90XX 12 měsíců / 4000 hodin – ostatní řady 3 měsíce / 1000 hodin<br />
            NEC – 6 měsíců / 1000 hodin<br />
            IBM – 3 měsíce<br />
        </p>
        <p>Právo Kupujícího reklamovat zboží v zákonné záruční lhůtě tím není dotčeno. Kupující však musí přihlížet k výše uvedeným skutečnostem, neboť záruka se nevztahuje na opotřebení způsobené obvyklým užíváním věci a nelze ji tak zaměňovat se životností výrobku. Životnost zboží představuje náchylnost k opotřebení způsobené obvyklým užíváním. Pokud zboží používáte (nikoliv vlastníte) déle než je obvyklá životnost, je pravděpodobné, že vzniknuvší vada je důsledkem běžného opotřebení, avšak není vyloučeno, že se skutečně jedná o záruční vadu. Goldmax.cz vždy v odůvodnění případného zamítnutí reklamace uvede, zda se jednalo o vadu způsobenou obvyklým opotřebením.</p>
        <p>Výše uvedená obvyklá živostnost je Goldmax.cz vždy uvedena i u nabízeného zboží, jinak se nemá možnost této živostnosti dovolávat.</p>

        <h1>VI. Bezplatný servis</h1>
        <p>Na produkty prodávané s delší zárukou než 24 měsíců je zajišťován po uplynutí lhůty 24 měsíců tzv. bezplatný servis. V případě vady zboží po lhůtě 24 měsíců bude takový produkt přijat do servisu a bude opraven nebo vyměněn za jiný kus se stejnými nebo lepšími parametry. Pokud ani jedno z těchto řešení nebude možné, bude vystaven dobropis. Prodávající se zavazuje takovýto servis ukončit ve lhůtě 35 dnů od přijetí zboží – po uplynutí této lhůty má kupující právo od smlouvy odstoupit.</p>

        <h1>VII. Závěrečná ustanovení</h1>
        <p>
            Tento reklamační řád je platný od 1. 1. 2014 a ruší platnost předchozích reklamačních řádů.<br />
            Tento reklamační řád je k dispozici v sídle Goldmax.cz nebo jako dokument na www.Goldmax.cz<br />
        </p>
    </div>
}
import React, { createContext, useState } from "react";

export const AppStateContext = createContext([undefined, () => { }]);

export const AppStateProvider = ({ children }) => {
    //let count = 0;
    let keys = Object.keys(localStorage);
    /* STARY ZPUSOB RESIL JEN CELKOVY POCET POLOZEK V LOKALNIM ULOZISTI - (NELZE POUZIT) APPLE SAFARI SI UKLADA VLASTNI DATA DO LOKALNIHO ULOZISTE 
    keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();       
    */    
    keys = keys.filter(x => x.includes("gmscitem")).sort();       
    const appState = useState({ scart: keys.length, currency: {}, footer: true });

    return (
        <AppStateContext.Provider value={appState}>
            {children}            
        </AppStateContext.Provider>
    );
}


//export const AppStateContext = createContext(undefined);
//export const AppStateDispatchContext = createContext(undefined);

//export const AppStateProvider = ({ children }) => {
//    const [appState, setAppState] = useState({ scart: localStorage.length, currency: {} });

//    return (
//        <AppStateContext.Provider value={appState}>
//            <AppStateDispatchContext.Provider value={setAppState}>
//                {children}
//            </AppStateDispatchContext.Provider>
//        </AppStateContext.Provider>
//    );
//}
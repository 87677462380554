import React from 'react';
import SEO from './SEO';

export const Eurocom = () => {
    return (
        <>
            {SEO({ title: "EUROCOM EUROPE | Notebooky na míru | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
            <h1>EUROCOM EUROPE</h1>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>Evropský e-shop EUROCOM</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://www.eurocom-europe.com/" target="_blank" rel="noopener noreferrer">
                    https://www.eurocom-europe.com/
                </a>
            </p>
        </>
    );
};
import React, { Component } from 'react';
import Loader from './Loader';
import './NotebooksOverview.css';
import { Route } from 'react-router-dom';
import SEO from './SEO';
import { ErrorNotFound } from './ErrorNotFound';
//import { AppStateContext } from './AppStateContext'
import { GetCurrency } from './Configurator'
import { AppStateContext } from './AppStateContext'
export class NotebooksOverview extends Component {
    static displayName = NotebooksOverview.name;
    static contextType = AppStateContext;

    constructor(props) {
        super(props);        
        this.state = { notebooks: null, loading: true, scmodel: null };
        this.handleConfigButton = this.handleConfigButton.bind(this); 
        this.handleDetail = this.handleDetail.bind(this); 
        this.handleShoppingCart = this.handleShoppingCart.bind(this); 

        this.type = -1;
        /*if (typeof this.props.location.state != 'undefined') {
            this.type = this.props.location.state.type;            
        }*/

        /*
        const { type } = this.props.match.params;       
        switch (type) {
            case "top":
                this.type = 0;
                break;
            case "workstation":
                this.type = 1;
                break;
            case "server":
                this.type = 2;
                break;
            case "enterprise":
                this.type = 3;
                break;
            case "gaming":
                this.type = 4;
                break;
            case "configured":
                this.type = 99;
                break;
            default:
                this.type = -1;
                break;
        }
        */

        const { pathname } = this.props.location;
        switch (pathname) {
            case "/top-notebooky": //"top":
                this.type = 0;
                break;
            case "/pracovni-stanice": //"workstation":
                this.type = 1;
                break;
            case "/mobilni-servery": //"server":
                this.type = 2;
                break;
            case "/korporatni-laptopy": //"enterprise":
                this.type = 3;
                break;
            case "/herni-notebooky": //"gaming":
                this.type = 4;
                break;
            case "/notebooky-skladem": //"configured":
                this.type = 99;
                break;
            default:
                this.type = -1;
                break;
        }
    }
    handleConfigButton(e) { 
        e.stopPropagation();
        const { id } = e.target; // Predan nazev url notebooku (id)
        //this.props.history.replace('/configurator', { id: id, type: this.type });    - HACK PROHLIZECE - TLACITKO Z5
        //this.props.history.push('/configurator', { id: id, type: this.type });
        this.props.history.push('/konfigurace-notebooku/' + id, { type: this.type });
        window.scrollTo(0, 0);
    }
    handleDetail(e) {
        const { id } = e.target; // Predan nazev url notebooku (id)
        this.props.history.push('/' + id, { type: this.type });
        window.scrollTo(0, 0);
    }
    async getConfiguratorAsync(id) {
        const requestOptions = { method: 'GET', 'Content-Type': 'application/json' };
        const response = await fetch('configurator/' + id, requestOptions);
        const data = await response.json();
        this.setState({ scmodel: data, loading: false });
    }
    async handleShoppingCart(e) {
        e.stopPropagation();
        this.setState({ loading: true });
        const { id } = e.target; // ZDE JE TO ID NOTEBOOKU
        await this.getConfiguratorAsync(id);
        let keys = Object.keys(localStorage);

        /* Prideleni id notebooku v lokalnim ulozisti - prefix a celkovy pocet plus 1 */

        /* STARY ZPUSOB RESIL JEN CELKOVY POCET POLOZEK V LOKALNIM ULOZISTI - (NELZE POUZIT) APPLE SAFARI SI UKLADA VLASTNI DATA DO LOKALNIHO ULOZISTE 
        keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();
        localStorage.setItem(keys.length, JSON.stringify(this.state.scmodel));
        */
        //let keys1 = keys.filter(x => x.includes("gmscitem"));
        //let keys2 = keys.filter(x => x.includes("gmscitem")).sort();


        keys = keys.filter(x => x.includes("gmscitem")).sort();
        var tempModel = this.state.scmodel;
        tempModel.notebook.detail = null;

        localStorage.setItem(`gmscitem-${keys.length}`, JSON.stringify(tempModel));
        /* ZBYTECNE - NEMELO BY NASTAT TO ZE PRIBYDE VICE JAK JEDEN PRVEK 
         * keys = Object.keys(localStorage);
        keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();
        this.context[1]((prevState) =>
            ({ ...prevState, scart: keys.length })
        );
        */
        this.context[1]((prevState) =>
            ({ ...prevState, scart: keys.length + 1 })
        );
        this.props.history.push('/nakupni-kosik');
        window.scrollTo(0, 0);
    }

    componentDidMount() {          
        this.getNotebooksAsync();        
    }
    componentWillReceiveProps(props) {        
        /*this.type = props.location.state.type;*/
        /*
        const { type } = props.match.params;
        switch (type) {
            case "top":
                this.type = 0;
                break;
            case "workstation":
                this.type = 1;
                break;
            case "server":
                this.type = 2;
                break;
            case "enterprise":
                this.type = 3;
                break;
            case "gaming":
                this.type = 4;
                break;
            case "configured":
                this.type = 99;
                break;
            default:
                this.type = -1;
                break;
        }
        */
        const { pathname } = props.location;
        switch (pathname) {
            case "/top-notebooky": //"top":
                this.type = 0;
                break;
            case "/pracovni-stanice": //"workstation":
                this.type = 1;
                break;
            case "/mobilni-servery": //"server":
                this.type = 2;
                break;
            case "/korporatni-laptopy": //"enterprise":
                this.type = 3;
                break;
            case "/herni-notebooky": //"gaming":
                this.type = 4;
                break;
            case "/notebooky-skladem": //"configured":
                this.type = 99;
                break;
            default:
                this.type = -1;
                break;
        }
        this.setState({ notebooks: null, loading: true });
        this.getNotebooksAsync();       
    }
    static renderDescription(notebook) {
        let description = notebook.description + "";
        let descriptions = [];        
        if (typeof description !== 'undefined' && description != "") {
            descriptions = description.split(";");
        }
        return (            
            descriptions.map(desc =>
                <div id={notebook.urlName} key={notebook.id}>{desc}</div>
            )            
        );
    }
    
    static renderNotebooksItems(_this) {
        return (
            _this.state.notebooks.map(notebook => (
                <React.Fragment>                    
                    <div className="item" id={notebook.urlName} key={notebook.id} onClick={_this.handleDetail}>
                        <img id={notebook.urlName} key={notebook.id} className="item-img" src={notebook.notebookImages ? notebook.notebookImages[0].link : ""}/>
                        <h3 id={notebook.urlName} key={notebook.id}>{notebook.name}</h3>
                        <div id={notebook.urlName} key={notebook.id} className="price-container">
                            <span id={notebook.urlName} key={notebook.id} className="price-text">CENA</span>
                            {_this.type == 99 /*KONFIGUROVATELNY VS NEKON....*/
                                ? 
                                    notebook.discountPrice != 0
                                    ? <>
                                        
                                        <div id={notebook.urlName} key={notebook.id} class="price-value">
                                            <span className="overview-canceled-price"><GetCurrency value={notebook.price} withoutCurrencySymbol={true} /></span>
                                            <span className="price-value overview-discount-price"><GetCurrency value={notebook.discountPrice} /></span>
                                        </div>                                        
                                        <span className="overview-discount-note"><b>{notebook.discountNote}</b></span>                                        
                                        </>
                                    : <span id={notebook.urlName} key={notebook.id} class="price-value"><GetCurrency value={notebook.price} /></span>
                                
                                : <span id={notebook.urlName} key={notebook.id} class="price-value">od <GetCurrency value={notebook.price} /></span>
                            }
                        </div>

                        <div id={notebook.urlName} key={notebook.id} className="item-text-container">
                            {NotebooksOverview.renderDescription(notebook)}
                        </div>  
                        {_this.type == 99 /*KONFIGUROVATELNY VS NEKON....*/
                            ? <button class="main-button rounded" id={notebook.id} key={notebook.id} onClick={_this.handleShoppingCart}>Do košíku</button>
                            : <button class="main-button rounded" id={notebook.urlName} key={notebook.id} onClick={_this.handleConfigButton}>Konfigurovat</button>
                        }                                                                                              
                    </div>
                </React.Fragment>
            ))
        );
    }

    render() { 
        /*if (typeof this.props.match.params == 'undefined') {
            return <Route path="*" component={ErrorNotFound} />
        }*/
        if (typeof this.props.match.params == 'undefined') {
            return <Route path="*" component={ErrorNotFound} />
        }
        let contents = this.state.loading
            ? <Loader loading={this.state.loading} />
            : NotebooksOverview.renderNotebooksItems(this);

        return (
            <React.Fragment>
                {this.getSEO(this.type)}
                <h1>{this.getHeader(this.type)}</h1>
                
                <div className="item-container">
                    {contents}
                </div>
                {this.getContent(this.type)}
                {this.type != 99 && Array.isArray(contents) && this.getImages(this.type)}                   
            </React.Fragment>
        ); 
    }

    async getNotebooksAsync() {        
        const requestOptions = { method: 'GET', 'Content-Type': 'application/json' };
        const response = await fetch('notebook/getall/' + this.type, requestOptions);
        const data = await response.json();
        this.setState({ notebooks: data, loading: false });
    }

    getSEO(type) {        
        switch (type) {
            case 0:
                return SEO({ title: "Nejvýkonnější notebooky | EUROCOM | Goldmax", description: "Výkonné pracovní notebooky na výpočty, grafiku, programování a střih videa.", siteUrl: "top-notebooky" });
            case 1:
                return SEO({ title: "Mobilní Pracovní stanice | EUROCOM | Goldmax", description: "Pracovní notebook na míru. Flexibilní a unikátní řešení.", siteUrl: "pracovni-stanice" });
            case 2:
                return SEO({ title: "Mobilní servery | EUROCOM | Goldmax", description: "Výkonné pracovní notebooky na výpočty, grafiku, programování a střih videa.", siteUrl: "mobilni-servery" });
            case 3:
                return SEO({ title: "Pracovní notebooky CAD | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení.", siteUrl: "korporatni-laptopy" });
            case 4:
                return SEO({ title: "Herní notebooky VR | EUROCOM | Goldmax", description: "Herní notebooky na grafiku, programování a střih videa.", siteUrl: "herni-notebooky" });
            case 99:
                return SEO({ title: "Skladové notebooky ihned dostupné | EUROCOM | Goldmax", description: "Super notebooky, pracovní stanice, prémiové herní notebooky", siteUrl: "notebooky-skladem" });
            default: return "";
        }
    }

    getHeader(type) {
        switch (type) {
            case 0:
                return "TOP notebooky";
            case 1:
                return "Pracovní stanice";
            case 2:
                return "Mobilní servery";
            case 3:
                return "Korporátní laptopy";
            case 4:
                return "Herní notebooky";
            case 99:
                return "Hotové sestavy";
            default: return "";
        }
    }
    getContent(type) {
        switch (type) {
            case 0:
                return <div className="paragraph-container">
                    <h2>Hledáte výkonný pracovní notebook pro náročnou úpravu fotografie, vývoj nebo střih videa 4K/8K?</h2>
                    <h2>Nejnovější profesionální notebook pro rendering objektů v Adobe SW, Catii V5 nebo 3D modelling?</h2>
                    <h2>Vyvíjíte software na notebooku, pracujete s náročnou 3D grafikou nebo se zabýváte PLC programováním?</h2>
                    <p>EUROCOM Vám postaví notebook na míru v konfigurátoru kategorie TOP notebooky, který společně postavíme, nakonfigurujeme a vyladíme k dokonalosti. Naše TOP notebooky jsou daleko za konkurencí skladových A brandových modelů, které leží předkonfigurované ve skladech a snaží se tvářit profesionálně. Notebook s RTX 3080 a Intel Core i9 dodáváme pravidelně.</p>
                    <p>Nejvýkonnější notebook na trhu dodává pravidelně EUROCOM, který dokážeme také uchladit v rámci rozumné úrovně hluku a nejvyšší řady osazujeme DESKTOPOVÝMI komponentami. Co to znamená? Jednoduše to, že plnohodotné komponenty ze stolních PC osazujeme do notebooků nejvyšších kategorií, které posouvají laťku výše a jsme tak 2-3 roky výkonově před konkurencí. 128GB RAM, až 32 jádrové procesory, 20TB SSD, nejvýkonnější grafiky a Windows Professional, to umíme a děláme.</p>
                    {this.whyBuyFromGoldmax()}
                </div>;
            case 1:
                return <div className="paragraph-container">
                    <h2>Hledáte výkonnou pracovní stanici pro úpravu fotografie, vývoj nebo střih videa 4K/8K?</h2>
                    <h2>Nejnovější profesionální notebook na modelování v CAD SW typu Solidworks, PLM software nebo BIM aplikaci pro architekty?</h2>
                    <h2>Je Vaší náplní práce renderování modelů nebo programujete na profesionální úrovní v LINUXu?</h2>
                    <p>EUROCOM Vám postaví notebook na míru v konfigurátoru kategorie Workstation, který společně postavíme, nakonfigurujeme a vyladíme k dokonalosti. Naše pracovní stanice EUROCOM jsou daleko za konkurencí skladových A brandových modelů, které leží předkonfigurované ve skladech a snaží se tvářit profesionálně.</p>
                    <p>Nejvýkonnější notebook na trhu dodává pravidelně EUROCOM, který dokážeme také uchladit v rámci rozumné úrovně hluku a nejvyšší řady osazujeme DESKTOPOVÝMI komponentami. Co to znamená? Jednoduše to, že plnohodotné komponenty ze stolních PC osazujeme do notebooků nejvyšších kategorií, které posouvají laťku výše a jsme tak 2-3 roky výkonově před konkurencí. 128GB RAM, až 32 jádrové procesory, 20TB SSD, nejvýkonnější grafiky a Windows Professional, to umíme a děláme. NVIDIA Quadro v notebooku či RTX 3080 osazujeme do notebooků pravidelně.</p>
                    {this.whyBuyFromGoldmax()}
                </div>;
            case 2:
                return <div className="paragraph-container">
                    {this.whyBuyFromGoldmax()}
                </div>;
            case 3:
                return <div className="paragraph-container">
                    {this.whyBuyFromGoldmax()}
                </div>;
            case 4:
                return <div className="paragraph-container">
                    <h2>Hledáte výkonný herní notebook pro náročné hraní?</h2>
                    <h2>Nejnovější notebook pro Virtuální realitu?</h2>
                    <h2>Vyvíjíte herní engine nebo pracujete s 3D grafikou?</h2>
                    <p>EUROCOM Vám postaví notebook na míru v konfigurátoru kategorie notebooků na hry, který společně postavíme, nakonfigurujeme a vyladíme k dokonalosti. Herní neznamená jen na hry, ale s výkonným hardwarem, který dokážeme také uchladit v rámci rozumné úrovně hluku. Kromě výkonné grafické karty se můžete těšit například na OLED displej, rychlé LCD s vysokou obnovovací frekvencí a vícejádrové procesory, které zážitek ze hraní ještě umocní.</p>
                    <h2>Chcete super extra výkonný herní notebook?</h2>
                    <p>Možná zkuste kategorii TOP notebooky, které představují ty nejvýkonnější notebooky na světě, které nabízejí ještě víc, například rozsáhlé možnosti upgrade, větší odolnost k dlouhodobé zátěži (rendering, výpočty..)</p>
                    {this.whyBuyFromGoldmax()}
                </div>;
            case 99:
                return <div className="paragraph-container">
                    {this.whyBuyFromGoldmax()}
                </div>;
            default: return "";
        }
    }

    whyBuyFromGoldmax() {
        return <>
            <h2>Proč nakoupit na Goldmax.cz?</h2>
            <p>90% zákazníků u nás nekupuje bezhlavě co se jim v konfigurátoru zlíbí, ale radí se s námi. Sdělte nám Váš rozpočet, zamýšlené užití notebooku, Vaše individuální požadavky (např. velikost, váhu, počet disků atd.) a my Vám notebook sestavíme dle našich mnohaletých zkušenosti tak, jako to neumí žádní skladové sestavy u konkurence. Kupujete pro někoho dalšího a nejste si volbou modelu a technické specifikace zcela jisti? Volejte <a href="tel:+420724583823">724 583 823</a> či pište na <a href="mailto:info@goldmax.cz">info@goldmax.cz</a> a poptávku spolu proměníme v realitu dokonalého notebooku ještě dnes.</p>
            <p>Notebooky také naskladňujeme na <a className="czc-link" target="_blank" href="https://www.czc.cz/eurocom/hledat?dostupnost=skladem">CZC.<span>CZ</span></a>.</p>
        </>;
    }

    getImages(type) {        
        switch (type) {
            case 0:
                return (
                    <div className="full-img-container">
                        <img className="full-img" src="images/ntb-categories/Top1.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top2.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top3.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top4.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top5.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top6.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top7.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top8.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top9.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Top10.jpg"></img>
                    </div>);
            case 1:
                return (
                    <div className="full-img-container">
                        <img className="full-img" src="images/ntb-categories/Workstation1.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Workstation2.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Workstation3.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Workstation4.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Workstation5.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Workstation6.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Workstation7.jpg"></img>

                    </div>);
            case 2:
                return (
                    <div className="full-img-container">
                        <img className="full-img" src="images/ntb-categories/Server1.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server2.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server3.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server4.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server5.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server6.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server7.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server8.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server9.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server10.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Server11.jpg"></img>                     
                    </div>);
            case 3:
                return (
                    <div className="full-img-container">
                        <img className="full-img" src="images/ntb-categories/Enterprise1.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Enterprise2.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Enterprise3.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Enterprise4.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Enterprise5.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Enterprise6.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Enterprise7.jpg"></img>

                    </div>);
            case 4:
                return (
                    <div className="full-img-container">
                        <img className="full-img" src="images/ntb-categories/Gaming1.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming2.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming3.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming4.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming5.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming6.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming7.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming8.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming9.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming10.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming11.jpg"></img>
                        <img className="full-img" src="images/ntb-categories/Gaming12.jpg"></img> 
                    </div>);
            default: return '';
        }
    }
}

import React, { useState } from 'react';
import { DynamicForm } from './DynamicForm';
import SEO from './SEO';

const formSubmitBtnName = 'ODESLAT';
const dialogHeader = 'Žádost o cenovou nabídku';
const successMessage = 'Váš požadavek byl odeslán, budeme Vás kontaktovat během následujícího pracovního dne.';
const errorMessage = 'Omlouváme se, ale Váš pořadavek se nepodařilo odeslat, kontaktujte nás prostřednictvím e-mailu nebo telefonu.';
const fields = [
    { prop: 'purpose', label: 'Jaký je účel notebooku?', placeholder: 'K čemu bude využíván, jaké Aplikace/SW na něm poběží', type: 'text', size: 12, validation: 'Zadejte prosím k čemu bude notebook využíván.', validationtype: 'required' },
    { prop: 'count', label: 'Kolik notebooků poptáváte, žádáte množstevní slevu?', placeholder: 'Počet, sleva pro firmu nebo do tenderu', type: 'text', size: 12, validation: 'Zadejte prosím alespoň počet.', validationtype: 'required' },
    { prop: 'req', label: 'Jaké požadavky na notebook kladete?', placeholder: 'Jaké máte priority a co vše by měl splňovat', type: 'text', size: 12, validation: '', validationtype: '' },
    { prop: 'name', label: 'Celé jméno', placeholder: 'Jméno a příjmení', type: 'text', size: 6, validation: 'Zadejte prosím Vaše celé jméno.', validationtype: 'required' },
    { prop: 'firm', label: 'Firma', placeholder: 'Název firmy', type: 'text', size: 6, validation: '', validationtype: '' },
    { prop: 'email', label: 'E-mail', placeholder: 'Váš e-mail', type: 'email', size: 6, validation: 'Zadejte prosím platnou emailovou adresu.', validationtype: 'email' },
    { prop: 'phone', label: 'Telefonní číslo', placeholder: 'Včetně předčíslí +420', type: 'text', size: 6, validation: 'Zadejte prosím platné telefonní číslo (+420123456789).', validationtype: 'phone' }    
];
let dynamicObject = {};
fields.map(item => dynamicObject[item.prop] = '');

export const QuotationRequest = () => {
    const [object, setObject] = useState(dynamicObject);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBody, setDialogBody] = useState('');    

    const handleObjectChange = e => {
        const { id, value } = e.target;
        setObject(prevObj => ({ ...prevObj, [id]: value }));
    };
    const onSubmit = () => {
        setLoading(true);
        sendFomrAsync();
    };
    const onCloseDialog = () => {
        setDialogOpen(false);
    }

    const sendFomrAsync = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        const response = await fetch('form?id=6', requestOptions);
        if (response.ok) {
            setDialogBody(successMessage);
            setObject(dynamicObject);
        }
        else {
            setDialogBody(errorMessage);
        }
        setLoading(false);
        setDialogOpen(true);
    }

    return (
        <>
            {SEO({ title: "Cenová nabídka notebooku | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
            <h1>Žádost o cenovou nabídku</h1>

            <p>Vážený zákazníku,</p>
            <p>abychom mohli společně zvolit pro Vás ideální řadu/model notebooku EUROCOM, žádáme Vás nyní o zodpovězení tří klíčových otázek.<br/><b>Notebooky</b> zákazníkům vždy „<b>stavíme na míru</b>“, proto je každý požadavek individuálně vyhodnocen tak, abychom sestavili právě Vašim požadavkům odpovídající dokonalou konfiguraci.</p>
           
            <DynamicForm fields={fields} object={object} submitBtnName={formSubmitBtnName} onChangeObject={handleObjectChange} onSubmitForm={onSubmit} loading={loading} dialogHeader={dialogHeader} dialogBody={dialogBody} dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />

            <p style={{ paddingTop: '24px' }}>Veškeré další dotazy směřujte na <a href="mailto:info@goldmax.cz">info@goldmax.cz</a> či telefon <a href="tel:+420724583823">+420 724 583 823</a>.</p>
        </>
    );
};
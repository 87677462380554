import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { AppStateProvider } from './AppStateContext'
import { Footer } from './Footer'
import './Layout.css'

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <AppStateProvider>                
                <NavMenu />
                <Container className="content-container">{this.props.children}</Container>
                <Footer />                    
            </AppStateProvider>
        );
    }
}

import React from 'react';
import SEO from './SEO';

export const Lib = () => {
    return (
        <>
            {SEO({ title: "Videa a návody | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
            <h1>Knihovna videí a návodů</h1>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>Knihovna EUROCOM.com</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://eurocom.com/ec/videolib(0)ec" target="_blank" rel="noopener noreferrer">
                    https://eurocom.com/ec/videolib(0)ec
                </a>
            </p>

            <h4 style={{ fontSize: '1.15em', fontWeight: 600 }}>Knihovna youtube kanálů EUROCOM</h4>
            <p>
                <a style={{ fontWeight: 500 }} href="https://www.youtube.com/user/EurocomTechnology" target="_blank" rel="noopener noreferrer">
                    https://www.youtube.com/user/EurocomTechnology
                </a>
            </p>
        </>
    );
};
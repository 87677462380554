import React, { useContext, useEffect, useState } from 'react';
/*import CartIcon from "./CartIcon";*/
import { Icon } from 'semantic-ui-react'
import { Menubar } from 'primereact/menubar';
import { useHistory } from "react-router-dom";
import { AppStateContext } from './AppStateContext'
//import { AppStateContext, AppStateDispatchContext } from './AppStateContext'
import './NavMenu.css';

import 'primereact/resources/themes/saga-orange/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';



//export function onCountChange() {  
//    var cartCount = localStorage.length;
//    var user = localStorage.getItem("currentUser");
//    if (user) {
//        cartCount--;
//    }    
//}


//const start = <img alt="logo" src="logo.png" height="40" className="p-mr-2"></img>;

//const history = useHistory();
//var user = localStorage.getItem("currentUser");
//if (user) {
//    setCartCount(cartCount--);
//}   
//const set = (count = 0) => {
//    setCartCount(count)
//}

//const start = <img style={{height: '48px', paddingRight: '24px'}} src="images/logo.png" />

export const NavMenu = () => {
    //const [cartCount, setCartCount] = useState(localStorage.length);
    const history = useHistory();
    const items = [
        {
            label: 'Produkty',
            items: [ 
                {
                    label: 'Skladem/Akce',
                    command: () => {                       
                        //history.push('/notebooks/configured');
                        history.push('/notebooky-skladem');
                    }
                },
                {
                    separator: true
                },
                {
                    label: 'TOP notebooky',
                    command: () => {
                    /*history.push('/notebooks', { type: 0 });*/
                        //history.push('/notebooks/top');
                        history.push('/top-notebooky');

                    }
                },
                {
                    label: 'Pracovní stanice',
                    command: () => {
                    /*history.push('/notebooks', { type: 1 });*/
                        //history.push('/notebooks/workstation');
                        history.push('/pracovni-stanice');
                    }
                },
                {
                    label: 'Mobilní servery',
                    command: () => {
                    /*history.push('/notebooks', { type: 2 });*/
                        //history.push('/notebooks/server');
                        history.push('/mobilni-servery');
                    }
                },
                {
                    label: 'Korporátní laptopy',
                    command: () => {
                    /*history.push('/notebooks', { type: 3 });*/
                        //history.push('/notebooks/enterprise');
                        history.push('/korporatni-laptopy');
                    }
                },
                {
                    label: 'Herní notebooky',
                    command: () => {
                    /*history.push('/notebooks', { type: 4 });*/
                        //history.push('/notebooks/gaming');
                        history.push('/herni-notebooky');
                    }
                }
            ]
        },
        {
            label: 'Technologie EUROCOM',
            items: [
                {
                    label: 'Proč EUROCOM?',
                    command: () => {
                        //history.push('/whyeurocom');
                        history.push('/proc-eurocom');
                    }
                },
                {
                    label: 'Případové studie a kam cílíme',
                    command: () => {
                        //history.push('/casestudies');
                        history.push('/pripadove-studie');
                    }
                },
                {
                    label: 'VR/AR Technologie',
                    command: () => {
                        //history.push('/vr');
                        history.push('/virtualni-realita');
                    }
                },
                {
                    label: 'Slevy pro studenty',
                    command: () => {
                        //history.push('/students');
                        history.push('/slevy-pro-studenty');
                    }
                },
                {
                    label: 'Multi GPU/Risers řešení',
                    command: () => {
                        //history.push('/multigpu');
                        history.push('/multi-gpu');
                    }
                },
                {
                    label: 'Recenze a ocenění',
                    command: () => {
                        //history.push('/review');
                        history.push('/recenze');
                    }
                },                

            ]
        },
        {
            label: 'Servis a Upgrade',
            items: [
                {
                    label: 'Servisní služby',
                    items: [
                        {
                            label: 'Čištění notebooku',
                            command: () => {
                                //history.push('/cleaning');
                                history.push('/cisteni-notebooku');
                            }
                        },
                        {
                            label: 'RMA reklamace',
                            command: () => {
                                //history.push('/rma');
                                history.push('/reklamace');
                            }
                        },
                        {
                            label: 'Objednávka svozu',
                            command: () => {
                                //history.push('/pickup');
                                history.push('/objednavka-svozu');
                            }
                        }                        
                    ]

                },
                {
                    label: 'Upgrade GPU a náhradní díly',
                    command: () => {
                        history.push('/upgrade');
                    }
                },
                {
                    label: 'Knihovna videí a návodů',
                    command: () => {
                        //history.push('/lib');
                        history.push('/videa-a-navody');
                    }
                },
                {
                    label: 'Ovladače ke stažení',
                    command: () => {
                        //history.push('/drivers');
                        history.push('/ovladace');
                    }
                },
            ]
        },
        {
            label: 'Podpora prodeje',
            items: [
                {
                    label: 'Žádost o cenovou nabídku',
                    command: () => {
                        //history.push('/quotationrequest');
                        history.push('/cenova-nabidka-notebooku');
                    }
                },
                {
                    label: 'Tendery/Veřejné zakázky',
                    command: () => {
                        //history.push('/tenders');
                        history.push('/tendery-verejne-zakazky');
                    }
                },
                {
                    label: 'Firemní testovací zápůjčka',
                    command: () => {
                        //history.push('/testlend');
                        history.push('/firemni-testovaci-zapujcka');
                    }
                }
            ]
        },
        {
            label: 'Kontakty',
            items: [
                {
                    label: 'Kontaktujte nás',
                    command: () => {
                        //history.push('/contact');
                        history.push('/kontakt');
                    }
                },
                {
                    label: 'EUROCOM EUROPE',
                    command: () => {                        
                        history.push('/eurocom');
                    }
                },
                {
                    label: 'Spolupráce',
                    command: () => {
                        //history.push('/cooperation');
                        history.push('/spoluprace');
                    }
                },
                {
                    label: 'Sociální sítě',
                    items: [
                        {
                            label: 'Facebook',
                            command: () => {
                                window.open('https://www.facebook.com/Goldmax.cz?ref=hl', '_blank');                                
                            }
                        },
                        {
                            label: 'Instagram',
                            command: () => {
                                window.open('https://www.instagram.com/goldmaxcz/', '_blank');
                            }
                        },
                        {
                            label: 'Youtube',
                            command: () => {
                                window.open('https://www.youtube.com/user/EurocomTechnology', '_blank');
                            }
                        }
                    ]
                }
            ]
        }
    ];

    const [appState, setAppState] = useContext(AppStateContext);

    //const { scart, currency } = useContext(AppStateContext);
    //const setAppState = useContext(AppStateDispatchContext);
    const [ currencies, setCurrencies ] = useState({});
    const end = <div className="menu-right-container"> 
        <div className="dropdown">
            <button className="dropbtn">{appState.currency === undefined ? "" : appState.currency.code}</button>
            <div className="dropdown-content">                
                {/*{Array.from(currencies).map(item => item.code != currency.code && <a onClick={() => { setAppState({ currency: item, scart: localStorage.length }) }}>{item.code}</a> )}*/}
                {Array.from(currencies).map((item, index) => item.code != appState.currency.code && <a key={index} onClick={() => { setAppState((prevState) => ({ ...prevState, currency: item })) }}>{item.code}</a>)}

            </div>
        </div>
        <div className="shoppingcart" onClick={() => history.push('/nakupni-kosik')}><span className="cart-icon"><Icon name='shopping bag' size='large' /></span><span className="cart-icon-number">{appState.scart}</span></div>
        </div>;

    //this.props.setGlobalState(prevGlobalState => ({
    //    counter: prevGlobalState.counter + 1
    //}))

    const getCurrenciesAsync = async () => {
        const requestOptions = { method: 'GET' };
        const response = await fetch('admin/getallcurrency', requestOptions);
        const data = await response.json();
        setCurrencies(data);
        let keys = Object.keys(localStorage);
        /* STARY ZPUSOB RESIL JEN CELKOVY POCET POLOZEK V LOKALNIM ULOZISTI - (NELZE POUZIT) APPLE SAFARI SI UKLADA VLASTNI DATA DO LOKALNIHO ULOZISTE 
        keys = keys.filter(x => x != "currentUser" && !x.includes("twk")).sort();        
        */        
        keys = keys.filter(x => x.includes("gmscitem")).sort();
            
        setAppState((prevState) => ({ ...prevState, currency: data[0], scart: keys.length }));
        //this.setState({ vouchers: data, loading: false });
    }

    useEffect(() => {
        getCurrenciesAsync();
    }, []);


    return <header style={{ marginBottom: '24px' }}>
        <div className="logo-container">
            <img src="./logo.png" onClick={() => history.push('/')} />            
            <div className="logo-container-right">
                <span><i>PREMIUM - CUSTOM MADE - INNOVATIVE</i></span>
                <img src="./logo-eurocom-europe.png" onClick={() => window.open("https://www.eurocom-europe.com", "_blank")} />
                <p><Icon name='phone' color='grey' /> <a style={{ color: '#aeaeb0' }} href="tel:+420724583823">+420 724 583 823</a></p>
                {/*<p><Icon name='facebook square' color='grey' /><Icon  name='youtube square' color='grey' style={{ marginRight: '-3px' }} /></p>*/}
            </div>
        </div>
        <Menubar className='nav-menubar' model={items} end={end} />
    </header>;


    

}
//export default withGlobalState(NavMenu)


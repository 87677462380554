import React, { useState, useEffect, useRef } from 'react';
import { DialogInfo } from './DialogInfo';
import Loader from './Loader';
import { isEmpty, validateEmail, validatePhone } from '../helpers/validator';

export const DynamicForm = ({ fields = [], object = {}, submitBtnName = '', onChangeObject, onSubmitForm, loading = false, onCloseDialog, dialogOpen = false, dialogHeader = '', dialogBody =  '' }) => {
    const [validator, setValidator] = useState({ 'firstname': [true, "required"]});
    const itemsRef = useRef([]);
    const [toggleValidFormData, setToggleValidFormData] = useState(false);

    useEffect(() => {
        let validator = {};
        fields.map(item => item.validationtype ? validator[item.prop] = [false, item.validationtype] : null);
        setValidator(validator);        
    }, []);

    const onSubmit = (e) => {    
        e.preventDefault();
        let valid = true;    
        Object.keys(validator).map((key, i) => {
            switch (validator[key][1]) {
                case 'required':                    
                    validator[key][0] = isEmpty(object[key]);
                    break;

                case 'email':                    
                    validator[key][0] = !validateEmail(object[key]);
                    break;

                case 'phone':                    
                    validator[key][0] = !validatePhone(object[key]);
                    break;

                default:
                    break;
            }
            if (valid && validator[key][0]) {
                valid = false;
                itemsRef.current[key].focus();
            }
        }); 
        setToggleValidFormData(!toggleValidFormData); 
        if (valid) onSubmitForm();        
    }

    const onChangeObj = e => {
        if (validator[e.target.id]) if (validator[e.target.id][0]) validator[e.target.id][0] = false;
        onChangeObject(e);
    }

    return (
        <>
            <Loader loading={loading} />
            <DialogInfo header={dialogHeader} show={dialogOpen} onClose={onCloseDialog}>
                {dialogBody}    
            </DialogInfo>
            <div className="form-row" style={{ marginTop: '32px' }}>
                {
                    fields.map((item, index) => {                        
                        switch (item.type) {
                            /* TEXT AREA */
                            case 'textarea':
                                return (
                                    <div className={`col-md-${item.size} mb-3`} key={index}>
                                        <label htmlFor={item.prop}>{item.label}</label>
                                        <textarea ref={el => itemsRef.current[item.prop] = el} className="form-control" id={item.prop} placeholder={item.placeholder} rows="4" value={object[item.prop]} onChange={onChangeObj} />
                                    </div>
                                ) 
                            /* INPUT - NUMBER, TEXT */
                            default:
                                return (
                                    <div className={`col-md-${item.size} mb-3`} key={index}>
                                        <label htmlFor={item.prop}>{item.label}</label>
                                        <input ref={el => itemsRef.current[item.prop] = el} type={item.type} className={validator[item.prop] ? validator[item.prop][0] ? "form-control is-invalid" : "form-control" : "form-control"} id={item.prop} placeholder={item.placeholder} required
                                            value={object[item.prop]} onChange={onChangeObj} />
                                         <div className="invalid-feedback">{item.validation}</div>
                                    </div>
                                )                                
                        }
                    })  
                }
            </div> 
            <div className="col-sm-12 text-center">
                <button style={{ margin: '24px 8px 48px 8px' }} onClick={onSubmit} className="submit-button rounded">{submitBtnName}</button>
            </div>
        </>
    );
};
import React, { useState } from 'react';
import { DynamicForm } from './DynamicForm';
import SEO from './SEO';

const formSubmitBtnName = 'ODESLAT';
const dialogHeader = 'RMA reklamace';
const successMessage = 'Váš požadavek byl odeslán, budeme Vás kontaktovat během následujícího pracovního dne.';
const errorMessage = 'Omlouváme se, ale Váš pořadavek se nepodařilo odeslat, kontaktujte nás prostřednictvím e-mailu nebo telefonu.';
const fields = [
    { prop: 'name', label: 'Celé jméno', placeholder: 'Jméno a příjmení', type: 'text', size: 6, validation: 'Zadejte prosím Vaše celé jméno.', validationtype: 'required' },
    { prop: 'firm', label: 'Firma', placeholder: 'Název firmy', type: 'text', size: 6, validation: '', validationtype: '' },
    { prop: 'email', label: 'E-mail', placeholder: 'Váš e-mail', type: 'email', size: 6, validation: 'Zadejte prosím platnou emailovou adresu.', validationtype: 'email' },
    { prop: 'phone', label: 'Telefonní číslo', placeholder: 'Včetně předčíslí +420', type: 'text', size: 6, validation: 'Zadejte prosím platné telefonní číslo (+420123456789).', validationtype: 'phone' },
    { prop: 'street', label: 'Ulice a číslo popisné', placeholder: 'Ulice a číslo popisné/orientační', type: 'text', size: 6, validation: 'Zadejte prosím platnou ulici a číslo pospisné.', validationtype: 'required' },
    { prop: 'zipcode', label: 'PSČ', placeholder: 'PSČ', type: 'number', size: 6, validation: 'Zadejte prosím platné poštovní směrovací číslo.', validationtype: 'required' },
    { prop: 'serialnumber', label: 'Sériové číslo notebooku', placeholder: 'Sériové číslo notebooku', type: 'text', size: 12, validation: 'Zadejte prosím platné sériové číslo.', validationtype: 'required' },
    { prop: 'bios', label: 'Verze BIOS + KBC/EC Firmware', placeholder: 'Verze BIOS + KBC/EC Firmware', type: 'text', size: 12, validation: 'Zadejte prosím alespoň verzi BIOSu.', validationtype: 'required' },
    { prop: 'message', label: 'Podrobný popis problémů', placeholder: 'Popis...', type: 'textarea', size: 12, validation: '', validationtype: '' }
];
let dynamicObject = {};
fields.map(item => dynamicObject[item.prop] = '');

export const RMA = () => {    
    const [object, setObject] = useState(dynamicObject);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogBody, setDialogBody] = useState('');

    const handleObjectChange = e => {
        const { id, value } = e.target;
        setObject(prevObj => ({ ...prevObj, [id]: value }));
    };
    const onSubmit = () => {
        setLoading(true);
        sendFomrAsync();
    };
    const onCloseDialog = () => {
        setDialogOpen(false);
    }

    const sendFomrAsync = async () => {        
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };
        const response = await fetch('form?id=2', requestOptions);
        if (response.ok) {
            setDialogBody(successMessage);
            setObject(dynamicObject);
        }
        else {
            setDialogBody(errorMessage);
        }
        setLoading(false);
        setDialogOpen(true);
    }

    return (
        <>
            {SEO({ title: "RMA reklamace | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
            <h1>RMA reklamace</h1>
            <p>Máte problém s notebookem?</p>
            <p>Trápí Vás něco nebo máte technické dotazy?</p>
            <p>Zde si jako náš zákazník a majitel notebooku EUROCOM můžete vypsat formulář, popsat podrobně problém, na základě kterého Vás kontaktujeme a pokud nebude možné vyřešit problém na dálku, pošleme k Vám kurýra, aby notebook vyzvednul. Následně provedeme bezplatně diagnostiku (v ceně 1.990 Kč bez DPH po záruce).</p>

            <DynamicForm fields={fields} object={object} submitBtnName={formSubmitBtnName} onChangeObject={handleObjectChange} onSubmitForm={onSubmit} loading={loading} dialogHeader={dialogHeader} dialogBody={dialogBody} dialogOpen={dialogOpen} onCloseDialog={onCloseDialog} />

            <p style={{ paddingTop: '24px' }}>Veškeré další dotazy směřujte na <a href="mailto:info@goldmax.cz">info@goldmax.cz</a> či telefon <a href="tel:+420724583823">+420 724 583 823</a>.</p>
        </>
    );
};
import React, { Component } from 'react';
import Loader from './Loader';
import { authHeader } from '../helpers/auth-header';
import { Route } from 'react-router-dom';
import { authenticationService } from '../services/authentication.service';
import { ErrorNotFound } from './ErrorNotFound';
import { DialogInfo } from './DialogInfo';
import { DialogConfirm } from './DialogConfirm';
import './ANotebookComponent.css';

export class Voucher {
    code = "";
    discountPercent = 0;
    discountPrice = 0;
    expirationDate = "";
    isForComSite = false;
}

export class AdminVoucher extends Component {
    static displayName = AdminVoucher.name;

    constructor(props) {
        super(props);
        this.state = {
            vouchers: [],
            voucher: new Voucher(),
            loading: true,
            logged: false,
            working: false,
            isUpdate: false,
            isOpen: false,
            header: "",
            message: "",
            isDelete: false,
            codeForDel: "",
            deleteId: -1
        };
        this.handleCreate = this.handleCreate.bind(this);        
        this.changeUpdate = this.changeUpdate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);  
        this.handleUpdateCancel = this.handleUpdateCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);  
        this.voucherCode = this.voucherCode.bind(this);
        this.voucherDiscountPercent = this.voucherDiscountPercent.bind(this);
        this.voucherDiscountPrice = this.voucherDiscountPrice.bind(this);
        this.voucherExpirationDate = this.voucherExpirationDate.bind(this);
        this.isForComSite = this.isForComSite.bind(this);
        this.toggleModal = this.toggleModal.bind(this);

        if (authenticationService.currentUserValue) {
            this.state.logged = true;
        }
    }

    handleCreate() {
        this.setState({ loading: true, working: true, header: "Vytvoření slevového kupónu" });
        this.createVoucherAsync(this.state.voucher);
    } 
    changeUpdate(e) {
        const { id } = e.target; 
        this.setState(
            {
                voucher: this.state.vouchers.find(x => x.id == id),
                isUpdate: true
            });
    }
    handleUpdateCancel() {
        this.setState(
            {
                voucher: new Voucher(),
                isUpdate: false
            });
    }
    handleUpdate(e) {        
        this.setState({ loading: true, working: true, header: "Úprava slevového kupónu" });
        this.updateVoucherAsync(this.state.voucher);        
    } 
    toggleOK = () => {
        this.setState({ isDelete: false, loading: true, working: true, header: "Smazání slevového kupónu" });
        this.deleteVoucherAsync(this.state.deleteId);
    }
    toggleNOK = () => {
        this.setState({ isDelete: false });
    }
    handleDelete(e) {
        const { id } = e.target;
        var voucher = this.state.vouchers.find(x => x.id == id)
        this.setState({ deleteId: id, isDelete: true, codeForDel: voucher.code });                
    }

    voucherCode(e) {
        var value = e.target.value;        
        const { voucher } = this.state;
        voucher.code = value;
        this.setState({ voucher });
    }
    voucherDiscountPercent(e) {
        var value = e.target.value;
        if (value == "") {
            value = 0;
        }
        const { voucher } = this.state;
        voucher.discountPercent = parseInt(value);
        this.setState({ voucher });
    }
    voucherDiscountPrice(e) {
        var value = e.target.value;
        if (value == "") {
            value = 0;
        }
        const { voucher } = this.state;
        voucher.discountPrice = parseFloat(value);
        this.setState({ voucher });
    }
    voucherExpirationDate(e) {
        const value = e.target.value;
        const { voucher } = this.state;
        voucher.expirationDate = value;
        this.setState({ voucher });
    }
    isForComSite(e) {
        //const value = e.target.value;
        const { voucher } = this.state;
        voucher.isForComSite = !this.state.voucher.isForComSite;
        this.setState({ voucher });
    }

    toggleModal() {
        if (this.state.message == "Slevový kupón byl úspěšně vytvořen." ||
            this.state.message == "Slevový kupón byl úspěšně upraven." ||
            this.state.message == "Slevový kupón byl úspěšně smazán.")
        {            
            this.setState({                
                voucher: new Voucher(),
                isOpen: !this.state.isOpen,
                isUpdate: false
            });
        }
        else {
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }

    componentDidMount() {
        if (this.state.logged) {
            this.getVouchersAsync();
        }
    }

    static renderVoucherTable(_this) {

        return (
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th>Kód</th>
                        <th>Sleva %</th>
                        <th>Sleva Kč</th>
                        <th>Platnost do</th>
                        <th>.com web</th>
                        <th></th>
                        <th></th>                        
                    </tr>
                </thead>
                <tbody>
                    {_this.state.vouchers.map(voucher => 
                        <tr key={voucher.id}>
                            <td>{voucher.code}</td>
                            <td>{voucher.discountPercent}</td>
                            <td>{voucher.discountPrice.toLocaleString()}</td>
                            <td>{new Date(voucher.expirationDate).toLocaleDateString()}</td>
                            <td>{voucher.isForComSite ? ".com" : null}</td> 
                            <td><div id={voucher.id} key={voucher.id} onClick={_this.changeUpdate} className="text-button">UPRAVIT</div></td>
                            <td><div id={voucher.id} key={voucher.id} onClick={_this.handleDelete} className="text-button">SMAZAT</div></td>
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

    render() {
        if (!this.state.logged) {
            return <Route path="*" component={ErrorNotFound} />
        }

        let loader = (this.state.working && this.state.loading) && <Loader loading={this.state.loading} />
        let contents = (!this.state.working && this.state.loading)
            ? <Loader loading={this.state.loading} />
            : AdminVoucher.renderVoucherTable(this);    

        return (
            <React.Fragment>
                {loader}
                <DialogConfirm
                    header="Smazání slevového kupónu" show={this.state.isDelete}
                    onOKButton={this.toggleOK}
                    onNOKButton={this.toggleNOK}>
                    Opravdu chceš smazat kupón s kódem <b>{this.state.codeForDel}</b>?
                </DialogConfirm>
                <DialogInfo
                    header={this.state.header} show={this.state.isOpen}
                    onClose={this.toggleModal}>
                    {this.state.message}
                </DialogInfo>
                <h5>{this.state.isUpdate ? "Úprava slevového kupónu" : "Nový slevový kupón"}</h5>
                <div className="group-container">
                    <div className="input-r-padding" style={{ width: '22%', float: 'left' }}>
                        <input type="text" placeholder="Kód" value={this.state.voucher.code == "" ? "" : this.state.voucher.code}
                            onChange={this.voucherCode} className="form-control input-text" />
                    </div>
                    <div className="input-c1-padding" style={{ width: '22%', float: 'left' }}>
                        <input type="number" placeholder="Sleva %" value={this.state.voucher.discountPercent == 0 ? "" : this.state.voucher.discountPercent}
                            onChange={this.voucherDiscountPercent} className="form-control input-text"
                            disabled={this.state.voucher.discountPrice == 0 ? "" : "disabled"} />
                    </div>
                    <div className="input-c2-padding" style={{ width: '22%', float: 'left' }}>
                        <input type="number" placeholder="Sleva Kč" value={this.state.voucher.discountPrice == 0 ? "" : this.state.voucher.discountPrice}
                            onChange={this.voucherDiscountPrice} className="form-control input-text"
                            disabled={this.state.voucher.discountPercent == 0 ? "" : "disabled"} />
                    </div>
                    <div className="input-c2-padding" style={{ width: '22%', float: 'left' }}>
                        <input type="date" placeholder="Platnost do"
                            value={this.state.voucher.expirationDate == "" ? "" : new Date(this.state.voucher.expirationDate.split('T')[0]).toISOString().substr(0, 10)}
                            onChange={this.voucherExpirationDate} className="form-control input-text" />
                    </div>
                    <div className="input-l-padding" style={{ width: '12%', float: 'left' }}>
                        <div onClick={this.isForComSite} className={this.state.voucher.isForComSite ? "form-control input-button component-container-selected" : "form-control input-button"} >.com web</div>
                    </div>
                </div>
                <div className="col-sm-12 text-center" style={{ marginBottom: '24px' }}>
                    {this.state.isUpdate
                        ? <React.Fragment>
                            <button style={{ marginRight: '12px'}} className="submit-button rounded" onClick={this.handleUpdate}>UPRAVIT</button>
                            <button style={{ marginLeft: '12px' }} className="submit-button rounded" onClick={this.handleUpdateCancel}>ZRUŠIT</button>
                        </React.Fragment>
                        : <button className="submit-button rounded" onClick={this.handleCreate}>VYTVOŘIT</button>}
                </div>
                {contents}
            </React.Fragment>

        );
    }

    async getVouchersAsync() {
        const requestOptions = { method: 'GET', headers: authHeader() };
        const response = await fetch('admin/getallvouchers', requestOptions);
        const data = await response.json();
        this.setState({ vouchers: data, loading: false });
    }

    async createVoucherAsync(voucher) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(voucher)
        };
        const response = await fetch('admin/createvoucher', requestOptions);
        const data = await response.json();
        const { vouchers } = this.state;
        var voucher = this.state.voucher;
        var message = "";
        if (response.ok) {
            message = "Slevový kupón byl úspěšně vytvořen.";
            vouchers.unshift(data);
        }
        else {
            message = "Slevový kupón se nepovedlo vytvořit.";
        }
        this.setState({ loading: false, working: false, isOpen: true, message, vouchers });
    }

    async updateVoucherAsync(voucher) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(voucher)
        };
        const response = await fetch('admin/updatevoucher', requestOptions);
        const data = await response.json();
        var { vouchers } = this.state;
        var message = "";
        if (response.ok) {
            message = "Slevový kupón byl úspěšně upraven."
            const index = vouchers.findIndex(x => x.id == data.id);            
            vouchers[index] = data;
        }
        else {
            message = "Slevový kupón se nepovedlo upravit."
        }
        this.setState({ loading: false, working: false, isOpen: true, message, vouchers });
    }

    async deleteVoucherAsync(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: authHeader()            
        };
        const response = await fetch('admin/deletevoucher/' + id, requestOptions);        
        var { vouchers } = this.state;
        var message = "";
        if (response.ok) {
            message = "Slevový kupón byl úspěšně smazán."
            
            vouchers = vouchers.filter(x => x.id != id);
        }
        else {
            message = "Slevový kupón se nepovedlo smazat."
        }
        this.setState({ loading: false, working: false, isOpen: true, message, vouchers });
    }
}
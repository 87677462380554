import React from "react"
import { Helmet } from "react-helmet"

const defaultUrl = "https://goldmax.cz";
const defaultImgUrl = "https://goldmax.cz/og-image.png";

const SEO = ({ title, description, siteUrl = "", sourceImgUrl = "", meta = [] }) => {
    return (
        <Helmet title={title}
            htmlAttributes={{ lang: "en" }}
            meta={[
                {
                    name: `description`,
                    content: description
                },
                {
                    property: `og:site_name`,
                    content: title
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    property: `og:url`,
                    content: `${defaultUrl}/${siteUrl}`
                },
                {
                    property: `og:description`,
                    content: description
                },
                {
                    property: `og:image`,
                    content: `${defaultUrl}/${sourceImgUrl}`
                },
                {
                    property: `og:image`,
                    content: defaultImgUrl
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    property: `og:locale`,
                    content: `cs_CZ`
                }

            ]}
        />
    )
}
export default SEO;

//  <meta name="description" content="Výkonné pracovní notebooky na výpočty, grafiku, programování a střih videa.">
//  <meta property="og:site_name" content="GOLDMAX" />
//  <!--Maximum 35 characters-- >
//  <meta property="og:title" content="GOLDMAX" />
//  <!--Full link to your webpage address-- >
//  <meta property="og:url" content="www.goldmax.cz" />
//  <!--Maximum 65 characters-- >
//  <meta property="og:description" content="Desktop v těle notebooku, to je EUROCOM! Plně konfigurovatelné...">
//  <!-- Image(JPG or PNG) of size less than 300KB and minimum dimension of 300 x 200 pixel is advised -->
//  <meta property="og:image" content="https://goldmax.cz/og-image.png">
//  <meta property="og:type" content="website" />
//  <meta property="og:locale" content="cs_CZ" />
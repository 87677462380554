import React from 'react';
import SEO from './SEO';

export const GDPR = () => {
    return <>
        {SEO({ title: "Ochrana osobních údajů | EUROCOM | Goldmax", description: "Bezkonkurenční špičkové notebooky na míru. Flexibilní a unikátní řešení." })}
        <h1>Ochrana osobních údajů</h1>
        <h5>Prohlášení o ochraně soukromí</h5>
        <p>Goldmax Services s.r.o. se zavazuje respektovat a ochraňovat Vaše soukromí. Goldmax Services s.r.o. se zavázal chránit vaše osobní údaje a postupuje v souladu se zákonem č. 101/2000 Sb., o ochraně osobních údajů a o změně některých zákonů.</p>
        <p>Goldmax Services s.r.o.  je registrován na Úřadu pro ochranu osobních údajů.</p>
        <h5>Zabezpečení osobních údajů</h5>
        <p>Goldmax Services s.r.o. je zavázána k ochraně a zabezpečení vašich osobních údajů. Užíváme množství různých zabezpečovacích technologií a postupů k tomu, abychom ochránili vaše osobní údaje před neoprávněným přístupem, užitím nebo zpřístupněním. Pokud nám při objednávání výrobků nebo služeb sdělíte své osobní údaje, můžete si být jisti, že je použijeme pouze pro zajištění a udržování kontaktu mezi vaší osobou a naší společností.  Goldmax Services s.r.o. neprodá ani žádným způsobem nepronajme vaše osobní údaje nikomu jinému. Pokud nebudeme mít váš souhlas nebo pokud zákon nebude vyžadovat jinak, nebudeme sdílet vaše osobní data, která nám poskytnete online.</p>
        <h5>Kde jsou vyžadovány osobní údaje</h5>
        <p>Goldmax Services s.r.o. na svých stránkách zveřejňuje informace nejen o produktech a službách, které nabízí, ale i informace o kontaktech na naší společnost, základní údaje o naší společnosti, nabídky možnosti zaměstnání , atd. Veškeré tyto informace jsou dostupné, aniž bychom po návštěvnících stránek vyžadovali jakékoliv osobní údaje.</p>
        <h5>Údaje, které shromažďujeme</h5>
        <p>Goldmax Services s.r.o. provozuje stránky na kterých můžete objednávat produkty nebo služby, zadávat požadavky a registrovat se pro zasílání materiálů. Osobními údaji, které jsou shromažďovány na těchto stránkách, jsou fakturační informace, dodací adresa, kontaktní osoba, telefonní spojení, emailová adresa a obchodní transakce.</p>
        <h5>Proč tyto údaje shromažďujeme</h5>
        <p>Goldmax Services s.r.o. bude shromažďovat a používat vaše osobní údaje pouze s vaším vědomím a souhlasem, a to pro objednávání, případně následné používání produktů a služeb. Můžeme používat vaše údaje pro účely související s poskytováním služeb vaší osobě, což zahrnuje zpracování objednávek, uzavření smlouvy, poskytování služeb a produktů, případně informací, které žádáte či odpovědí na vaše stížnosti nebo dotazy.</p>
        <h5>Souhlas s poskytnutím osobních údajů</h5>
        <p>Goldmax Services s.r.o. získává váš dobrovolný souhlas se zařazením poskytnutých údajů do databáze společnosti a jejich dalším zpracováním registrací na našich stránkách. Jestliže se rozhodnete poskytnout nám osobní údaje (jakékoliv informace, podle kterých lze identifikovat vaši osobu), buďte ujištěni, že budou použity pouze s cílem podporovat vás jako našeho zákazníka.</p>
        </>
} 
import React, { Component } from 'react';
import Loader from './Loader';
import { authHeader } from '../helpers/auth-header';
import { Route } from 'react-router-dom';
import { authenticationService } from '../services/authentication.service';
import { ErrorNotFound } from './ErrorNotFound';
import { DialogInfo } from './DialogInfo';
import { DialogConfirm } from './DialogConfirm';

export class Slide {
    order = 1;
    imageLink = "";
    imageBase64File = "";
    title = "";
    description = "";
    button = "";
    buttonLink = "";
    column = "1";
    row = "1";
    isForComSite = false;
    color = "";
}

const readUploadedFileAsData = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("Problem parsing input file."));
        };

        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsDataURL(inputFile);
    });
};
const readUploadedFiles = async (e) => {
    try {
        const promises = [];
        for (var i = 0; i < e.target.files.length; i++) {
            promises.push(readUploadedFileAsData(e.target.files[i]));
        }
        let base64Array = await Promise.all(promises);
        return base64Array;
    }
    catch (e) {
        console.log("Error" + e)
    }
}

export class AdminCarousel extends Component {
    static displayName = AdminCarousel.name;

    constructor(props) {
        super(props);
        this.state = {
            slides: [],
            slide: new Slide(),
            loading: true,
            logged: false,
            working: false,
            isUpdate: false,
            isOpen: false,
            header: "",
            message: "",
            isDelete: false,
            slideForDel: "",
            deleteId: -1,
            defaultOrder: 1
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.changeUpdate = this.changeUpdate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleUpdateCancel = this.handleUpdateCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        //this.voucherCode = this.voucherCode.bind(this);
        //this.voucherDiscountPercent = this.voucherDiscountPercent.bind(this);
        //this.voucherDiscountPrice = this.voucherDiscountPrice.bind(this);
        //this.voucherExpirationDate = this.voucherExpirationDate.bind(this);
        this.toggleModal = this.toggleModal.bind(this);

        if (authenticationService.currentUserValue) {
            this.state.logged = true;
        }
    }

    handleCreate() {
        this.setState({ loading: true, working: true, header: "Vytvoření nového snímku" });

        if (this.state.defaultOrder != this.state.slide.order) {
            this.createSlideOrderAsync(this.state.slide);
        } else {
            this.createSlideAsync(this.state.slide);            
        }
        
    }
    changeUpdate(e) {
        const { id } = e.target;
        const slide = this.state.slides.find(x => x.id == id);
        this.setState(
            {
                slide: slide,
                defaultOrder: slide.order,
                isUpdate: true
            });
    }
    handleUpdateCancel() {
        const slide = new Slide();
        slide.order = this.state.slides.length + 1;
        this.setState(
            {
                slide: slide,
                defaultOrder: slide.order,
                isUpdate: false
            });
    }
    handleUpdate(e) {
        this.setState({ loading: true, working: true, header: "Úprava snímku" });
        if (this.state.defaultOrder != this.state.slide.order) {
            this.updateSlideOrderAsync(this.state.slide);
        } else {
            this.updateSlideAsync(this.state.slide);
        }
    }
    toggleOK = () => {
        this.setState({ isDelete: false, loading: true, working: true, header: "Smazání snímku" });
        this.deleteSlideAsync(this.state.deleteId);
    }
    toggleNOK = () => {
        this.setState({ isDelete: false });
    }
    handleDelete(e) {
        const { id } = e.target;
        var slide = this.state.slides.find(x => x.id == id)
        this.setState({ deleteId: id, isDelete: true, slideForDel: slide.title });
    }

    toggleModal() {
        if (this.state.message == "Snímek byl úspěšně vytvořen." ||
            this.state.message == "Snímek byl úspěšně upraven." ||
            this.state.message == "Snímek byl úspěšně smazán.") {
            const slide = new Slide();
            slide.order = this.state.slides.length + 1;
            this.setState({
                slide: slide,
                defaultOrder: slide.order,
                isOpen: !this.state.isOpen,
                isUpdate: false
            });
        }
        else {
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }

    componentDidMount() {
        if (this.state.logged) {
            this.getSlidesAsync();
        }
    }

    static renderSlideTable(_this) {

        return (
            <table className='table table-striped' style={{ marginTop: '32px', marginBottom: '32px' }}>
                <thead>
                    <tr>
                        <th>Pořadí</th>
                        <th>Titulek</th> 
                        <th>.com web</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {_this.state.slides.map(slide =>
                        <tr key={slide.id}>
                            <td>{slide.order}</td>  
                            <td>{slide.title}</td>  
                            <td>{slide.isForComSite ? ".com" : null}</td> 
                            <td><div id={slide.id} key={slide.id} onClick={_this.changeUpdate} className="text-button">UPRAVIT</div></td>
                            <td><div id={slide.id} key={slide.id} onClick={_this.handleDelete} className="text-button">SMAZAT</div></td>
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

    slideIsFromComSite = (e) => {        
        const { slide } = this.state;
        slide.isForComSite = !this.state.slide.isForComSite;
        this.setState({ slide });
    }

    slideTitle = (e) => {
        var value = e.target.value;
        const { slide } = this.state;
        slide.title = value;
        this.setState({ slide });
    }
    slideOrder = (e) => {
        var value = e.target.value;
        const { slide } = this.state;
        slide.order = parseFloat(value);
        this.setState({ slide });
    }
    slideDescription = (e) =>
    {
        var value = e.target.value;
        const { slide } = this.state;
        slide.description = value;
        this.setState({ slide });
    }
    slideButton = (e) => {
        var value = e.target.value;
        const { slide } = this.state;
        slide.button = value;
        this.setState({ slide });
    }
    slideColumn = (e) => {
        var value = e.target.value;
        const { slide } = this.state;
        slide.column = value;
        this.setState({ slide });
    }
    slideRow = (e) => {
        var value = e.target.value;
        const { slide } = this.state;
        slide.row = value;
        this.setState({ slide });
    }
    slideButtonLink = (e) => {
        var value = e.target.value;
        const { slide } = this.state;
        slide.buttonLink = value;
        this.setState({ slide });
    }
    slideColor = (e) => {
        var value = e.target.value;
        const { slide } = this.state;
        slide.color = value;
        this.setState({ slide });
    }

    uploadFile = async (e) => {
        this.setState({ loading: true });
        const { files } = e.target;
        const { slide } = this.state;
        const base64Array = await readUploadedFiles(e);
        for (var i = 0; i < base64Array.length; i++) {            
            slide.imageBase64File = base64Array[i];
            slide.imageLink = URL.createObjectURL(files[i])            
        }
        this.setState({ slide: slide, loading: false });
    }

    renderPositionContainer = () => {
        switch (this.state.slide.row) {
            case "1":
                return <>
                    {
                        this.state.slide.column === "1"
                            ? <div className="slider-left-container" style={{ top: '24px' }}>
                                <h1 style={{ color: '#' + this.state.slide.color }}>{this.state.slide.title}</h1>
                                <p style={{ color: '#' + this.state.slide.color }}>{this.state.slide.description}</p>
                                <button className="slider-button" style={{ color: '#' + this.state.slide.color }} onClick={() => { this.props.history.push(this.state.slide.buttonLink)}}>{this.state.slide.button}</button>
                            </div>
                            : <div className="slider-right-container" style={{ top: '24px' }}>
                                <h1 style={{ color: '#' + this.state.slide.color }}>{this.state.slide.title}</h1>
                                <p style={{ color: '#' + this.state.slide.color }}>{this.state.slide.description}</p>
                                <button className="slider-button" style={{ color: '#' + this.state.slide.color }} onClick={() => { this.props.history.push(this.state.slide.buttonLink) }}>{this.state.slide.button}</button>
                            </div>
                    }                   
                    
                </>
            case "2":
                return <>
                    {
                        this.state.slide.column === "1"
                            ? <div className="slider-left-container" style={{ top: '50%', transform: 'translate(0, -50%)' }}>
                                <h1 style={{ color: '#' + this.state.slide.color }}>{this.state.slide.title}</h1>
                                <p style={{ color: '#' + this.state.slide.color }}>{this.state.slide.description}</p>
                                <button className="slider-button" style={{ color: '#' + this.state.slide.color }} onClick={() => { this.props.history.push(this.state.slide.buttonLink) }}>{this.state.slide.button}</button>
                            </div>
                            : <div className="slider-right-container" style={{ top: '50%', transform: 'translate(0, -50%)' }}>
                                <h1 style={{ color: '#' + this.state.slide.color }}>{this.state.slide.title}</h1>
                                <p style={{ color: '#' + this.state.slide.color }}>{this.state.slide.description}</p>
                                <button className="slider-button" style={{ color: '#' + this.state.slide.color }} onClick={() => { this.props.history.push(this.state.slide.buttonLink) }}>{this.state.slide.button}</button>
                            </div>
                    }       
                </>
            case "3":
                return <>
                    {
                        this.state.slide.column === "1"
                            ? <div className="slider-left-container" style={{ bottom: '24px' }}>
                                <h1 style={{ color: '#' + this.state.slide.color }}>{this.state.slide.title}</h1>
                                <p style={{ color: '#' + this.state.slide.color }}>{this.state.slide.description}</p>
                                <button className="slider-button" style={{ color: '#' + this.state.slide.color }} onClick={() => { this.props.history.push(this.state.slide.buttonLink) }}>{this.state.slide.button}</button>
                            </div>
                            : <div className="slider-right-container" style={{ bottom: '24px' }}>
                                <h1 style={{ color: '#' + this.state.slide.color }}>{this.state.slide.title}</h1>
                                <p style={{ color: '#' + this.state.slide.color }}>{this.state.slide.description}</p>
                                <button className="slider-button" style={{ color: '#' + this.state.slide.color }} onClick={() => { this.props.history.push(this.state.slide.buttonLink) }}>{this.state.slide.button}</button>
                            </div>
                    }
                </>
            default:
                return <></>
        }
    }

    render() {
        if (!this.state.logged) {
            return <Route path="*" component={ErrorNotFound} />
        }

        let loader = (this.state.working && this.state.loading) && <Loader loading={this.state.loading} />
        let contents = (!this.state.working && this.state.loading)
            ? <Loader loading={this.state.loading} />
            : AdminCarousel.renderSlideTable(this);

        return (
            <React.Fragment>
                {loader}
                <DialogConfirm
                    header="Smazání snímku" show={this.state.isDelete}
                    onOKButton={this.toggleOK}
                    onNOKButton={this.toggleNOK}>
                    Opravdu chceš smazat snímek s titulkem <b>{this.state.slideForDel}</b>?
                </DialogConfirm>
                <DialogInfo
                    header={this.state.header} show={this.state.isOpen}
                    onClose={this.toggleModal}>
                    {this.state.message}
                </DialogInfo>
                <h5>{this.state.isUpdate ? "Úprava snímku" : "Nový snímek"}</h5>
                <p style={{ padding: 0, margin: 0 }}>Rozměry obrázku pro slide <b>1200 x 400 px</b>.</p>
                {this.state.defaultOrder != this.state.slide.order && (this.state.isUpdate
                    ? <span className="nok-code">Dojde k prohození se snímkem na dané pozici !!!</span>
                    : <span className="nok-code">Dojde k přečíslování řazení ostatních snímků !!!</span>)}
                <div className="group-container">
                    <div className="input-r-padding" style={{ float: 'left', width: '8%' }}>
                        <input type="number" placeholder="Pořadí" value={this.state.slide.order == "" ? "" : this.state.slide.order} onChange={this.slideOrder} className="form-control input-text" />
                    </div>
                    <div className="input-c1-padding" style={{ float: 'left', width: '54%' }}>
                        <input type="text" placeholder="Titulek" value={this.state.slide.title == "" ? "" : this.state.slide.title} onChange={this.slideTitle} className="form-control input-text" />
                    </div>
                    <div className="input-c2-padding" style={{ width: '22%', float: 'left' }}>
                        <input type="text" placeholder="Barva s alfa kanalem (#RRGGBBAA)" value={this.state.slide.color == "" ? "" : this.state.slide.color} onChange={this.slideColor} className="form-control input-text" />                       
                    </div>
                    <div className="hidden-file-input" style={{ float: 'right' }}>
                        <label for="file-upload" className="input-file-upload">Vybrat obrázek</label>
                        <input id="file-upload" type="file" multiple accept="image/*" onChange={this.uploadFile} />
                    </div>
                </div>
                <textarea rows="2" placeholder="Popis" value={this.state.slide.description == "" ? "" : this.state.slide.description} onChange={this.slideDescription} className="form-control input-textarea col-sm-12" />
                <div className="group-container">
                    <div className="input-r-padding" style={{ width: '22%', float: 'left' }}>
                        <input type="text" placeholder="Popis na tlačítku" value={this.state.slide.button == "" ? "" : this.state.slide.button}
                            onChange={this.slideButton} className="form-control input-text" />
                    </div>
                    <div className="input-c1-padding" style={{ width: '22%', float: 'left' }}>                       
                        <input type="text" placeholder="Akce tlačítka (/notebooks)" value={this.state.slide.buttonLink == "" ? "" : this.state.slide.buttonLink} onChange={this.slideButtonLink} className="form-control input-text" />               
                    </div>
                    <div className="input-c2-padding" style={{ width: '22%', float: 'left' }}>                        
                        <select value={this.state.slide.column} onChange={this.slideColumn} className="form-control input-select">
                            <option value="1">levý sloupec</option>
                            <option value="2">pravý sloupec</option>                           
                        </select>                        
                    </div>
                    <div className="input-c2-padding" style={{ width: '22%', float: 'left' }}>
                        <select value={this.state.slide.row} onChange={this.slideRow} className="form-control input-select">
                            <option value="1">horní řádek</option>
                            <option value="2">prostřední řádek</option>
                            <option value="3">dolní řádek</option>
                        </select>    
                    </div>
                    <div className="input-l-padding" style={{ width: '12%', float: 'left' }}>
                        <div onClick={this.slideIsFromComSite} className={this.state.slide.isForComSite ? "form-control input-button component-container-selected" : "form-control input-button"} >.com web</div>
                    </div>
                </div>                
                <div className="col-sm-12 text-center">
                    {this.state.isUpdate
                        ? <React.Fragment>
                            <button style={{ marginRight: '12px' }} className="submit-button rounded" onClick={this.handleUpdate}>UPRAVIT</button>
                            <button style={{ marginLeft: '12px' }} className="submit-button rounded" onClick={this.handleUpdateCancel}>ZRUŠIT</button>
                        </React.Fragment>
                        : <button className="submit-button rounded" onClick={this.handleCreate}>VYTVOŘIT</button>}
                </div>
                 
                <div style={{ background: `url('${this.state.slide.imageLink}') no-repeat center center`, width: '100%', position: "relative", height: '400px', clear: 'both' }}>
                    { this.renderPositionContainer() }
                </div>
          

                
                {contents}
            </React.Fragment>

        );
    }

    async getSlidesAsync() {
        const requestOptions = { method: 'GET', headers: authHeader() };
        const response = await fetch('admin/getallslidesadmin', requestOptions);
        const data = await response.json();
        const slide = new Slide();
        slide.order = data.length + 1;
        this.setState({ slides: data, slide: slide, loading: false, defaultOrder: data.length + 1 });
    }

    async createSlideOrderAsync(slide) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(slide)
        };
        const response = await fetch('admin/createslideorder', requestOptions);
        const data = await response.json();
        //const { slides } = this.state;
        var message = "";
        if (response.ok) {
            message = "Snímek byl úspěšně vytvořen.";
            this.setState({ loading: false, working: false, isOpen: true, message, slides: data });
        }
        else {
            message = "Snímek se nepovedlo vytvořit.";
            this.setState({ loading: false, working: false, isOpen: true, message });
        }
        
    }
    async createSlideAsync(slide) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(slide)
        };
        const response = await fetch('admin/createslide', requestOptions);
        const data = await response.json();
        const { slides } = this.state;        
        var message = "";
        if (response.ok) {
            message = "Snímek byl úspěšně vytvořen.";
            slides.push(data);
        }
        else {
            message = "Snímek se nepovedlo vytvořit.";
        }
        this.setState({ loading: false, working: false, isOpen: true, message, slides });
    }

    async updateSlideOrderAsync(slide) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(slide)
        };
        const response = await fetch('admin/updateslideorder', requestOptions);
        const data = await response.json();        
        var message = "";
        if (response.ok) {
            message = "Snímek byl úspěšně upraven."            
            this.setState({ loading: false, working: false, isOpen: true, message, slides: data });
        }
        else {
            message = "Snímek se nepovedlo upravit."
            this.setState({ loading: false, working: false, isOpen: true, message });
        }        
    }
    async updateSlideAsync(slide) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(slide)
        };
        const response = await fetch('admin/updateslide', requestOptions);
        const data = await response.json();
        var { slides } = this.state;
        var message = "";
        if (response.ok) {
            message = "Snímek byl úspěšně upraven."
            const index = slides.findIndex(x => x.id == data.id);
            slides[index] = data;
        }
        else {
            message = "Snímek se nepovedlo upravit."
        }
        this.setState({ loading: false, working: false, isOpen: true, message, slides });
    }

    async deleteSlideAsync(id) {
        const requestOptions = {
            method: 'DELETE',
            headers: authHeader()
        };
        const response = await fetch('admin/deleteslide/' + id, requestOptions);
        var { slides } = this.state;
        var message = "";
        if (response.ok) {
            message = "Snímek byl úspěšně smazán."

            slides = slides.filter(x => x.id != id);
        }
        else {
            message = "Snímek se nepovedlo smazat."
        }
        this.setState({ loading: false, working: false, isOpen: true, message, slides });
    }
}